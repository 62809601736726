import { Avatar, Typography, Box, LinearProgress } from '@mui/material';

// Define Scorer component first
const Scorer = (props) => {
  const { score, color, size = 120, strokeWidth = 8, radius = 50, children, bottomMargin = 0 } = props;

  const svgStyle = {
    height: size,
    width: size,
    position: "absolute",
    top: 0,
    left: 0,
    fill: "transparent",
  };

  const circleStyle = {
    fill: "transparent",
  };

  const circumference = Math.PI * radius;
  const dashOffset = circumference - (circumference * score) / 10;

  const progressBorderStyle = {
    stroke: color,
    strokeWidth: strokeWidth,
    opacity: 0.5,
    strokeLinecap: "round",
    strokeDasharray: `${circumference} ${circumference}`,
    strokeDashoffset: dashOffset,
    transition: "all 0.5s ease",
  };

  const trackStyle = {
    stroke: color,
    opacity: 0.5,
    strokeWidth: strokeWidth,
    strokeLinecap: "round",
  };

  const center = size / 2;
  const pathD = `M${center} ${center - radius} A${radius} ${radius} 0 1 1 ${center} ${center + radius}`;

  return (
    <div style={{ position: "relative", width: size, height: size, marginBottom: `${bottomMargin}px` }}>
      <svg style={svgStyle}>
        <g transform={`rotate(-90, ${center}, ${center})`}>
          <path
            id="track"
            d={pathD}
            style={trackStyle}
            fill="none"
          />
          <path
            id="progress-border"
            d={pathD}
            style={{ ...circleStyle, ...progressBorderStyle }}
            fill="none"
          />
        </g>
      </svg>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        {children}
      </div>
    </div>
  );
};

// Main VibeScorer component
const VibeScorer = ({ vibeScore, size, displayMode = "circle", sx = {}, getVibeDescription, vibeColor }) => {
  // Calculate dimensions based on size prop
  const baseSize = size === "small" ? 100 : 120;
  const avatarHeight = size === "small" ? 25 : 40;
  const fontSize = size === "small" ? 25 : 28;
  const labelSize = size === "small" ? 10 : 10;
  const strokeWidth = size === "small" ? 6 : 8;
  const radius = size === "small" ? 40 : 50;
  const bottomMargin = size === "small" ? -20 : 0;

  if (!vibeScore) return null;

  if (displayMode === "bar") {
    return (
      <Box sx={{ width: '100%', textAlign: 'center', display: 'flex', flexDirection: 'column', ...sx }}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 700, 
            color: vibeColor(parseFloat(vibeScore)),
            fontSize: size === "small" ? "1.5rem" : "2.25rem",
            mb: 0.5,
            width: '100%',
            textAlign: 'left'
          }}
        >
          {vibeScore.toFixed(1)}
        </Typography>
        <LinearProgress
          variant="determinate"
          value={(vibeScore / 10) * 100}
          sx={{
            width: '100%',
            height: size === "small" ? 6 : 8,
            borderRadius: 5,
            backgroundColor: '#eee',
            '& .MuiLinearProgress-bar': {
              backgroundColor: vibeColor(parseFloat(vibeScore)),
              borderRadius: 5,
              border: 'none',
              boxShadow: 'none'
            },
            border: 'none',
            boxShadow: 'none'
          }}
        />
      </Box>
    );
  }

  return (
    <Scorer
      score={parseFloat(vibeScore)}
      color={vibeColor(parseFloat(vibeScore))}
      size={baseSize}
      strokeWidth={strokeWidth}
      radius={radius}
      bottomMargin={bottomMargin}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap:1}}>
      <Avatar
        sx={{
          width: baseSize,
          height: avatarHeight,
          color: vibeColor(parseFloat(vibeScore)),
          background: "transparent",
          fontSize: fontSize,
          fontWeight: 700,
        }}
      >
        {vibeScore.toFixed(1)}
      </Avatar>
      {size !== "small" && (
        <Typography
          variant="body2"
          fontSize={labelSize}
          fontWeight={700}
          sx={{
            backgroundColor: `${vibeColor(parseFloat(vibeScore))}20`,
            color: vibeColor(parseFloat(vibeScore)),
            padding: '2px 8px',
            borderRadius: '4px',
            textTransform: 'capitalize'
          }}
        >
          {getVibeDescription(parseFloat(vibeScore))}
        </Typography>
      )}
      </Box>
    </Scorer>
  );
};

export default VibeScorer;