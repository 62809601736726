import {
  Box,
  Grid,
  Typography,
  Card,
  ThemeProvider,
  ButtonBase,
  AppBar,
  Avatar,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Toolbar,
  Tabs,
  Tab,
  Chip,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Skeleton,
  Tooltip,
  Link,
} from "@mui/material";
import React, { useCallback, useState, useMemo, useEffect, useRef } from "react";
import {
  Add,
  BarChart,
  GroupOutlined,
  OpenInNew,
  Search,
  CommentOutlined,
  DescriptionOutlined,
  ListAlt,
  StackedLineChartOutlined,
  Refresh,
  DoneAllRounded,
  Edit,
} from "@mui/icons-material";
import { ArrowTopRightOnSquareIcon, CalendarDateRangeIcon, LinkIcon } from "@heroicons/react/24/outline";
import { siftsyTheme } from "./siftsytheme";
import { useUser, UserButton, useAuth } from "@clerk/clerk-react";
import { useNavigate, Navigate } from "react-router-dom";
import HelperFunctions from "./HelperFunctions";
import { formatNumber, formatPlatform, formatFullDate, formatData, estimateViews } from './Helpers';
import Footer from "./Footer";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer
} from 'recharts';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const ADMIN_EMAILS = process.env.REACT_APP_ADMIN_EMAILS?.split(',') || [];
const theme = siftsyTheme;

const teamCardStyles = {
  borderRadius: 3,
  p: 3,
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #eee',
  height: '200px !important', // Update base style
  minHeight: '200px !important',
  maxHeight: '200px !important',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
    borderColor: 'primary.main',
  },
};


function UpdatePlanModal({ open, onClose, team, onSuccess }) {
  const Functions = HelperFunctions();
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [planData, setPlanData] = useState({
    name: team?.plan?.name || null,
    type: team?.plan?.type || null,
    term: team?.plan?.term || null,
    teamMembers: team?.plan?.teamMembers || null,
    allowedMaas: team?.plan?.allowedMAAs || null,
    postCredits: team?.plan?.postCredits || null,
    commentLimit: team?.plan?.commentLimit || null,
    lookback: team?.plan?.lookback || null
  });
  
  const modalRef = useRef(null);
  const [modalWidth, setModalWidth] = useState('600px');

  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startWidth = modalRef.current.offsetWidth;

    const handleMouseMove = (e) => {
      const newWidth = Math.min(Math.max(startWidth + (e.clientX - startX), 300), window.innerWidth * 0.9, 1200);
      setModalWidth(newWidth + 'px');
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleUpdatePlan = async () => {
    setLoading(true);
    setError('');

    try {
      await Functions.updateTeamPlan(team.id, planData);
      await onSuccess(); // Only refresh data after successful update
      handleModalClose();
    } catch (error) {
      console.error('Error updating plan:', error);
      setError('Failed to update plan. Please try again.');
    } finally {
      setLoading(false);
    }
  };


  const handleModalClose = () => {
    setPlanData({
      name: team?.plan?.name || null,
      type: team?.plan?.type || null,
      term: team?.plan?.term || null,
      teamMembers: team?.plan?.teamMembers || null,
      allowedMaas: team?.plan?.allowedMAAs || null,
      postCredits: team?.plan?.postCredits || null,
      commentLimit: team?.plan?.commentLimit || null,
      lookback: team?.plan?.lookback || null
    });
    setError('');
    setShowConfirmation(false);
    onClose();
  };

  const handleInputChange = (field) => (event) => {
    const value = field === 'name' || field === 'type' || field === 'term' 
      ? event.target.value 
      : parseInt(event.target.value, 10);
    setPlanData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Function to check if a value has changed
  const hasValueChanged = (key) => {
    const oldValue = team?.plan?.[key];
    const newValue = planData[key];
    return oldValue !== newValue;
  };

  // Function to check if any values have decreased
  const hasDecreasedValues = () => {
    const decreasedFields = [];
    
    if (planData.teamMembers < (team?.plan?.teamMembers || 0)) {
      decreasedFields.push('Team Members');
    }
    if (planData.allowedMaas < (team?.plan?.allowedMAAs || 0)) {
      decreasedFields.push('MAAs');
    }
    if (planData.postCredits < (team?.plan?.postCredits || 0)) {
      decreasedFields.push('Post Credits');
    }
    if (planData.commentLimit < (team?.plan?.commentLimit || 0)) {
      decreasedFields.push('Comment Limit');
    }
    if (planData.lookback < (team?.plan?.lookback || 0)) {
      decreasedFields.push('Lookback Period');
    }
    
    return decreasedFields;
  };

  // Function to format the change display
  const formatChange = (key) => {
    const oldValue = team?.plan?.[key] || 'N/A';
    const newValue = planData[key];
    return `${oldValue} → ${newValue}`;
  };

  // Check if any changes have been made
  const hasChanges = Object.keys(planData).some(key => hasValueChanged(key));

  return (
    <>
      <Dialog 
        open={open && !showConfirmation} 
        onClose={loading ? undefined : handleModalClose}
        maxWidth="sm" 
        fullWidth
        PaperProps={{
          style: { width: modalWidth }
        }}
      >
        <div style={{ position: 'relative', padding: "20px" }}>
          <Typography variant="h6" sx={{ mb: 2, mt:2 }}>Update Team Plan</Typography>
          <div 
            style={{ cursor: 'ew-resize', position: 'absolute', right: 0, top: 0, height: '100%', width: '10px' }} 
            onMouseDown={handleMouseDown}
          />
          <DialogContent ref={modalRef} style={{ padding: 0, height: '100%', overflow: 'hidden' }}>
            <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Plan Name</InputLabel>
                <Select
                  value={planData.name}
                  onChange={handleInputChange('name')}
                  label="Plan Name"
                  disabled={loading}
                >
                  <MenuItem value="Trial">Trial</MenuItem>
                  <MenuItem value="Agile Team">Agile Team</MenuItem>
                  <MenuItem value="Growth Team">Growth Team</MenuItem>
                  <MenuItem value="Powerhouse Team">Powerhouse Team</MenuItem>
                  <MenuItem value="Enterprise">Enterprise</MenuItem>
                </Select>
              </FormControl>
              
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Plan Type</InputLabel>
                    <Select
                      value={planData.type}
                      onChange={handleInputChange('type')}
                      label="Plan Type"
                      disabled={loading}
                    >
                      <MenuItem value="subscription">Subscription</MenuItem>
                      <MenuItem value="trial">Trial</MenuItem>
                      <MenuItem value="enterprise">Enterprise</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Term</InputLabel>
                    <Select
                      value={planData.term}
                      onChange={handleInputChange('term')}
                      label="Term"
                      disabled={loading}
                    >
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl fullWidth>
                <InputLabel>Team Members</InputLabel>
                <Select
                  value={planData.teamMembers}
                  onChange={handleInputChange('teamMembers')}
                  label="Team Members"
                  disabled={loading}
                >
                  {[...Array(20)].map((_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>
                      {i + 1} {i === 0 ? 'member' : 'members'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="h6" sx={{ mt: 2, mb: 0 }}>Plan Limits</Typography>

              {/* Accounts Section */}
              <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>Accounts</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>MAAs</InputLabel>
                    <Select
                      value={planData.allowedMaas}
                      onChange={handleInputChange('allowedMaas')}
                      label="Allowed MAAs"
                      disabled={loading}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={150}>150</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Posts Section */}
              <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>Posts</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Comment Limit</InputLabel>
                    <Select
                      value={planData.commentLimit}
                      onChange={handleInputChange('commentLimit')}
                      label="Comment Limit"
                      disabled={loading}
                    >
                      <MenuItem value={250}>250</MenuItem>
                      <MenuItem value={500}>500</MenuItem>
                      <MenuItem value={1000}>1,000</MenuItem>
                      <MenuItem value={2000}>2,000</MenuItem>
                      <MenuItem value={5000}>5,000</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Lookback Period (months)</InputLabel>
                    <Select
                      value={planData.lookback}
                      onChange={handleInputChange('lookback')}
                      label="Lookback Period (months)"
                      disabled={loading}
                    >
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={6}>6</MenuItem>
                      <MenuItem value={12}>12</MenuItem>
                      <MenuItem value={18}>18</MenuItem>
                      <MenuItem value={24}>24</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              <Typography variant="h6" sx={{ mt: 2, mb: 0 }}>Credits</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Post Credits"
                    type="number"
                    value={planData.postCredits}
                    onChange={(e) => {
                      const value = parseInt(e.target.value, 10);
                      if (!isNaN(value) && value >= 1 && value <= 200) {
                        handleInputChange('postCredits')({ target: { value } });
                      }
                    }}
                    disabled={loading}
                    inputProps={{
                      min: 1,
                      max: 200
                    }}
                    helperText="Enter a number between 1 and 200"
                  />
                </Grid>
              </Grid>

              {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ pt: 4}}>
            <Button 
              onClick={handleModalClose} 
              disabled={loading}
              sx={{ borderRadius: 2 }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => setShowConfirmation(true)}
              variant="contained"
              disabled={loading || !hasChanges}
              sx={{ borderRadius: 2 }}
            >
              Review Changes
            </Button>
          </DialogActions>
        </div>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Plan Changes</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 600 }}>
              Team: {team?.name}
            </Typography>
            
            {/* Warning for decreased values */}
            {hasDecreasedValues().length > 0 && (
              <Box sx={{ 
                mt: 2, 
                mb: 3, 
                p: 2, 
                bgcolor: 'error.light', 
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'error.main'
              }}>
                <Typography variant="subtitle2" color="error.dark" gutterBottom>
                  ⚠️ Warning: The following limits will be decreased:
                </Typography>
                <ul style={{ margin: '8px 0', paddingLeft: '20px' }}>
                  {hasDecreasedValues().map((field, index) => (
                    <li key={index}>
                      <Typography variant="body2" color="error.dark">
                        {field}
                      </Typography>
                    </li>
                  ))}
                </ul>
                <Typography variant="body2" color="error.dark">
                  This may affect the team's ability to use certain features.
                </Typography>
              </Box>
            )}

            {/* Changes Summary */}
            <Box sx={{ mt: 3 }}>
              <Typography variant="subtitle2" gutterBottom>
                Changes Summary:
              </Typography>
              {Object.entries(planData).map(([key, value]) => {
                if (hasValueChanged(key)) {
                  return (
                    <Box key={key} sx={{ mb: 2 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </Typography>
                      <Typography sx={{ 
                        p: 1, 
                        bgcolor: 'rgba(71, 40, 196, 0.05)', 
                        borderRadius: 1,
                        border: '1px solid rgba(71, 40, 196, 0.1)'
                      }}>
                        {formatChange(key)}
                      </Typography>
                    </Box>
                  );
                }
                return null;
              })}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowConfirmation(false)}
            disabled={loading}
            sx={{ borderRadius: 2 }}
          >
            Back
          </Button>
          <Button
            onClick={handleUpdatePlan}
            variant="contained"
            disabled={loading}
            sx={{ borderRadius: 2 }}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Confirm Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Modal component for displaying the iframe
const PostModal = ({ open, onClose, postId, teamId }) => {
  const modalRef = useRef(null);
  const [modalWidth, setModalWidth] = useState('90vw'); // Initial width set to 90% of viewport width

  const handleMouseDown = (e) => {
    const startX = e.clientX;
    const startWidth = modalRef.current.offsetWidth;

    const handleMouseMove = (e) => {
      const newWidth = Math.min(Math.max(startWidth + (e.clientX - startX), window.innerWidth * 0.9), 1400);
      setModalWidth(newWidth + 'px');
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth
      PaperProps={{
        style: { width: modalWidth, height: '80vh', overflow: 'hidden', maxWidth: '1400px', minWidth: `${window.innerWidth * 0.9}px` } // Set min width to 90% of viewport and max to 1400px
      }}
    >
      <div style={{ position: 'relative', height: '80vh' }}>
        <DialogTitle>Post Details</DialogTitle>
        <div 
          style={{ cursor: 'ew-resize', position: 'absolute', right: 0, top: 0, height: '100%', width: '10px' }} 
          onMouseDown={handleMouseDown}
        />
        <DialogContent ref={modalRef} style={{ padding: 0, height: '100%', overflow: 'hidden' }}>
          <iframe
            src={`/team/${teamId}/post/${postId}`}
            style={{ width: '100%', height: '100%', border: 'none', overflow: 'auto' }}
            title="Post Details"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

function CreateTeamModal({ open, onClose, onSuccess }) {
  const [teamName, setTeamName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [newTeamId, setNewTeamId] = useState(null);
  const Functions = HelperFunctions();
  const { user } = useUser();
  const navigate = useNavigate();

  const handleCreateTeam = async () => {
    const trimmedName = teamName.trim();
    if (!trimmedName) {
      setError('Team name is required');
      return;
    }

    if (trimmedName.length > 100) {
      setError('Team name must be 100 characters or less');
      return;
    }

    setLoading(true);
    setError('');

    try {
      // Create the team
      const newTeam = await Functions.createTeam(trimmedName);
      
      if (newTeam) {
        // Get or create Siftsy user
        const siftsyUser = await Functions.fetchUser(user.primaryEmailAddress.emailAddress);
        
        if (siftsyUser) {
          // Link user to team
          await Functions.linkUserTeam(siftsyUser.id, newTeam.id);
          setNewTeamId(newTeam.id);
          setSuccess(true);
          onSuccess();
          setTeamName('');
        } else {
          setError('Failed to create team. Please try again.');
        }
      } else {
        setError('Failed to create team. Please try again.');
      }
    } catch (error) {
      console.error('Error creating team:', error);
      setError('Failed to create team. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !loading) {
      handleCreateTeam();
    }
  };

  const handleClose = () => {
    onClose();
  };

  const goToNewTeam = () => {
    handleClose();
    navigate(`/team/${newTeamId}`);
  };

  return (
    <Dialog 
      open={open} 
      onClose={loading ? undefined : handleClose}
      maxWidth="sm" 
      fullWidth
    >
      {success ? (
        <>
          <DialogContent>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center',
              py: 3,
              gap: 2
            }}>
              <Box sx={{ 
                width: 40, 
                height: 40, 
                borderRadius: '50%', 
                bgcolor: 'success.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 1
              }}>
                <DoneAllRounded sx={{ color: 'white' }} />
              </Box>
              <Typography variant="h6" align="center">
                Team Created Successfully!
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center">
                Your new team is ready to go
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions sx={{ p: 2, pt: 0 }}>
            <Button 
              onClick={handleClose}
              sx={{ borderRadius: 2 }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              onClick={goToNewTeam}
              sx={{ borderRadius: 2 }}
              endIcon={<OpenInNew />}
            >
              Go to Team
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Create New Team</DialogTitle>
          <DialogContent>
            <Box sx={{ pt: 2 }}>
              <TextField
                autoFocus
                label="Team Name"
                fullWidth
                value={teamName}
                onChange={(e) => {
                  setTeamName(e.target.value);
                  setError('');
                }}
                onKeyPress={handleKeyPress}
                error={!!error}
                helperText={error || `${teamName.length}/100 characters`}
                disabled={loading}
                inputProps={{
                  maxLength: 100
                }}
                placeholder="Enter your team name"
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={handleClose} 
              disabled={loading}
              sx={{ borderRadius: 2 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleCreateTeam}
              variant="contained"
              disabled={loading || !teamName.trim()}
              sx={{ borderRadius: 2 }}
              startIcon={loading ? <CircularProgress size={20} /> : <Add />}
            >
              Create Team
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

// Add this new component near the other modal components
function RenameTeamModal({ open, onClose, team, onSuccess }) {
  const [newName, setNewName] = useState(team?.name || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [success, setSuccess] = useState(false);
  const Functions = HelperFunctions();

  const handleRename = async () => {
    const trimmedName = newName.trim();
    if (!trimmedName) {
      setError('Team name is required');
      return;
    }

    if (trimmedName.length > 100) {
      setError('Team name must be 100 characters or less');
      return;
    }

    setLoading(true);
    setError('');

    try {
      console.log('Updating team name...'); // Debug log
      await Functions.updateTeamName(team.id, trimmedName);
      console.log('Team name updated, refreshing data...'); // Debug log
      await onSuccess();
      console.log('Setting success state...'); // Debug log
      setSuccess(true);
    } catch (error) {
      console.error('Error renaming team:', error);
      setError('Failed to rename team. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    console.log('Handling close...'); // Debug log
    setNewName(team?.name || '');
    setError('');
    setSuccess(false);
    setShowConfirmation(false);
    onClose();
  };

  const handleConfirmationClose = () => {
    console.log('Handling confirmation close...'); // Debug log
    setShowConfirmation(false);
  };

  // Handle closing confirmation dialog
  const handleBack = () => {
    handleConfirmationClose();
  };

  return (
    <>
      <Dialog 
        open={open && !showConfirmation && !success} 
        onClose={loading ? undefined : handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Rename Team</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2 }}>
            <TextField
              autoFocus
              label="Team Name"
              fullWidth
              value={newName}
              onChange={(e) => {
                setNewName(e.target.value);
                setError('');
              }}
              error={!!error}
              helperText={error || `${newName.length}/100 characters`}
              disabled={loading}
              inputProps={{
                maxLength: 100
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            disabled={loading}
            sx={{ borderRadius: 2 }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => setShowConfirmation(true)}
            variant="contained"
            disabled={loading || !newName.trim() || newName.trim() === team?.name}
            sx={{ borderRadius: 2 }}
          >
            Review Change
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={showConfirmation && !success}
        onClose={handleConfirmationClose} // Updated this line
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Confirm Team Rename</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" color="warning.main" gutterBottom>
              ⚠️ Warning: This action will rename the team!
            </Typography>
            
            <Box sx={{ mt: 3, p: 2, bgcolor: 'rgba(71, 40, 196, 0.05)', borderRadius: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Current name
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {team?.name}
              </Typography>
              
              <Typography variant="body2" color="text.secondary" gutterBottom>
                New name
              </Typography>
              <Typography variant="body1" sx={{ color: 'primary.main', fontWeight: 500 }}>
                {newName}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleBack}
            disabled={loading}
            sx={{ borderRadius: 2 }}
          >
            Back
          </Button>
          <Button
            onClick={handleRename}
            variant="contained"
            disabled={loading}
            sx={{ borderRadius: 2 }}
            startIcon={loading ? <CircularProgress size={20} /> : null}
          >
            Confirm Rename
          </Button>
        </DialogActions>
      </Dialog>

      {/* Success Dialog */}
      <Dialog
        open={success}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            py: 3,
            gap: 2
          }}>
            <Box sx={{ 
              width: 40, 
              height: 40, 
              borderRadius: '50%', 
              bgcolor: 'success.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 1
            }}>
              <DoneAllRounded sx={{ color: 'white' }} />
            </Box>
            <Typography variant="h6" align="center">
              Team Renamed Successfully!
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center">
              The team name has been updated to "{newName}"
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ p: 2, pt: 0 }}>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ borderRadius: 2 }}
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default function AdminDashboard() {
  const { isLoaded, isSignedIn, user } = useUser();
  const navigate = useNavigate();
  const Functions = HelperFunctions();
  
  const [teams, setTeams] = useState([]);
  const [teamSearchQuery, setTeamSearchQuery] = useState('');

  // Create a filtered teams memoized value
  const filteredTeams = useMemo(() => {
    return teams
      .filter(team => 
        team.name.toLowerCase().includes(teamSearchQuery.toLowerCase())
      )
      .sort((a, b) => {
        // Get posts this month count for each team
        const aPostsThisMonth = a.usageMetrics?.postsThisMonth || 0;
        const bPostsThisMonth = b.usageMetrics?.postsThisMonth || 0;
        
        // Sort descending (highest count first)
        return bPostsThisMonth - aPostsThisMonth;
      });
  }, [teams, teamSearchQuery]);

  const [allPosts, setAllPosts] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [campaignsLoading, setCampaignsLoading] = useState(true);
  const [campaignsError, setCampaignsError] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [postsLoading, setPostsLoading] = useState(false);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [teamsDataLoading, setTeamsDataLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [totalTeams, setTotalTeams] = useState(0);
  const [totalPosts, setTotalPosts] = useState(0);
  const [platform, setPlatform] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [updatePlanTeam, setUpdatePlanTeam] = useState(null);
  const [teamUsageData, setTeamUsageData] = useState({});
  const tableRef = React.useRef(null);
  const [graphData, setGraphData] = useState([]);
  const [graphLoading, setGraphLoading] = useState(true);
  const [metricsData, setMetricsData] = useState({
    totalPosts: 0,
    totalTeams: 0,
    postsLastDay: 0,
    postsLastWeek: 0,
    postsLastMonth: 0
  });
  const [chartTimeRange, setChartTimeRange] = useState('30d');
  const [interval, setInterval] = useState('day');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [error, setError] = useState('');
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [createTeamOpen, setCreateTeamOpen] = useState(false);
  const [renameTeam, setRenameTeam] = useState(null);
  const [postSearchQuery, setPostSearchQuery] = useState('');
  // Add state for tracking which team's link was just copied
  const [copiedTeamLink, setCopiedTeamLink] = useState(null);

  const timeRangeOptions = [
    { value: '24h', label: 'Last 24 Hours' },
    { value: '7d', label: 'Last Week' },
    { value: '30d', label: 'Last Month' },
    { value: '90d', label: 'Last 3 Months' },
    { value: '180d', label: 'Last 6 Months' },
    { value: '365d', label: 'Last Year' },
    { value: 'all', label: 'Lifetime' }
  ];

  // Check if user is admin
  const isAdmin = useMemo(() => {
    if (!user?.primaryEmailAddress?.emailAddress) return false;
    return ADMIN_EMAILS.includes(user.primaryEmailAddress.emailAddress);
  }, [user]);

  // Add effect to filter teams when search query changes
  useEffect(() => {
      const filtered = teams.filter(team => 
        team.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setTotalTeams(filtered.length);
  }, [searchQuery, teams]);

  // Add effect to filter posts when search query changes
  useEffect(() => {
    if (allPosts) {
      // Filter posts based on search query
      const filtered = allPosts.filter(post => {
        const searchLower = searchQuery.toLowerCase();
        return (
          (post.account && post.account.toLowerCase().includes(searchLower)) ||
          (post.username && post.username.toLowerCase().includes(searchLower)) ||
          (post.platform && post.platform.toLowerCase().includes(searchLower)) ||
          (post.postType && post.postType.toLowerCase().includes(searchLower))
        );
      });
      setTotalPosts(filtered.length);
    }
  }, [searchQuery, teams, allPosts, selectedTab]);

  // Add effect to handle tab changes
  useEffect(() => {
    // Reset pagination when changing tabs
    setPage(1);
      setTotalTeams(filteredTeams ? filteredTeams.length : 0);
      setTotalPosts(allPosts ? allPosts.length : 0);
    
  }, [selectedTab, filteredTeams.length, allPosts]);

  // Modify fetchAllPosts to properly handle pagination
  const fetchAllPosts = async () => {
    console.log('Starting fetchAllPosts...');
    const postsMap = new Map();
    let totalPosts = 0;

    try {
      // First request to get total count
      console.log('Making initial request with page 1');
      const initialParams = {
        page: 1,
        limit: 1000,
        platform: platform || undefined,
        startDate: dateRange[0]?.toISOString() || undefined,
        endDate: dateRange[1]?.toISOString() || undefined
      };
      console.log('Initial request params:', initialParams);

      const initialResult = await Functions.getAllPosts(initialParams);

      if (!initialResult?.data || !Array.isArray(initialResult.data)) {
        console.error('Invalid initial response:', initialResult);
        return [];
      }

      totalPosts = initialResult.total;
      console.log(`Total posts available: ${totalPosts}`);

      // Store first page results
      initialResult.data.forEach(post => postsMap.set(post.id, post));
      console.log(`Stored ${postsMap.size} posts from first page`);

      // Calculate remaining pages
      const totalPages = Math.ceil(totalPosts / 1000);
      console.log(`Total pages to fetch: ${totalPages}`);

      // Fetch remaining pages
      if (totalPages > 1) {
        for (let currentPage = 2; currentPage <= totalPages; currentPage++) {
          console.log(`\nFetching page ${currentPage} of ${totalPages}`);
          
          const pageParams = {
            ...initialParams,
            page: currentPage
          };
          console.log('Request params:', pageParams);

          const pageResult = await Functions.getAllPosts(pageParams);
          
          if (pageResult?.data && Array.isArray(pageResult.data)) {
            pageResult.data.forEach(post => postsMap.set(post.id, post));
            console.log(`Added posts from page ${currentPage}, total unique posts: ${postsMap.size}`);
          } else {
            console.error(`Invalid response for page ${currentPage}:`, pageResult);
          }
        }
      }

      // Convert map to array and sort by createdAt in descending order
      const sortedPosts = Array.from(postsMap.values())
        .map(post => {
          // Safely parse the date
          let timestamp;
          try {
            timestamp = post.createdAt ? new Date(post.createdAt).getTime() : 0;
            if (isNaN(timestamp)) timestamp = 0;
          } catch (e) {
            console.warn('Invalid date for post:', post.id);
            timestamp = 0;
          }

          return {
            ...post,
            // Store both original date and timestamp
            createdAt: post.createdAt || null,
            timestamp: timestamp
          };
        })
        .sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0)); // Sort by timestamp

      // Log the first few posts to verify sorting
      console.log('First 5 posts dates:', sortedPosts.slice(0, 5).map(p => ({
        id: p.id,
        createdAt: p.createdAt,
        timestamp: p.timestamp
      })));
      
      return sortedPosts;

    } catch (error) {
      console.error('Error in fetchAllPosts:', error);
      throw error;
    }
  };

  // First define calculateMetrics
  const calculateMetrics = useCallback((posts) => {
    const now = new Date();
    const oneDayAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));
    const oneWeekAgo = new Date(now.getTime() - (7 * 24 * 60 * 60 * 1000));
    const oneMonthAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));

    let postsLastDay = 0;
    let postsLastWeek = 0;
    let postsLastMonth = 0;
    
    posts.forEach(post => {
      const createdDate = new Date(post.createdAt);
      
      if (createdDate >= oneDayAgo && createdDate <= now) {
        postsLastDay++;
      }
      if (createdDate >= oneWeekAgo && createdDate <= now) {
        postsLastWeek++;
      }
      if (createdDate >= oneMonthAgo && createdDate <= now) {
        postsLastMonth++;
      }
    });

    return {
      totalPosts: posts.length,
      totalTeams: new Set(posts.map(post => post.teamId)).size,
      postsLastDay,
      postsLastWeek,
      postsLastMonth
    };
  }, []);

  // Then generateGraphData
  const generateGraphData = useCallback((posts, timeRange) => {
    const now = new Date();
    now.setHours(23, 59, 59, 999);
    
    const getStartDate = () => {
      const start = new Date(now);
      switch (timeRange) {
        case '24h': start.setHours(start.getHours() - 24); break;
        case '7d': start.setDate(start.getDate() - 7); break;
        case '30d': start.setDate(start.getDate() - 30); break;
        case '90d': start.setDate(start.getDate() - 90); break;
        case '180d': start.setDate(start.getDate() - 180); break;
        case '365d': start.setDate(start.getDate() - 365); break;
        case 'all': return new Date(2021, 0, 1);
        default: start.setDate(start.getDate() - 30);
      }
      return start;
    };

    const startDate = getStartDate();
    startDate.setHours(0, 0, 0, 0);

    // Create a map to store daily counts
    const dailyData = new Map();
    
    // Initialize all dates in the range
    let currentDate = new Date(startDate);
    while (currentDate <= now) {
      const dateKey = currentDate.toISOString().split('T')[0];
      dailyData.set(dateKey, { date: dateKey, created: 0, posted: 0 });
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Count posts for each day
    posts.forEach(post => {
      const createdDate = new Date(post.createdAt);
      const postedDate = new Date(post.postedAt);

      if (createdDate >= startDate && createdDate <= now) {
        const createdKey = createdDate.toISOString().split('T')[0];
        const dayData = dailyData.get(createdKey);
        if (dayData) {
          dayData.created += 1;
        }
      }

      if (postedDate >= startDate && postedDate <= now) {
        const postedKey = postedDate.toISOString().split('T')[0];
        const dayData = dailyData.get(postedKey);
        if (dayData) {
          dayData.posted += 1;
        }
      }
    });

    // Convert map to array and sort by date
    const graphData = Array.from(dailyData.values())
      .sort((a, b) => a.date.localeCompare(b.date));

    // For hourly data in 24h view, split into hours
    if (timeRange === '24h') {
      const hourlyData = new Map();
      const yesterday = new Date(now);
      yesterday.setHours(now.getHours() - 24);

      // Initialize all hours
      for (let i = 0; i < 24; i++) {
        const date = new Date(yesterday);
        date.setHours(yesterday.getHours() + i);
        const hourKey = date.toISOString().slice(0, 13) + ':00';
        hourlyData.set(hourKey, { 
          date: hourKey,
          created: 0,
          posted: 0 
        });
      }

      // Count posts for each hour
      posts.forEach(post => {
        const createdDate = new Date(post.createdAt);
        const postedDate = new Date(post.postedAt);

        if (createdDate >= yesterday && createdDate <= now) {
          const hourKey = createdDate.toISOString().slice(0, 13) + ':00';
          const hourData = hourlyData.get(hourKey);
          if (hourData) {
            hourData.created += 1;
          }
        }

        if (postedDate >= yesterday && postedDate <= now) {
          const hourKey = postedDate.toISOString().slice(0, 13) + ':00';
          const hourData = hourlyData.get(hourKey);
          if (hourData) {
            hourData.posted += 1;
          }
        }
      });

      return Array.from(hourlyData.values())
        .sort((a, b) => a.date.localeCompare(b.date));
    }

    return graphData;
  }, []);

  // Finally loadInitialData
  const loadInitialData = useCallback(async () => {
    if (!isAdmin) return;
    
    try {
      setTeamsLoading(true);
      setTeamsDataLoading(true);
      setGraphLoading(true);
      setPostsLoading(true);

      // Load teams data
      try {
        const teamsResult = await Functions.getAllTeams({ limit: 1000 });
        if (Array.isArray(teamsResult)) {
          setTeams(teamsResult);
          setTotalTeams(teamsResult.length);
        }
      } catch (error) {
        console.error('Error fetching teams:', error);
      }

      // Load posts data
      try {
        const allPosts = await fetchAllPosts();
        if (allPosts.length > 0) {
          const processedPosts = await Promise.all(allPosts.map(async (post) => {
            try {
              const vibeCheck = post?.data?.vibeCheckData 
                ? post.data.vibeCheckData 
                : null;
              
              const numComments = post?.data?.engagement?.comments || 0;
              const views = post?.data?.engagement?.views > 0
                ? post.data.engagement.views
                : estimateViews(
                    post.data.engagement.likes,
                    post.data.engagement.comments,
                    post.data.engagement.shares,
                    post.data.engagement.saves,
                    post.data.platform
                  );

              return {
                id: post.id,
                teamId: post.teamId,
                avatar: post.data.userAvatar,
                thumbnail: post.data.thumbnail,
                account: post.data.creatorName,
                username: post.data.username,
                vibeScore: vibeCheck?.vibeScore,
                sentimentData: vibeCheck?.sentimentData,
                relevanceData: vibeCheck?.relevanceData,
                consensusData: vibeCheck?.consensusData,
                positiveSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.positivePercentage : null,
                neutralSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.neutralPercentage : null,
                negativeSentimentPercentage: numComments > 0 ? vibeCheck?.sentimentData?.negativePercentage : null,
                platform: formatData(post.data.platform),
                postType: post.data.postType,
                createdAt: post.createdAt,
                postedAt: post.data.postedAt,
                views,
                comments: numComments,
                likes: post.data.engagement.likes,
                shares: post.data.engagement.shares,
                saves: post.data.engagement.saves || 0,
                groupIds: post.groupIds || [],
                link: post.link || "",
              };
            } catch (err) {
              console.error(`Error processing post ${post.id}:`, err);
              return null;
            }
          }));

          const validPosts = processedPosts.filter(post => post !== null);
          setAllPosts(validPosts);
          setTotalPosts(validPosts.length);
          
          const metrics = calculateMetrics(validPosts);
          setMetricsData(metrics);
          
          const newGraphData = generateGraphData(validPosts, chartTimeRange);
          setGraphData(newGraphData);
        }
      } catch (error) {
        console.error('Error fetching and processing posts:', error);
      }

    } catch (error) {
      console.error('Error loading initial data:', error);
    } finally {
      setTeamsLoading(false);
      setTeamsDataLoading(false);
      setPostsLoading(false);
      setGraphLoading(false);
      setInitialLoadComplete(true);
    }
  }, [isAdmin, chartTimeRange, fetchAllPosts, generateGraphData, calculateMetrics, Functions]);

  // Initial load effect
  useEffect(() => {
    if (!isAdmin || initialLoadComplete) return;
    loadInitialData();
  }, [isAdmin, initialLoadComplete, loadInitialData]);

  // Handle refresh separately from initial load
  const handleRefresh = useCallback(() => {
    if (!isAdmin) return;
    loadInitialData();
  }, [isAdmin, loadInitialData]);

  const gridOptions = useMemo(() => ({
    enableCellTextSelection: true,
    ensureDomOrder: true,
    suppressColumnVirtualisation: true,
    animateRows: false,
    rowHeight: 60,
    defaultColDef: {
      sortable: true,
      resizable: true,
    },
    // Set default sorting
    defaultSortModel: [{
      colId: 'date',
      sort: 'desc'
    }],
    // Pagination settings
    pagination: true,
    paginationPageSize: 100,
    suppressPaginationPanel: false, // Show the pagination controls
    paginationAutoPageSize: false, // Don't auto-calculate page size
    suppressScrollOnNewData: true,
    suppressAnimationFrame: true,
    suppressColumnMoveAnimation: true,
    suppressLoadingOverlay: true,
    suppressNoRowsOverlay: true
  }), []);

  const columnDefs = useMemo(() => [
    {
      field: "",
      pinned: "left",
      sortable: false,
      cellRenderer: (props) => {
        return (
          <IconButton
            size="small"
            onClick={() => {
              setSelectedPostId(props.data.id);
              setIsModalOpen(true);
              setTeamId(props.data.teamId);
            }}
          >
            <ArrowTopRightOnSquareIcon style={{ height: 20, width: 20, color:"#5C6078" }} />
          </IconButton>
        );
      },
      width: 60,
    },
    {
      field: "account",
      headerName: "Account",
      cellStyle: { display: "flex", alignItems: "center" },
      valueFormatter: (params) => {
        return params.value ? params.value.replace(/\s\s+/g, " ") : "";
      },
      width: 220,
      cellRenderer: (props) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "1rem",
              overflow: "hidden",
              width: 200,
            }}
          >
            <Avatar
              sx={{ width: 30, height: 30 }}
              src={props.data.avatar}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <Typography>
                {props.data.account}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                @{props.data.username?.replace("@", "")}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'link',
      headerName: 'Link',
      width: 65,
      cellStyle: { display: "flex", alignItems: "center", height: "100%" },
      cellRenderer: (props) => {
        return props.value ? (
          <Button
            href={props.value}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            sx={{ minWidth: 'auto' }}
          >
            Link
          </Button>
        ) : null;
      }
    },
    {
      field: "platform",
      headerName: "Platform",
      width: 110,
      cellRenderer: (props) => {
        return (
          <Typography variant="body1" fontWeight={600}>
            {formatPlatform(props.data.platform)}
          </Typography>
        );
      },
      cellStyle: { display: "flex", alignItems: "center" },
    },
    {
      field: "postType",
      headerName: "Type",
      valueGetter: (params) => {
        const postType = params.data.postType;
        if (!postType) return "N/A";
        return postType === "reel" ? "REELS" : postType === "short" ? "SHORTS" : postType.toUpperCase();
      },
      cellRenderer: (props) => {
        const postType = props.data.postType;
        const label = !postType ? "N/A" : 
                     postType === "reel" ? "REELS" : 
                     postType === "short" ? "SHORTS" : 
                     postType.toUpperCase();
        return <Chip 
          sx={{
            fontWeight: 600,
            fontSize: 10,
            color: "#333",
          }}
          label={label}
        />;
      },
      cellStyle: { display: "flex", alignItems: "center" },
      width: 110,
    },
    {
      field: "vibeScore",
      headerName: "Vibe Score",
      cellStyle: { display: "flex", alignItems: "center" },
      valueGetter: (params) => params.data.vibeScore != null ? params.data.vibeScore.toFixed(1) : 0,
      width: 110,
      cellRenderer: (props) => {
        const score = props.data.vibeScore;
        const comments = props.data.comments;
        
        if (score == null || !comments || comments === 0 || score === 0 || score === 0.0) {
          return (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                position: "relative",
                p: 1,
              }}
            >
              <Tooltip title="Vibe Score is not available for posts with no comments.">
                <Typography sx={{ fontSize: 12, fontWeight: 700, color: "#aaa" }}>
                  N/A
                </Typography>
              </Tooltip>
            </Box>
          );
        }

        const color = score >= 7
          ? "#089616"
          : score >= 5
          ? "#74d52e"
          : score >= 3
          ? "#ffb93d"
          : "#fb3e3e";

        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              position: "relative",
            }}
          >
            <Avatar
              sx={{
                width: "40px",
                height: "40px",
                color: color,
                background: "transparent",
                fontSize: "1rem",
                fontWeight: 900,
              }}
            >
              {score.toFixed(1)}
            </Avatar>
          </Box>
        );
      },
    },
    {
      field: "views",
      headerName: "Views",
      cellStyle: { display: "flex", alignItems: "center" },
      valueFormatter: (params) => {
        return params.value == 0 || isNaN(params.value) || !isFinite(params.value) ? "N/A" : params.value.toFixed(0);
      },
      cellRenderer: (props) => {
        return props.data.views == 0 || isNaN(props.data.views) || !isFinite(props.data.views) ? (
          <Typography sx={{ fontWeight: 600, fontSize: 14, color: "#888" }}>
            N/A
          </Typography>
        ) : (
          <Typography>
            {formatNumber(props.data.views)}
          </Typography>
        );
      },
      width: 110,
    },
    {
      field: "likes",
      headerName: "Likes",
      cellStyle: { display: "flex", alignItems: "center" },
      cellRenderer: (props) => {
        return isNaN(props.data.likes) || !isFinite(props.data.likes) ? (
          <Typography sx={{ fontWeight: 600, fontSize: 12, color: "#888" }}>
            N/A
          </Typography>
        ) : (
          <Typography>
            {formatNumber(props.data.likes)}
          </Typography>
        );
      },
      width: 110,
    },
    {
      field: "comments",
      headerName: "Comments",
      cellStyle: { display: "flex", alignItems: "center" },
      cellRenderer: (props) => {
        return isNaN(props.data.comments) || !isFinite(props.data.comments) ? (
          <Typography sx={{ fontWeight: 600, fontSize: 12, color: "#888" }}>
            N/A
          </Typography>
        ) : (
          <Typography>
            {formatNumber(props.data.comments)}
          </Typography>
        );
      },
      width: 110,
    },
    {
      field: "date",
      headerName: "Date",
      sortable: true,
      valueGetter: (params) => {
        // Safely get timestamp for sorting
        try {
          return params.data.createdAt ? new Date(params.data.createdAt).getTime() : 0;
        } catch (e) {
          console.warn('Invalid date for sorting:', params.data.id);
          return 0;
        }
      },
      cellRenderer: (props) => {
        try {
          if (!props.data.createdAt) return <Typography>N/A</Typography>;
          return <Typography>{formatFullDate(props.data.createdAt)}</Typography>;
        } catch (e) {
          console.warn('Invalid date for display:', props.data.id);
          return <Typography>Invalid Date</Typography>;
        }
      },
      cellStyle: { display: "flex", alignItems: "center" },
      width: 110,
      sort: 'desc',
      sortIndex: 0
    },
  ], [navigate]);

  const handlePlanUpdated = () => {
    handleRefresh();
  };

  const handleTimeRangeChange = async (newTimeRange) => {
    try {
      setGraphLoading(true);
      const newGraphData = generateGraphData(allPosts, newTimeRange);
      setGraphData(newGraphData);
    } catch (error) {
      console.error('Error updating graph data:', error);
    } finally {
      setGraphLoading(false);
    }
  };

  const refreshAllData = async () => {
    console.log('Starting data refresh...'); // Debug log
    try {
      setTeamsLoading(true);
      
      // Fetch fresh teams data with cache busting
      const res = await Functions.getAllTeams({ limit: 1000, forceRefresh: true });
      const teamsResult = res.data;
      
      if (Array.isArray(teamsResult)) {
        // Update teams state
        setTeams(teamsResult);
        setTotalTeams(teamsResult.length);
        
        console.log('Teams data refreshed successfully'); // Debug log
      } else {
        console.error('Invalid teams response:', teamsResult);
        throw new Error('Invalid teams data structure received');
      }
    } catch (error) {
      console.error('Error in refreshAllData:', error);
      throw error; // Propagate error to caller
    } finally {
      setTeamsLoading(false);
    }
  };

  // Redirect if not admin
  if (!isAdmin) {
    return <Navigate to="/" replace />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div style={{
        backgroundColor: "#f7f8fa",
        width: "100%",
        minHeight: "100vh",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}>
        <AppBar
          position="static"
          elevation={0}
          sx={{ background: "#fff", borderBottom: "1px solid #ddd" }}
        >
          <Toolbar>
            <Box sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              maxWidth: 1200,
              width: "100%",
              margin: "0 auto",
              gap: 2
            }}>
              <Box>
                <ButtonBase>
                  <img
                    src={"/siftsy_logo.png"}
                    alt="logo"
                    style={{ width: 80, height: "auto", padding: "5px" }}
                  />
                </ButtonBase>
              </Box>
              <Tabs 
                value={selectedTab} 
                onChange={(e, newValue) => setSelectedTab(newValue)}
                sx={{ ml: 4 }}
              >
                <Tab label="Overview" />
                <Tab label="Teams" />
                <Tab label="Posts" />
                {/* <Tab label="Campaigns" /> */}
              </Tabs>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton 
                onClick={handleRefresh}
                disabled={teamsLoading || postsLoading || graphLoading}
                sx={{ mr: 2 }}
              >
                <Refresh />
              </IconButton>
              <Box>
                <UserButton />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>

        <Box sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden"
        }}>
          {selectedTab === 0 && (
            <Box sx={{
              maxWidth: 1200,
              width: "100%",
              margin: "0 auto",
              p: 4,
            }}>
              <Typography variant="h5" sx={{ mb: 3, fontWeight: 600 }}>
                Platform Overview
              </Typography>

              {/* Posts Row */}
              <Box>
                <Typography variant="h6" sx={{ mb: 2, fontWeight: 500, color: 'text.secondary' }}>
                  Posts
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: 3,
                        p: 3,
                        border: '1px solid #eee',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <BarChart sx={{ color: 'text.secondary', mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Last 24 Hours
                        </Typography>
                      </Box>
                      {teamsDataLoading ? (
                        <Skeleton variant="text" width="60%" height={42} />
                      ) : (
                        <Typography variant="h4">
                          {formatNumber(metricsData.postsLastDay)}
                        </Typography>
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: 3,
                        p: 3,
                        border: '1px solid #eee',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <BarChart sx={{ color: 'text.secondary', mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Last Week
                        </Typography>
                      </Box>
                      {teamsDataLoading ? (
                        <Skeleton variant="text" width="60%" height={42} />
                      ) : (
                        <Typography variant="h4">
                          {formatNumber(metricsData.postsLastWeek)}
                        </Typography>
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: 3,
                        p: 3,
                        border: '1px solid #eee',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <BarChart sx={{ color: 'text.secondary', mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Last Month
                        </Typography>
                      </Box>
                      {teamsDataLoading ? (
                        <Skeleton variant="text" width="60%" height={42} />
                      ) : (
                        <Typography variant="h4">
                          {formatNumber(metricsData.postsLastMonth)}
                        </Typography>
                      )}
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      elevation={0}
                      sx={{
                        borderRadius: 3,
                        p: 3,
                        border: '1px solid #eee',
                      }}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <BarChart sx={{ color: 'text.secondary', mr: 1 }} />
                        <Typography variant="body2" color="text.secondary">
                          Total Posts
                        </Typography>
                      </Box>
                      {teamsDataLoading ? (
                        <Skeleton variant="text" width="60%" height={42} />
                      ) : (
                        <Typography variant="h4">
                          {formatNumber(metricsData.totalPosts)}
                        </Typography>
                      )}
                    </Card>
                  </Grid>
                </Grid>
              </Box>

              {/* Posts Over Time Graph */}
              <Box sx={{ mt: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 500, color: 'text.secondary' }}>
                    Posts Over Time
                  </Typography>
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <Select
                      value={chartTimeRange}
                      onChange={(e) => {
                        const newTimeRange = e.target.value;
                        setChartTimeRange(newTimeRange);
                        // Update interval based on new time range
                        const newInterval = (() => {
                          switch (newTimeRange) {
                            case '24h':
                              return 'hour';
                            case '7d':
                            case '30d':
                              return 'day';
                            case '90d':
                            case '180d':
                              return 'week';
                            case '365d':
                            case 'all':
                              return 'month';
                            default:
                              return 'day';
                          }
                        })();
                        setInterval(newInterval);
                        handleTimeRangeChange(newTimeRange);
                      }}
                      sx={{ background: '#fff' }}
                    >
                      {timeRangeOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Card
                  elevation={0}
                  sx={{
                    borderRadius: 3,
                    p: 3,
                    border: '1px solid #eee',
                    height: 400,
                  }}
                >
                  {graphLoading ? (
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        data={graphData}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 40,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                        <XAxis
                          dataKey="date"
                          angle={-45}
                          textAnchor="end"
                          height={40}
                          tick={{ fontSize: 12, fill: '#666' }}
                          interval={interval === 'hour' ? 2 : chartTimeRange === '30d' ? 5 : 'preserveStartEnd'}
                        />
                        <YAxis 
                          tick={{ fontSize: 12, fill: '#666' }}
                          allowDecimals={false}
                          label={{ 
                            value: 'Number of Posts', 
                            angle: -90, 
                            position: 'insideLeft',
                            style: { fill: '#666' }
                          }}
                        />
                        <RechartsTooltip
                          contentStyle={{
                            backgroundColor: '#fff',
                            border: '1px solid #eee',
                            borderRadius: 8,
                            padding: '10px',
                          }}
                          labelStyle={{ color: '#666', marginBottom: '5px' }}
                          formatter={(value, name) => [
                            `${formatNumber(value)} posts`, 
                            name === 'created' ? 'Created in Siftsy' : 'Posted on Platform'
                          ]}
                          labelFormatter={(label) => `Date: ${label}`}
                        />
                        <Line
                          type="monotone"
                          dataKey="created"
                          name="created"
                          stroke="#4728c4"
                          strokeWidth={2}
                          dot={{ fill: '#4728c4', r: 2 }}
                          activeDot={{ r: 6 }}
                        />
                        <Line
                          type="monotone"
                          dataKey="posted"
                          name="posted"
                          stroke="#e0e0e0"
                          strokeWidth={2}
                          dot={{ fill: '#e0e0e0', r: 2 }}
                          activeDot={{ r: 6 }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  )}
                </Card>
              </Box>
            </Box>
          )}

          {selectedTab === 1 && (
            <Box sx={{
              maxWidth: 1200,
              margin: "0 auto",
              p: 2,
              width:"100%"
            }}>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: 2,
                mb: 3,
              }}>
                <Box sx={{ 
                  display: "flex", 
                  alignItems: "center",
                  maxWidth: 300,
                  background: "#fff",
                  borderRadius: 2,
                  border: "1px solid #eee",
                  p: 1,
                }}>
                  <Search sx={{ color: "text.secondary", mr: 1 }} />
                  <TextField
                    size="small"
                    placeholder="Search teams..."
                    value={teamSearchQuery}
                    onChange={(e) => setTeamSearchQuery(e.target.value)}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <Box sx={{ display: "flex", gap: 2, ml: "auto" }}>
                  <Button
                    variant="contained"
                    startIcon={<Add />}
                    onClick={() => setCreateTeamOpen(true)}
                    sx={{ borderRadius: 2 }}
                  >
                    Create Team
                  </Button>
                </Box>
              </Box>

              <Grid container spacing={2}>
                {teamsLoading ? (
                  // Initial loading state - show full card skeletons
                  [...Array(6)].map((_, index) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} ey={index}>
                      <Card
                        elevation={0}
                        sx={{
                          borderRadius: 3,
                          p: 3,
                          border: '1px solid #eee',
                          height: 200
                        }}
                      >
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                          <Skeleton variant="circular" width={40} height={40} sx={{ mr: 2 }} />
                          <Box sx={{ flex: 1 }}>
                            <Skeleton variant="text" width="60%" height={24} sx={{ mb: 0.5 }} />
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                              <Skeleton variant="text" width="30%" height={20} />
                              <Typography variant="body2" color="text.secondary">•</Typography>
                              <Skeleton variant="text" width="30%" height={20} />
                            </Box>
                          </Box>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                          <Skeleton variant="rounded" width={80} height={24} />
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, mt: 'auto' }}>
                          <Skeleton variant="rounded" width="100%" height={36} />
                          <Skeleton variant="rounded" width="100%" height={36} />
                        </Box>
                      </Card>
                    </Grid>
                  ))
                ) : filteredTeams.length > 0 ? (
                  filteredTeams.map((team) => (
                    <Grid item xs={12} sm={6} md={6} lg={4} key={team.id}>
                      <Card
                        elevation={0}
                        sx={{
                          ...teamCardStyles,
                          borderRadius: 3,
                          padding: '24px !important',
                          display: 'flex !important',
                          flexDirection: 'column !important',
                          border: '1px solid #eee',
                          height: '200px !important',
                          minHeight: '200px !important',
                          maxHeight: '200px !important',
                          overflow: 'hidden',
                          [theme.breakpoints.up('sm')]: {
                            padding: '24px !important',
                            height: '200px !important',
                            minHeight: '200px !important',
                            maxHeight: '200px !important',
                          },
                          position: 'relative',
                          transform: 'translateZ(0)', // Force GPU acceleration
                          backfaceVisibility: 'hidden', // Prevent Chrome repaints
                          WebkitBackfaceVisibility: 'hidden',
                          WebkitTransform: 'translateZ(0) scale(1.0, 1.0)',
                          willChange: 'transform', // Hint to browser about expected changes
                          '&::after': {
                            content: '""',
                            position: 'absolute',
                            height: '200px !important',
                            width: '100%',
                            top: 0,
                            left: 0,
                            pointerEvents: 'none',
                          }
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: -3, gap:1, flexDirection:"row" }}>

                        <IconButton 
                            onClick={(e) => {
                              e.stopPropagation();
                              const url = `${window.location.origin}/team/${team.id}`;
                              navigator.clipboard.writeText(url).then(() => {
                                setCopiedTeamLink(team.id);
                                setTimeout(() => {
                                  setCopiedTeamLink(null);
                                }, 3000);
                              });
                            }}
                            title="Copy link"
                          >
                            {copiedTeamLink === team.id ? (
                              <DoneAllRounded style={{ color: '#4728c4', height: 18, width: 18 }} />
                            ) : (
                              <LinkIcon style={{ color: '#aaa', height: 18, width: 18 }}/>
                            )}
                          </IconButton>
      
                          <IconButton
                            size="small"
                            onClick={() => setRenameTeam(team)}
                            disabled={teamsDataLoading}
                            sx={{ 
                              color: '#aaa',
                              '&:hover': {
                                color: 'primary.main',
                                bgcolor: 'rgba(71, 40, 196, 0.05)',
                              }
                            }}
                          >
                            <Edit sx={{ fontSize: 18 }} />
                          </IconButton>
                        </Box>
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'flex-start', 
                          mb: 2,
                          mt: 0 // Explicit margin top
                        }}>
                          <Avatar sx={{ bgcolor: 'primary.main', mr: 2, mt: '2px' }}>
                            {team.name ? team.name.charAt(0).toUpperCase() : '?'}
                          </Avatar>
                          <Box sx={{ flex: 1 }}>
                            <Typography 
                              variant="h6" 
                              sx={{ 
                                fontWeight: 600,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: 'vertical',
                                lineHeight: 1.2,
                                height: '2.4em',
                                minHeight: '2.4em',
                                mb: 0.5
                              }}
                            >
                              {team.name}
                            </Typography>
                            {teamsDataLoading ? (
                              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <Skeleton variant="text" width="30%" height={20} />
                                <Typography variant="body2" color="text.secondary">•</Typography>
                                <Skeleton variant="text" width="30%" height={20} />
                              </Box>
                            ) : (
                              <Box sx={{display: 'flex', flexDirection: 'column', gap: 1}}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                  <Typography variant="body2" color="text.secondary">
                                    {formatNumber(team.teamMembers?.length || 0)} {(team.teamMembers?.length || 0) === 1 ? 'member' : 'members'}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    •
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    {formatNumber(team.usageMetrics?.activeCampaigns || 0)} campaigns
                                  </Typography>
                                  {team.usageMetrics?.postsCompleted > 0 && (
                                    <>
                                      <Typography variant="body2" color="text.secondary">
                                        •
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary">
                                        {formatNumber(team.usageMetrics.postsCompleted)} posts
                                      </Typography>
                                    </>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                        <Box sx={{ 
                          display: 'flex', 
                          flexDirection: 'column', 
                          justifyContent: 'flex-start', 
                          alignItems: 'flex-start', 
                          gap: 1,
                          mb: 'auto' // Push content up
                        }}>
                          {team.usageMetrics?.postsThisMonth > 0 && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                              <StackedLineChartOutlined sx={{ color: "#4728c4", mr: 1, height: 16, width: 16 }} />
                              <Typography variant="body2" color="#4728c4" fontWeight={600}>
                                {formatNumber(team.usageMetrics.postsThisMonth)} posts this month
                              </Typography>
                            </Box>
                          )}

                          {teamsDataLoading ? (
                            <Skeleton variant="rounded" width={80} height={24} />
                          ) : (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <ListAlt sx={{ color: "text.secondary", height: 16, width: 16 }} />
                              <Chip 
                                label={team.plan?.name || 'Free'} 
                                size="small"
                                color={team.plan?.name?.toLowerCase().includes('pro') ? 'primary' : 'default'}
                              />
                            </Box>
                          )}
                        </Box>
                        <Box 
  sx={{ 
    display: 'flex', 
    gap: 1, 
    marginTop: 'auto !important',
    marginBottom: '0 !important',
    paddingTop: 2, // Add explicit padding
    position: 'relative' // Ensure proper stacking
  }}
>
  <Button
    variant="outlined"
    size="small"
    onClick={() => setUpdatePlanTeam(team)}
    sx={{ 
      borderRadius: 2, 
      flex: 1,
      height: '32px !important',
      minHeight: '32px !important'
    }}
    disabled={teamsDataLoading}
  >
    Update Plan
  </Button>
  <Button
    variant="contained"
    size="small"
    endIcon={<OpenInNew />}
    onClick={() => navigate(`/team/${team.id}`)}
    sx={{ 
      borderRadius: 2, 
      flex: 1,
      height: '32px !important',
      minHeight: '32px !important'
    }}
    disabled={teamsDataLoading}
  >
    View Team
  </Button>
</Box>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    justifyContent: 'center',
                    minHeight: '400px',
                    bgcolor: 'background.paper',
                    borderRadius: 3,
                    p: 4,
                    mt: 2,
                    width: '100%'
                  }}>
                    <Typography variant="h6" color="text.secondary" gutterBottom>
                      No teams found
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {teamSearchQuery ? 'Try adjusting your search' : 'Create a team to get started'}
                    </Typography>
                  </Box>
                )}
              </Grid>
            </Box>
          )}

          {selectedTab === 2 && (
            <Box sx={{
              maxWidth: 1200,
              width: "100%",
              margin: "0 auto",
              p: 4,
            }}>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mb: 3,
              }}>
                <Box sx={{ 
                  display: "flex", 
                  alignItems: "center",
                  flex: 1,
                  maxWidth: 300,
                  background: "#fff",
                  borderRadius: 2,
                  border: "1px solid #eee",
                  p: 1,
                }}>
                  <Search sx={{ color: "text.secondary", mr: 1 }} />
                  <TextField
                    size="small"
                    placeholder="Search by account, username, platform..."
                    value={postSearchQuery}
                    onChange={(e) => setPostSearchQuery(e.target.value)}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
                <FormControl sx={{ minWidth: 120 }}>
                  <Select
                    size="small"
                    value={platform || ''}
                    onChange={(e) => setPlatform(e.target.value)}
                    displayEmpty
                    sx={{ background: '#fff' }}
                  >
                    <MenuItem value="">All Platforms</MenuItem>
                    <MenuItem value="tiktok">TikTok</MenuItem>
                    <MenuItem value="instagram">Instagram</MenuItem>
                    <MenuItem value="youtube">YouTube</MenuItem>
                  </Select>
                </FormControl>
              </Box>

              <Card elevation={0} sx={{ borderRadius: 3, border: '1px solid #eee' }}>
                <Box sx={{ p: 3 }}>
                  <Typography variant="h6" gutterBottom>
                    All Posts ({formatNumber(totalPosts)} total)
                  </Typography>
                  {postsLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="calc(100vh - 300px)">
                      <CircularProgress />
                    </Box>
                  ) : !allPosts || allPosts.length === 0 ? (
                    <Box display="flex" justifyContent="center" alignItems="center" height="calc(100vh - 300px)">
                      <Typography>No posts found</Typography>
                    </Box>
                  ) : (
                    <>
                      <div
                        className="ag-theme-quartz"
                        style={{
                          height: 'calc(100vh - 350px)',
                          width: "100%",
                          background: "transparent",
                        }}
                      >
                        <AgGridReact
                          ref={tableRef}
                          rowData={allPosts.filter(post => {
                            const searchLower = postSearchQuery.toLowerCase();
                            return (
                              (post.account && post.account.toLowerCase().includes(searchLower)) ||
                              (post.username && post.username.toLowerCase().includes(searchLower)) ||
                              (post.platform && post.platform.toLowerCase().includes(searchLower)) ||
                              (post.postType && post.postType.toLowerCase().includes(searchLower))
                            );
                          })}
                          columnDefs={columnDefs}
                          gridOptions={gridOptions}
                          defaultColDef={{
                            sortable: true,
                            resizable: true,
                            suppressSizeToFit: true,
                            wrapText: false,
                            autoHeight: false
                          }}
                          enableCellTextSelection={true}
                          ensureDomOrder={true}
                          suppressColumnVirtualisation={true}
                          animateRows={false}
                          rowHeight={60}
                        //   rowsPerPage={100}
                        //   pagination={true}
                        //   paginationPageSize={100}
                          suppressPaginationPanel={false}
                          paginationAutoPageSize={false}
                          suppressScrollOnNewData={true}
                          suppressAnimationFrame={true}
                          suppressColumnMoveAnimation={true}
                          suppressLoadingOverlay={true}
                          suppressNoRowsOverlay={true}
                          getRowHeight={() => 60}
                        />
                      </div>
                    </>
                  )}
                </Box>
              </Card>
            </Box>
          )}

          {selectedTab === 3 && (
            <Box sx={{
              maxWidth: 1200,
              width: "100%",
              margin: "0 auto",
              p: 4,
            }}>
              <Box sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mb: 3,
              }}>
                <Box sx={{ 
                  display: "flex", 
                  alignItems: "center",
                  flex: 1,
                  maxWidth: 300,
                  background: "#fff",
                  borderRadius: 2,
                  border: "1px solid #eee",
                  p: 1,
                }}>
                  <Search sx={{ color: "text.secondary", mr: 1 }} />
                  <TextField
                    size="small"
                    placeholder="Search campaigns..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </Box>
              </Box>

              <Card elevation={0} sx={{ borderRadius: 3, border: '1px solid #eee' }}>
                <Box sx={{ p: 3 }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6">
                      All Campaigns {!campaignsLoading && `(${formatNumber(campaigns.length)} total)`}
                    </Typography>
                    <Button
                      startIcon={<Refresh />}
                      onClick={() => handleRefresh()}
                      disabled={campaignsLoading}
                    >
                      Refresh
                    </Button>
                  </Box>
                  
                  {campaignsLoading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" py={8}>
                      <CircularProgress />
                    </Box>
                  ) : campaignsError ? (
                    <Box 
                      display="flex" 
                      flexDirection="column" 
                      alignItems="center" 
                      justifyContent="center" 
                      py={8}
                      gap={2}
                    >
                      <Typography color="error" variant="body1">
                        {campaignsError}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => handleRefresh()}
                        startIcon={<Refresh />}
                      >
                        Try Again
                      </Button>
                    </Box>
                  ) : campaigns.length === 0 ? (
                    <Box 
                      display="flex" 
                      flexDirection="column" 
                      alignItems="center" 
                      justifyContent="center" 
                      py={8}
                      gap={2}
                    >
                      <ListAlt sx={{ fontSize: 48, color: 'text.secondary' }} />
                      <Typography variant="h6" color="text.secondary">
                        No campaigns found
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        There are currently no campaigns in the system
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <TableContainer>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Campaign Name</TableCell>
                              <TableCell>Team</TableCell>
                              <TableCell>Created</TableCell>
                              <TableCell>Posts</TableCell>
                              <TableCell align="right">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {campaigns.map((campaign) => (
                              <TableRow key={campaign.id}>
                                <TableCell>
                                  <Typography variant="body1" fontWeight={500}>
                                    {campaign.name}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                    {campaign.status}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <Typography variant="body1">
                                    {campaign.teamId}
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  {formatFullDate(campaign.createdAt)}
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {formatNumber(campaign.groupCount || 0)} groups
                                  </Typography>
                                </TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="contained"
                                    size="small"
                                    endIcon={<OpenInNew />}
                                    onClick={() => navigate(`/team/${campaign.teamId}`)}
                                    sx={{ borderRadius: 2 }}
                                  >
                                    View Team
                                  </Button>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        <TablePagination
                          component="div"
                          count={campaigns.length}
                          page={page - 1}
                          onPageChange={(event, newPage) => setPage(newPage + 1)}
                          rowsPerPage={limit}
                          onRowsPerPageChange={(event) => {
                            setLimit(parseInt(event.target.value, 10));
                            setPage(1);
                          }}
                          rowsPerPageOptions={[10, 25, 50, 100]}
                        />
                      </Box>
                    </>
                  )}
                </Box>
              </Card>
            </Box>
          )}
        </Box>

        <Footer />

        {updatePlanTeam && (
          <UpdatePlanModal
            open={!!updatePlanTeam}
            onClose={() => setUpdatePlanTeam(null)}
            team={updatePlanTeam}
            onSuccess={handlePlanUpdated}
          />
        )}

        <PostModal open={isModalOpen} onClose={() => setIsModalOpen(false)} postId={selectedPostId} teamId={teamId} />

        <CreateTeamModal
          open={createTeamOpen}
          onClose={() => setCreateTeamOpen(false)}
          onSuccess={() => {
            setCreateTeamOpen(false);
            handleRefresh();
          }}
        />

        {renameTeam && (
          <RenameTeamModal
            open={!!renameTeam}
            onClose={() => setRenameTeam(null)}
            team={renameTeam}
            onSuccess={handleRefresh}  // Use handleRefresh instead of loadInitialData directly
          />
        )}
      </div>
    </ThemeProvider>
  );
} 