import {
    Box,
    Grid,
    Typography,
    Slider,
    Card,
    createTheme,
    ThemeProvider,
    ButtonBase,
    AppBar,
    Divider,
    Skeleton,
    Tabs,
    Tab,
    CardContent,
    AvatarGroup,
    Avatar,
    Button,
    Popper,
    ClickAwayListener,
    Paper,
    MenuItem,
    IconButton,
    MenuList,
    FormControl,
    Select,
    Chip,
    LinearProgress,
    CircularProgress,
    Tooltip,
    Badge,
    TextField,
    SpeedDialIcon,
    InputAdornment,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Popover,
    Snackbar,
    Alert,
    Breadcrumbs,
    DialogContentText,
    Modal,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Switch,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,

  } from "@mui/material";
  import { emphasize } from '@mui/material/styles';
  import { styled as muiStyled } from "@mui/material/styles";
  import React, { useState } from "react";
  import {
    Add,
    Analytics,
    ArrowDownwardOutlined,
    ArrowDropDownOutlined,
    ArrowDropUpOutlined,
    ArrowLeftOutlined,
    ArrowRightOutlined,
    AutoAwesome,
    BarChart,
    BarChartOutlined,
    Chat,
    CheckBoxOutlined,
    Close,
    CloseOutlined,
    Comment,
    CommentOutlined,
    ContentCopyOutlined,
    CopyAllOutlined,
    DescriptionOutlined,
    DoneAllOutlined,
    DoneAllRounded,
    DragHandle,
    DragHandleOutlined,
    ExpandLess,
    ExpandMore,
    FolderOutlined,
    Group,
    GroupOutlined,
    GroupsOutlined,
    GroupsRounded,
    NextPlan,
    OpenInNew,
    ScoreOutlined,
    Settings,
    SpeedOutlined,
    TableView,
    UploadFileOutlined,
    VideoFileOutlined,
    MoreVert,
    ChevronLeft,
  } from "@mui/icons-material";
  import { siftsyTheme } from "./siftsytheme";
  import { useParams, useNavigate } from "react-router-dom";
  import HelperFunctions from "./HelperFunctions";
import GroupView from "./GroupView";
import { formatData, formatFullDate, formatNumber, formatPlatform, UTCtoLocal } from './Helpers'; 
// import dayjs, { Dayjs } from 'dayjs';
// import { format } from "url";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useUser } from "@clerk/clerk-react";
import { loadStripe } from '@stripe/stripe-js';
import StripePlanPricing from "./StripePlanPricing";
import { maxWidth } from "@mui/system";
import { ArrowTopRightOnSquareIcon, ChevronLeftIcon, ChevronRightIcon, XCircleIcon } from "@heroicons/react/24/outline";

const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
const ENDPOINT = process.env.REACT_APP_ENDPOINT;

export default function TeamSettingsModal({ open, isMobile, handleClose, type, name, teamData, selectedAssets, selectedGroups, setSelectedGroups, softRefresh, viewPlans}) {
    const Functions = HelperFunctions();

    const [teamName, setTeamName] = useState(teamData ? teamData.name : "");
    const [groupName, setGroupName] = useState(teamData && teamData.groups && teamData.groups.length > 0 ? teamData.groups[0].name : "");
    const [groupType, setGroupType] = useState(teamData && teamData.groups ? teamData.groups.length > 0 ? teamData.groups[0].type : "campaign" : "campaign");
    const [links, setLinks] = useState([]);
    const [invitees, setInvitees] = useState([]);
    const [separator, setSeparator] = useState("\n");
    const [linksValid, setLinksValid] = useState(true);
    const [newGroupName, setNewGroupName] = useState("");
    const [teammembers, setTeammembers] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    React.useEffect(() => {
      if(viewPlans){
        setSelectedTab(3);
      }
    }, [viewPlans]);
    const [usageData, setUsageData] = useState({
      totalComments: 0,
      plan: {
        type: "",
        comments: 0,
        postsUploaded: 0,
        postsCompleted: 0,
      }
    });
    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()));
    const [endDate, setEndDate] = useState(new Date());
    const { user } = useUser();
    const [verifyingRemoval, setVerifyingRemoval] = useState(null);

    const navigate = useNavigate();

    const handleOpenStripePortal = () => {
      //open in new tab
      window.open(`https://billing.stripe.com/p/login/6oE16iauBg0jfwQ144?teamIds=${teamData.id}`, '_blank');
    };
  

    React.useEffect(() => {
      async function fetchData() {
        let data = await Functions.getTeammateData(teamData.id);
        setTeammembers(data);

        let planStart = teamData.plan && teamData.plan.startDate ? new Date(teamData.plan.startDate) :  new Date(new Date().getFullYear(), new Date().getMonth(), 1);

        //let start be the date of this month that the plan started
        let start = new Date(new Date().getFullYear(), 
        
        planStart.getDate() > new Date().getDate() ? new Date().getMonth() - 1 : new Date().getMonth(), 
        
        planStart.getDate()).toISOString();
        
        //let end be today
        let end = new Date( new Date().getFullYear(),  planStart.getDate() > new Date().getDate() ? new Date().getMonth() : new Date().getMonth() + 1 , planStart.getDate() - 1).toISOString();

        setStartDate(start);
        setEndDate(end);

      }
      if(open){
      fetchData();
      }
    }, [open]);

    React.useEffect(() => {
      async function fetchData() {
        let data = await Functions.getTeamUsageData(teamData.id, startDate, endDate);
        setUsageData(data);
      }
      fetchData();
    },
    [startDate, endDate]);

    const goToPreviousPeriod = () => {
      const start = new Date(startDate);
      setEndDate(new Date(start.getFullYear(), start.getMonth(), start.getDate() - 1));
      setStartDate(new Date(start.getFullYear(), start.getMonth() - 1, start.getDate()));
    }

    const goToNextPeriod = () => {
      const end = new Date(endDate);
      setStartDate(new Date(end.getFullYear(), end.getMonth(), end.getDate() + 1));
      setEndDate(new Date(end.getFullYear(), end.getMonth() + 1, end.getDate()));
    }
  
  
    const updateInvitees = (value) => {
      let list = value.split(",");
      setInvitees(list);
    }
  
    const updateLinks = (value) => {
      //find separator
      let newSeparator = value.includes("\n") ? "\n" : ",";
      setSeparator(newSeparator);
  
      let list = value.split(newSeparator);
      //remove url params for link in list
    if (list.length > 0){
            list = list.map((link, index) => {
                //check if link is a valid url
            let isUrl = isValidSocialMediaLink(link.trim());
            console.log("isUrl", isUrl, link);
            let url = isUrl ? new URL(link) : "";
            console.log("url", url);
            if (isUrl && link.includes("watch?v=")) {
                const regExp = /^.*(youtu.be\/|v\/|e\/|u\/\w+\/|embed\/|v=)([^#\&\?]*).*/;
                const match = link.trim().match(regExp);
                if (match && match[2].length === 11) {
                return `https://www.youtube.com/watch?v=${match[2]}`;
                }
              }
            else{
            return isUrl ? url.origin + url.pathname : link.trim();
            }
            });
        }

      setLinks(list);
  
      let valid = list.every((link) => isValidSocialMediaLink(link));
      setLinksValid(valid);
    }
    
    const isValidEmail = (email) => {
      // Check for basic email format and reject emails containing '+'
      return /^[^\s@+]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const [addEmail, setAddEmail] = useState("");
  
    const handleRemoveTeammate = async (memberId, memberEmail) => {
      if (memberEmail === user.primaryEmailAddress.emailAddress) {
        // Don't allow removing your own email
        return;
      }
      await Functions.removeMemberFromTeam(teamData.id, memberId);
      setVerifyingRemoval(null);
      let data = await Functions.getTeammateData(teamData.id);
      setTeammembers(data);
    };
  
  
    const handleAddTeammate = async () => {
      if (addEmail && teammembers.length < teamData.plan.teamMembers) {
        // Implement the logic to invite a new teammate
        // This is a placeholder function - you'll need to implement the actual API call
        await Functions.addMemberToTeam(teamData.id, addEmail);
        setAddEmail("");
        let data = await Functions.getTeammateData(teamData.id);
        setTeammembers(data);
      }
    };
  
    const modalStyle = {
      borderRadius: 8, // Adjust the border radius as needed
      backgroundColor: 'white',
      // padding: '20px',
      outline: 'none', // Remove the default outline
      // position:"fixed",
      // left: "50%",
      // bottom:0,
      width:"90%",
      height:"80%",
      maxWidth:1200,
      maxHeight: '80%',
      overflowY: 'auto',
      overflowX: 'hidden',
      position: "fixed", // Position fixed to the viewport
      left: "50%", // Center horizontally
      top: "50%", // Center vertically
      transform: "translate(-50%, -50%)", 
      display: "flex",
      flexDirection: "column",
      background:"#f7f8fa"
  
      // transform: "translate(-50%, 0)",
    };
  
    return (
      <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...modalStyle,
            // gap: ".5rem",
            display:"flex",
            // flexDirection:"column",
            // justifyContent:"center",   
            "&::-webkit-scrollbar": {
              width: "20px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              borderRadius: 20,
              marginTop: 5,
              marginBottom: 5,

            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ddd",
              border: "5px solid #f7f8fa",
              borderRadius: 20,
            },       
          }}
        >

          <Box sx={{width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", pl:4, pr:4, pt:4}}>
          <Typography
                      variant="body1"
                      sx={{
                        color: "#4728c4",
                        fontWeight: 700,
                        textTransform: "none",
                        fontSize: isMobile ? 20 : 25,
                      }}
                    >
                      Team Settings
                    </Typography>
            <IconButton onClick={handleClose}>
              <XCircleIcon style={{height:24, width:24, color:"#333"}}/>
            </IconButton>
          </Box>

          <Box>

            <Box sx={{display:"flex", flexDirection:"row", gap:2, pr:4, pl:4, pt:2, pb:2, flexWrap:"wrap"}}>
            <ButtonBase onClick={() => setSelectedTab(0)} sx={{backgroundColor:"white !important", borderRadius:4, padding:"10px 30px", border:`1px solid ${selectedTab === 0 ? "#4728c4" : "#ddd"}`}}>
              <Typography variant="body2" fontWeight={700} color={selectedTab === 0 ? "primary" : "textSecondary"}>
                Overview
              </Typography>
            </ButtonBase>
            <ButtonBase onClick={() => setSelectedTab(1)} sx={{background:"white !important", borderRadius:4, padding:"10px 30px", border:`1px solid ${selectedTab === 1 ? "#4728c4" : "#ddd"}`}}>
              <Typography variant="body2" fontWeight={700} color={selectedTab === 1 ? "primary" : "textSecondary"}>
                Team Members {teammembers ? `(${teammembers.length})` : ""}
              </Typography>
            </ButtonBase>
            <ButtonBase onClick={() => setSelectedTab(2)} sx={{background:"white !important", borderRadius:4, padding:"10px 30px", border:`1px solid ${selectedTab === 2 ? "#4728c4" : "#ddd"}`}}>
              <Typography variant="body2" fontWeight={700} color={selectedTab === 2 ? "primary" : "textSecondary"}>
                Usage & Billing
              </Typography>
            </ButtonBase>
            <ButtonBase onClick={() => setSelectedTab(3)} sx={{background:"white !important", borderRadius:4, padding:"10px 30px", border:`1px solid ${selectedTab === 3 ? "#4728c4" : "#ddd"}`}}>
              <Typography variant="body2" fontWeight={700} color={selectedTab === 3 ? "primary" : "textSecondary"}>
                Plans
              </Typography>
            </ButtonBase>
            
              

            
            </Box>

            <Box sx={{display:"flex", flexDirection:"column", gap:2, p:4}}>



          {selectedTab === 0 && (

            <TeamOverview teamData={teamData} teammembers={teammembers} isMobile={isMobile} user={user} />


          )}

          {selectedTab === 1 && (
            <>

            <TeamComponent teamData={teamData} teammembers={teammembers} setVerifyingRemoval={setVerifyingRemoval} verifyingRemoval={verifyingRemoval} handleRemoveTeammate={handleRemoveTeammate} handleAddTeammate={handleAddTeammate} addEmail={addEmail} setAddEmail={setAddEmail} isMobile={isMobile} user={user} isValidEmail={isValidEmail} />

            </>
          )}

          {selectedTab === 2 && (
            <>
            <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-stretch",
              flexGrow: 1,
            }}
          >  
          <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-stretch",
                  flexGrow: 1,
                  gap:2,
                  width:"100%"
                  
                }}
              >
                    <TableView sx={{height:20, width:20, fill:"#999"}}/>
  
                    <Typography variant="body1" fontWeight={500}>
                    Plan
                </Typography>
                  <Box sx={{flexGrow:1}}/>
                {teamData.plan && !teamData.plan.name.toLowerCase().includes("demo") && !teamData.plan.name.toLowerCase().includes("trial") &&
            (
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenStripePortal}
                sx={{borderRadius: 30}}
              >
                      Manage
              </Button>
            </Box>
            )}
  
        </Box>
        <Box sx={{display:"flex", flexDirection:"row", gap:2, alignItems:"center"}}>
                 <Box sx={{width:20}}/> 

            <Typography variant="body2" fontWeight={600} sx={{ mb: 1 }}>
              {teamData.plan && teamData.plan.term ? teamData.plan.term.toUpperCase() + " - " : ""
              } {teamData.plan && teamData.plan.name ? teamData.plan.name : "Trial"}
            </Typography>
            </Box>

            <Box sx={{ p: 3, backgroundColor: '#f9f9f9' }}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Plan Details
              </Typography>

              <Grid container spacing={3}>
                {/* Team Members */}
                <Grid item xs={4} lg={2.25}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: 2,
                    backgroundColor: '#fff',
                    border: '1px solid #eee',
                    borderRadius: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                      transform: 'translateY(-2px)'
                    }
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <GroupOutlined sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="subtitle1" fontWeight={600}>
                        Team Members
                      </Typography>
                    </Box>
                    <Typography variant="h4" fontWeight={700} sx={{ mb: 1 }}>
                      {teamData.plan?.teamMembers || 5}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                      Max. team members
                    </Typography>
                  </Box>
                </Grid>

                {/* MaaS Accounts */}
                <Grid item xs={4} lg={2.25}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: 2,
                    backgroundColor: '#fff',
                    border: '1px solid #eee',
                    borderRadius: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                      transform: 'translateY(-2px)'
                    }
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <BarChartOutlined sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="subtitle1" fontWeight={600}>
                        MAAs
                      </Typography>
                    </Box>
                    <Typography variant="h4" fontWeight={700} sx={{ mb: 1 }}>
                      {teamData.plan?.allowedMaas || 10}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                      Max. accounts analyzed per mo.
                    </Typography>
                  </Box>
                </Grid>

                {/* Post Credits - Only show for Demo/Trial */}
                {(teamData.plan?.name === 'Trial' || teamData.plan?.name === 'Demo') && (
                <Grid item xs={4} lg={2.25}>
                    <Box sx={{ 
                      display: 'flex', 
                      flexDirection: 'column',
                      p: 2,
                      backgroundColor: '#fff',
                      border: '1px solid #eee',
                      borderRadius: 2,
                      transition: 'all 0.2s ease',
                      '&:hover': {
                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                        transform: 'translateY(-2px)'
                      }
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <DescriptionOutlined sx={{ mr: 1, color: 'primary.main' }} />
                        <Typography variant="subtitle1" fontWeight={600}>
                          Post Credits
                        </Typography>
                      </Box>
                      <Typography variant="h4" fontWeight={700} sx={{ mb: 1 }}>
                        {teamData.plan?.postCredits || 50}
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                        Available post credits
                      </Typography>
                    </Box>
                  </Grid>
                )}

                {/* Comment Limit */}
                <Grid item xs={4} lg={2.25}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: 2,
                    backgroundColor: '#fff',
                    border: '1px solid #eee',
                    borderRadius: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                      transform: 'translateY(-2px)'
                    }
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <CommentOutlined sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="subtitle1" fontWeight={600}>
                        Comment Limit
                      </Typography>
                    </Box>
                    <Typography variant="h4" fontWeight={700} sx={{ mb: 1 }}>
                      {teamData.plan?.commentLimit || 250}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                      Comments analyzed per post
                    </Typography>
                  </Box>
                </Grid>

                {/* Lookback Period */}
                <Grid item xs={4} lg={2.25}>
                  <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    p: 2,
                    backgroundColor: '#fff',
                    border: '1px solid #eee',
                    borderRadius: 2,
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                      transform: 'translateY(-2px)'
                    }
                  }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <BarChartOutlined sx={{ mr: 1, color: 'primary.main' }} />
                      <Typography variant="subtitle1" fontWeight={600}>
                        Historical Data
                      </Typography>
                    </Box>
                    <Typography variant="h4" fontWeight={700} sx={{ mb: 1 }}>
                      {teamData.plan?.lookback || 3} mo
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{height:50}}>
                      Max. analyzed post age
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            </Box>
            <UsageComponent teamData={teamData} usageData={usageData} startDate={startDate} endDate={endDate} isMobile={isMobile} goToPreviousPeriod={goToPreviousPeriod} goToNextPeriod={goToNextPeriod} handleOpenStripePortal={handleOpenStripePortal}/>
  

          
</>
          )}  
            
    

            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={dayjs(startDate)}
                    onChange={(newValue) => setStartDate(newValue)}
                  />
                    <DatePicker
                    label="End Date"
                    value={dayjs(endDate)}
                    onChange={(newValue) => setEndDate(newValue)}
                  />
              </LocalizationProvider> */}

              {/* {viewPlans &&  
              <Box>
                <StripePlanPricing teamData={teamData} user={user} />
                <Divider />
                </Box>
              } */}



    {selectedTab === 3 && (
      <>
      <Box sx={{display:"flex", flexDirection:"row"}}>
        <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:2, display:{lg:"flex", md:"none", sm:"none", xs:"none"}}}>
      {/* <IconButton onClick={() => {
    const scrollContainer = document.querySelector('.scrollable-content');
    console.log('Scroll Container:', scrollContainer); // Check if the container is found
    if (scrollContainer) {
        // Check the current scroll position
        const currentScroll = scrollContainer.scrollLeft;
        if (currentScroll > 0) {
            // Scroll back to the left
            scrollContainer.scrollBy({ left: -300, behavior: 'smooth' });
        }

    } else {
        console.error('Scroll container not found'); // Log an error if not found
    }
}}>
  <ChevronLeftIcon style ={{height:24, width:24, color:"#333"}}/>
</IconButton>  */}
</Box>
    {/* {(!teamData.plan || teamData.plan.name === "Demo" || teamData.plan.name === "Trial") ? ( */}
    {/* <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 2, p: 2, overflowX:"scroll"
      ,
      "&::-webkit-scrollbar": {
        width: 0, // Hide scrollbar
    },
     }} className="scrollable-content"> */}
        {/* ... existing code ... */}
        <Box sx={{ display: "flex",  whiteSpace: "nowrap", width: {lg:"100%", md:"100%", sm:"100%", xs:"100%"}, borderRadius: 4 }} >
    <StripePlanPricing teamData={teamData} user={user} />
    
{/* </Box> */}

</Box>
<Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:2, display:{lg:"flex", md:"none", sm:"none", xs:"none"}}}>

{/* <IconButton onClick={() => {
    const scrollContainer = document.querySelector('.scrollable-content');
    console.log('Scroll Container:', scrollContainer); // Check if the container is found
    if (scrollContainer) {
        // Check the current scroll position
        const currentScroll = scrollContainer.scrollLeft;
        if (currentScroll == 0) {
            // Scroll back to the left
            // Scroll to the right
            scrollContainer.scrollBy({ left: 300, behavior: 'smooth' });
        }
    } else {
        console.error('Scroll container not found'); // Log an error if not found
    }
}}>
  <ChevronRightIcon style={{height:24, width:24, color:"#333"}}/>
</IconButton>  */}
</Box>
        
        {/* <Typography variant="body1" align="center">
          You are currently on a {teamData.plan ? teamData.plan.name : "free"} plan. 
          Upgrade to access more features and increase your usage limits.
        </Typography> */}
    
      </Box>
 
      <Box sx={{display:{lg:"flex", md:"none", sm:"none", xs:"none"}}}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 2, p: 2 }}>
        <Box sx={{flexGrow:1}}/>

</Box>
      </Box>

    </>
  )}

    

</Box>
         
          
        </Box>
        
         
        </Box>


    
      </Modal>


</>
    );
  }


  function isValidSocialMediaLink(link) {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[\w-]{11}/;
    const youtubeShortRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/shorts\/|youtu\.be\/)[\w-]{11}/;
    const instagramPostRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/p\/[\w-]+/;
    const instagramReelRegex = /^(https?:\/\/)?(www\.)?instagram\.com\/reel\/[\w-]+/;
    const tiktokRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/@[\w.-]+\/video\/[\w-]+/;
    // Handle short tiktok urls like so: https://www.tiktok.com/t/ZT8h3Nsh9/
    const tiktokShortRegex = /^(https?:\/\/)?(www\.)?tiktok\.com\/t\/[\w.-]+/;
  
    return (
      youtubeRegex.test(link) ||
      youtubeShortRegex.test(link) ||
      instagramPostRegex.test(link) ||
      instagramReelRegex.test(link) ||
      tiktokRegex.test(link) ||
      tiktokShortRegex.test(link)
    );
  }




const ControlledIconButton = ({ action, icon }) => {

  const [clicked, setClicked] = useState(false);


  const handleClick = () => {
    setClicked(true);
    action();
    setTimeout(()=>{
      setClicked(false);
    }, 1000)
  };

  return (
<IconButton size="small" disabled={clicked} variant="outlined" color="primary" onClick={handleClick}>
              {icon}
              </IconButton>
  );
}


const StripePricingTable = ({ pricingTableId, publishableKey, clientReferenceId, customerEmail, customerMetadata }) => {
  const [checkoutSessionId, setCheckoutSessionId] = useState(null);

  React.useEffect(() => {
    // Load the Stripe.js script
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    // Create Checkout Session
    const createCheckoutSession = async () => {
      try {
        const response = await fetch(`${ENDPOINT}/create-checkout-session`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            clientReferenceId,
            customerEmail,
            customerMetadata
          }),
        });
        const data = await response.json();
        setCheckoutSessionId(data.sessionId);
      } catch (error) {
        console.error('Error creating Checkout Session:', error);
      }
    };

    createCheckoutSession();

    return () => {
      document.body.removeChild(script);
    };
  }, [clientReferenceId, customerEmail, customerMetadata]);

  if (!checkoutSessionId) {
    return <div>Loading pricing table...</div>;
  }

  return (
    <stripe-pricing-table
      pricing-table-id={pricingTableId}
      publishable-key={publishableKey}
      client-reference-id={clientReferenceId}
      customer-email={customerEmail}
      checkout-session-id={checkoutSessionId}
    />
  );
};


function UsageComponent(props) {

  const { teamData, usageData, startDate, endDate, isMobile, goToPreviousPeriod, goToNextPeriod, handleOpenStripePortal } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 3,
        padding: 2,
        backgroundColor: '#f9f9f9'
      }}
    >
      {/* Usage Summary Section */}
      <Box
        sx={{
          padding: 2,
          backgroundColor: '#ffffff',
          borderRadius: 2,
          boxShadow: 1,
        }}
      >
        <Typography variant="h6" color="text.primary" sx={{ marginBottom: 2 }}>
          Usage Summary
        </Typography>
        <Paper
          elevation={0}
          sx={{
            borderRadius: 8,
            border: '1px solid #ddd', 
            padding: 2,
            backgroundColor: '#fff',
            borderRadius: 1,
            minHeight: 150,
          }}
        >
                      {teamData.plan ? 
<Box sx={{
  display: "flex", 
  flexDirection: isMobile ? "column" : "row",
  justifyContent: "space-between",
  alignItems: isMobile ? "flex-start" : "center",
  gap: 2, 
  p: 2
}}>
  {teamData.plan && teamData.plan.startDate ? 
    <Typography variant="body2" fontWeight={600}>
      Plan Start: {formatFullDate(teamData.plan.startDate)}
    </Typography> 
  : null}

  <Box sx={{ 
    display: "flex", 
    flexDirection: "row", 
    alignItems: "center", 
    justifyContent: "flex-end",
    gap: 1
  }}>
    <Tooltip title="Go to last period">
      <ControlledIconButton action={goToPreviousPeriod} icon={<ArrowLeftOutlined/>} />
    </Tooltip>
    <Typography variant="body2" fontWeight={600}>
      {`${formatFullDate(startDate)} - ${formatFullDate(endDate)}`}
    </Typography>
    <Tooltip title="Go to next period">
      <ControlledIconButton action={goToNextPeriod} icon={<ArrowRightOutlined/>} />
    </Tooltip>
  </Box>
</Box>
: null}

{teamData.plan && usageData ? 

<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap:2, p:2}}>
<Typography variant="body1" fontWeight={600} ml={2} mr={2}>
  Accounts
</Typography>

<Grid container spacing={2}>
<Grid item xs={6} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          {/* <UploadFileOutlined
           sx={{ height: 16, width:16, color: "#333" }} /> */}

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            MAAs (This Month)
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
            
  {formatNumber(usageData.MAAs ? usageData.MAAs.reduce((sum, month) => sum + month.activeAccounts.length, 0) : 0)}
            {teamData && teamData.plan && teamData.plan.allowedMAAs ? "/" + teamData.plan.allowedMAAs : ""}
          </Typography>
    
        </Box>
        <Typography variant="caption">
            Accounts
          </Typography>
      </Box>
    </Grid>
    <Grid item xs={6} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          {/* <UploadFileOutlined
           sx={{ height: 16, width:16, color: "#333" }} /> */}

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            Total Analyzed Accounts
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
            {formatNumber(usageData.totalAccounts ? usageData.totalAccounts : 0)}
          </Typography>
    
        </Box>
        <Typography variant="caption">
            Accounts
          </Typography>
      </Box>
    </Grid>
  </Grid>

</Box>
 : null}

{teamData.plan && usageData ? 

<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap:2, p:2}}>
<Typography variant="body1" fontWeight={600} ml={2} mr={2}>
  Posts
</Typography>

<Grid container spacing={2}>
    <Grid item xs={6} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          <UploadFileOutlined
           sx={{ height: 16, width:16, color: "#333" }} />

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            Uploaded
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
            {formatNumber(usageData.postsCompleted)} {teamData.plan && teamData.plan.postCredits ? `/ ${teamData.plan.postCredits}` : ""}
          </Typography>
    
        </Box>
        <Typography variant="caption">
            Posts
          </Typography>
      </Box>
    </Grid>
    <Grid item xs={6} sm={4}>
      {teamData.plan && teamData.plan.postCredits && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <CheckBoxOutlined
                           sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Remaining
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                          color={((teamData.plan.postCredits - usageData.postsCompleted) > (teamData.plan.postCredits * .5)) ? "green" : ((teamData.plan.postCredits - usageData.postsCompleted) > (teamData.plan.postCredits * .75)) ? "#fec76f" : "error"}
                        >
                         {formatNumber(teamData.plan.postCredits - usageData.postsCompleted)}
                        </Typography>
                        <Typography fontSize={20}>
                          /
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                         {formatNumber(teamData.plan.postCredits)}
                        </Typography>
                      
                      </Box>
                      <Typography variant="caption">
                          posts
                        </Typography>
                    </Box>
                  )}
                  </Grid>
    <Grid item xs={12} sm={4}>
    {teamData.plan && teamData.plan.postCredits && (

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <SpeedOutlined sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Overage
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                          +{usageData.postsCompleted > teamData.plan.postCredits ? formatNumber(usageData.postsCompleted - teamData.plan.postCredits) : 0}
                     
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                          posts
                        </Typography>
                    </Box>
                  )}
                  </Grid>
    {/* <Grid item xs={6} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          <CheckBoxOutlined
             sx={{ height: 16, width:16, color: "#333" }} />

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            Remaining
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
            color="green"
          >
           {formatNumber(teamData.plan.credits - usageData.totalComments)}
          </Typography>
          <Typography fontSize={20}>
            /
          </Typography>
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
           {formatNumber(teamData.plan.credits)}
          </Typography>
        
        </Box>
        <Typography variant="caption">
            comments
          </Typography>
      </Box>
    </Grid>
    <Grid item xs={12} sm={4}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          flexGrow: 1,
          border: "1px solid #ddd",
          p: 2,
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            gap: .5,
          }}
        >
          <SpeedOutlined sx={{ height: 16, width:16, color: "#333" }} />

          <Typography
            variant="body1"
            fontSize={12}
            fontWeight={700}
            align="left"
            textTransform={"uppercase"}
          >
            Overage
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: 1,
          }}
        >
          <Typography
            variant="body1"
            fontWeight={700}
            fontSize={22}
          >
            +{usageData.totalComments > teamData.plan.credits ? formatNumber(usageData.totalComments - teamData.plan.credits) : 0}
       
          </Typography>
        </Box>
        <Typography variant="caption">
            comments
          </Typography>
      </Box>
    </Grid> */}
  </Grid>

</Box>
 : null}



              {teamData.plan ? 

              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap:2, p:2}}>
              <Typography variant="body1" fontWeight={600} ml={2} mr={2}>
                Comments
              </Typography>

 <Grid container spacing={2}>
                  <Grid item xs={6} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <CloseOutlined
                         sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Used
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                          color={
                            
                            teamData.plan.credits ? ((usageData.totalComments) < (teamData.plan.credits * .5)) ? "green" : ((usageData.totalComments) < (teamData.plan.credits * .75)) ? "#fec76f" : "error" : "#333"}

                        >
                          {formatNumber(usageData.totalComments)}
                          </Typography>
                          {teamData.plan.credits ? <>
                        <Typography fontSize={20}>
                          /
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                         {formatNumber(teamData.plan.credits)}
                        </Typography>
                        </> : null}
                      </Box>
                      
                      <Typography variant="caption">
                          comments
                        </Typography>
                    </Box>
                  </Grid>
                  {teamData.plan.credits ?
                  <Grid item xs={6} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                    
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <CheckBoxOutlined
                           sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Remaining
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                          color={((teamData.plan.credits - usageData.totalComments) > (teamData.plan.credits * .5)) ? "green" : ((teamData.plan.credits - usageData.totalComments) > (teamData.plan.credits * .75)) ? "#fec76f" : "error"}
                        >
                         {formatNumber(teamData.plan.credits - usageData.totalComments)}
                        </Typography>
                        <Typography fontSize={20}>
                          /
                        </Typography>
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                         {formatNumber(teamData.plan.credits)}
                        </Typography>
                      
                      </Box>
                      <Typography variant="caption">
                          comments
                        </Typography>
                    </Box>
                 
                  </Grid>
                  : null}

                  {teamData.plan.credits ?

                  <Grid item xs={12} sm={4}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexGrow: 1,
                        border: "1px solid #ddd",
                        p: 2,
                        borderRadius: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          width: "100%",
                          gap: .5,
                        }}
                      >
                        <SpeedOutlined sx={{ height: 16, width:16, color: "#333" }} />

                        <Typography
                          variant="body1"
                          fontSize={12}
                          fontWeight={700}
                          align="left"
                          textTransform={"uppercase"}
                        >
                          Overage
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: 1,
                        }}
                      >
                        <Typography
                          variant="body1"
                          fontWeight={700}
                          fontSize={22}
                        >
                          +{usageData.totalComments > teamData.plan.credits ? formatNumber(usageData.totalComments - teamData.plan.credits) : 0}
                     
                        </Typography>
                      </Box>
                      <Typography variant="caption">
                          comments
                        </Typography>
                    </Box>
                  </Grid>
                    : null}
                </Grid>

              </Box>
               : null}


        </Paper>
      </Box>

      {/* Billing Summary Section */}
      <Box
        sx={{
          padding: 2,
          backgroundColor: '#ffffff',
          borderRadius: 2,
          boxShadow: 1,
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <Typography variant="h6" color="text.primary" sx={{ marginBottom: 2 }}>
          Billing
        </Typography>
        <Box sx={{flexGrow:1}}/>

        <Button
        endIcon={<ArrowTopRightOnSquareIcon style={{height:20, width:20, marginBottom:5}} />}
                variant="outlined"
                color="primary"
                onClick={handleOpenStripePortal}
                sx={{borderRadius: 2, pt:.8}}
              >
                      Go To Billing
              </Button>

        {/* <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              padding: 3,
              backgroundColor: '#f3f3f3',
              borderRadius: 1,
              minHeight: 100,
              flex: 1,
            }}
          />
          <Paper
            elevation={0}
            sx={{
              padding: 3,
              backgroundColor: '#f3f3f3',
              borderRadius: 1,
              minHeight: 100,
              flex: 1,
            }}
          />
        </Box> */}
      </Box>
    </Box>
  );
}


function TeamComponent(props) {
  const { teamData, teammembers = [], setVerifyingRemoval, verifyingRemoval, handleRemoveTeammate, handleAddTeammate, addEmail, setAddEmail, isMobile, user, isValidEmail } = props;
  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: '#f9f9f9',
        borderRadius: 2,
        boxShadow: 1,
      }}
    >
      {/* Header */}
      <Typography variant="h6" sx={{ marginBottom: 2 }}>
        Team
      </Typography>

      {/* Top Controls */}


      <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-stretch",
                  flexGrow: 1,
                  pr: 2,
                  pt:2,
                  gap: 2
                }}
              > 
  
                <Group sx={{height:20, width:20, fill:"#999"}}/>
                <Typography variant="body1" fontWeight={500}>
                    Teammates ({teammembers ? teammembers.length : 0})
                </Typography>
              </Box>
         
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", gap: 2 }}>
              {teammembers && teammembers.map((member, index) => (
                <Box key={index} sx={{
                  display: "flex", 
                  flexDirection: isMobile ? "column" : "row", 
                  gap: isMobile ? 1 : 2, 
                  alignItems: isMobile ? "flex-start" : "center", 
                  justifyContent: "space-between", 
                  mt: 2
                }}>
                  <Box sx={{display:"flex", flexDirection:"column"}}>
                    <Typography variant="body1" fontWeight={600} fontSize={14}>
                      {member.name && !(member.name == "New User" || member.name.trim() == "") ? member.name : ""}
                    </Typography>
                    <Typography variant="body1" fontWeight={600} fontSize={18}>
                      {member.email}
                    </Typography>
                  </Box>
                  {member.email !== user.primaryEmailAddress.emailAddress && (
          <>
          <Box sx={{flexGrow:1}}/>
            {verifyingRemoval === member.id ? (
              <>
                <Button 
                  variant="contained" 
                  color="error" 
                  size="small"
                  onClick={() => handleRemoveTeammate(member.id, member.email)}
                >
                  Confirm Remove
                </Button>
                <Button 
                  variant="outlined" 
                  size="small"
                  onClick={() => setVerifyingRemoval(null)}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <Button 
                variant="outlined" 
                color="error" 
                size="small"
                onClick={() => setVerifyingRemoval(member.id)}
              >
                Remove
              </Button>
            )}
          </>
        )}
                </Box>
              ))}

{teamData.plan && teamData.plan.teamMembers && (<>
<Typography variant="body2" color="textSecondary" mt={2}>
                {teamData.plan.teamMembers - (teammembers ? teammembers.length : 0)} spot{teamData.plan.teamMembers - (teammembers ? teammembers.length : 0) !== 1 ? 's' : ''} remaining
              </Typography>
              
              {(!teammembers || teammembers.length < teamData.plan.teamMembers) && (
         <Box
         sx={{
           display: "flex", 
           flexDirection: isMobile ? "column" : "row", 
           gap: 2, 
           alignItems: isMobile ? "stretch" : "center", 
           justifyContent: "flex-end", 
           mt: 2,
         }}
       >
            <form onSubmit={(e) => { e.preventDefault(); handleAddTeammate(); }} style={{ width: '100%' }}>
              <TextField
                label="Add email"
                value={addEmail}
                variant="standard"
                onChange={(e) => setAddEmail(e.target.value)}
                fullWidth
                InputProps={{
                  style: { fontSize: 16 },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button 
                        variant="contained" 
                        color="primary" 
                        sx={{ mb: 2 }}
                        type="submit"
                        disabled={
                          !isValidEmail(addEmail) ||
                          (teammembers && teammembers.some((member) => member.email === addEmail)) ||
                          !addEmail || (teammembers && teammembers.length >= teamData.plan.teamMembers)}
                      >
                        Add
                      </Button>
                    </InputAdornment>
                  )
                }}
              />
            </form>
                </Box>
              )}
</>)}
            </Box>
    </Box>
  );
}


function TeamOverview(props) {
  const { teamData, teammembers, isMobile, user } = props;
  return (
    <Box
      sx={{
        padding: 3,
        backgroundColor: '#f9f9f9',
        borderRadius: 2,
        boxShadow: 1,
        
      }}
    >
      {/* Header */}
      <Typography variant="h6" sx={{ marginBottom: 1 }}>
        Team Overview
      </Typography>

      {/* Description */}
      <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
        Manage your team settings, plan, and billing information.
      </Typography>

      {/* Team Name Input */}
      <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
        Team Name
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        size="small"
        defaultValue={teamData.name}
        disabled
        sx={{
          backgroundColor: '#ffffff',
          borderRadius: 1,
          marginBottom: 1,
        }}
      />
      {/* <Typography variant="body2" color="text.secondary">
        Your Team name is not currently displayed to your subscribers or on your publication's websites
      </Typography> */}
    </Box>
  );
}