import {
  Box,
  Grid,
  Typography,
  Slider,
  Card,
  createTheme,
  ThemeProvider,
  ButtonBase,
  AppBar,
  Divider,
  Skeleton,
  Tabs,
  Tab,
  CardContent,
  AvatarGroup,
  Avatar,
  Button,
  Popper,
  ClickAwayListener,
  Paper,
  MenuItem,
  IconButton,
  MenuList,
  FormControl,
  Select,
  Chip,
  LinearProgress,
  CircularProgress,
  Tooltip,
  Badge,
  TextField,
  SpeedDialIcon,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Snackbar,
  Alert,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Menu,
  ListItemText,
  ListSubheader,
  FormControlLabel,
  Checkbox,
  ListItem,
} from "@mui/material";
import React, { useState, useMemo } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  ArrowLeft,
  ArrowRight,
  AssessmentOutlined,
  AutoAwesome,
  AutoAwesomeOutlined,
  BarChart,
  Chat,
  CheckBoxOutlined,
  Close,
  Comment,
  CommentOutlined,
  CommentRounded,
  ContentCopyOutlined,
  ContentCopyRounded,
  CopyAllOutlined,
  DescriptionOutlined,
  DescriptionRounded,
  DoneAllOutlined,
  DoneAllRounded,
  DragHandle,
  DragHandleOutlined,
  ExpandLess,
  ExpandMore,
  ExplicitOutlined,
  FavoriteBorder,
  FavoriteBorderRounded,
  FileCopyOutlined,
  FilterListOffOutlined,
  FilterListOutlined,
  FormatListBulletedOutlined,
  HelpOutlineOutlined,
  IndeterminateCheckBoxSharp,
  Info,
  InfoOutlined,
  InfoRounded,
  Insights,
  IosShare,
  IosShareOutlined,
  LocalShippingOutlined,
  MonetizationOnOutlined,
  PersonOffOutlined,
  PersonOutlined,
  QuestionAnswerOutlined,
  QuestionMarkOutlined,
  RateReview,
  Refresh,
  Reply,
  RequestPage,
  RequestQuoteOutlined,
  Search,
  SearchOutlined,
  Send,
  SendOutlined,
  Settings,
  Share,
  ShareOutlined,
  ShortTextOutlined,
  Speed,
  SpeedOutlined,
  SportsScore,
  StarBorderOutlined,
  StarRate,
  ThumbDown,
  ThumbUp,
  Tune,
  Visibility,
  VisibilityOff,
  VisibilityOutlined,
  ArrowDropUpOutlined,
  SentimentNeutralOutlined,
  HideSourceOutlined,
  CheckCircle,
  CheckCircleOutlineOutlined,
  SentimentDissatisfiedOutlined,
  SentimentSatisfiedAltOutlined,
  MenuBook,
  MenuBookOutlined,
  MenuBookTwoTone,
  ContactSupportOutlined,
  ThumbUpAltOutlined,
  MenuOpen,
  ListAlt,
  ListOutlined,
  BoltOutlined,
  BoltTwoTone,
  Add,
  AddOutlined,
  FilterList,
} from "@mui/icons-material";
import axios from "axios";
import CommentComponent from "./CommentComponent";
import { siftsyTheme } from "./siftsytheme";
import { useParams } from "react-router-dom";
import SiftPreview from "./SiftPreview";
import { useUser, UserButton } from "@clerk/clerk-react";
import ProfileSettingsButton from "./ProfileSettingsButton";
import { sifterOptions } from "./constants";
import ReactGA from "react-ga4";
import HelperFunctions from "./HelperFunctions";
import { Grow } from "@mui/material";
import html2canvas from "html2canvas";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./SiftPage.css";
import { v4 as uuidv4 } from "uuid";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import VibeCheckSection from "./VibeCheckSection";
import Footer from "./Footer";
import { filter, update } from "lodash";
import { sanitizeInput, containsJSInjection } from "./Helpers";
import { VariableSizeList, FixedSizeList } from "react-window";
import "./GroupReport.css";
import InfiniteLoader from "react-window-infinite-loader";
import { ArrowPathIcon, ArrowsUpDownIcon, BoltIcon, ChatBubbleLeftRightIcon, CurrencyDollarIcon, FaceFrownIcon, FaceSmileIcon, FunnelIcon, HandThumbUpIcon, InformationCircleIcon, MagnifyingGlassIcon, MagnifyingGlassPlusIcon, NumberedListIcon, PaperAirplaneIcon, QuestionMarkCircleIcon, SparklesIcon, SpeakerWaveIcon, XMarkIcon } from "@heroicons/react/24/outline";

export default function CommentView(props) {
  const Functions = HelperFunctions();
  
  const { isLoaded, isSignedIn, user } = useUser();
  const {
    teamData,
    viewportHeight,
    viewportWidth,
    selectedPostData,
    selectedComments,
    handleSelectedComments,
    goToPost,
    selectedGroups,
    selectedPost,
    fullTable,
    showVibeCheck,
    setShowVibeCheck,
    selectedTags,
    selectedAccounts,
  } = props;

  const isMobile = viewportWidth < 900;

  const [postData, setPostData] = React.useState(null);
  const [group, setGroup] = React.useState(null);
  const [commentCount, setCommentCount] = React.useState(20);
  const [empty, setEmpty] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [searchedComments, setSearchedComments] = React.useState([]);
  const [openExport, setOpenExport] = React.useState(false);
  const [searchTimer, setSearchTimer] = React.useState(null);
  const [selectMode, setSelectMode] = React.useState(false);
  const [username, setUsername] = React.useState("");
  const [copied, setCopied] = React.useState(false);
  const [filters, setFilters] = React.useState([]);
  const [searchFilters, setSearchFilters] = React.useState([]);
  const [searching, setSearching] = React.useState(false);
  const [exporting, setExporting] = React.useState(false);
  const watermarkRef = React.useRef(null);
  const screenShotRef = React.useRef(null);
  const [screenshotComments, setScreenshotComments] = React.useState(null);
  const [openSearchPopper, setOpenSearchPopper] = React.useState(false);
  const [listKey, setListKey] = React.useState(0);
  const listRef = React.useRef(null);
  const searchBoxRef = React.useRef(null);
  const commentLoaderRef = React.useRef(null); // Create a ref for the InfiniteLoader
  const [sortBy, setSortBy] = React.useState(null);
  const [sortByOpen, setSortByOpen] = React.useState(false);
  const [commentData, setCommentData] = React.useState([]);
  const [fetchingCommenterProfiles, setFetchingCommenterProfiles] = React.useState(false);

  React.useEffect(() => {
    if (listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
    setListKey((prevKey) => prevKey + 1);
  }, [searchedComments]);

  const [screenshotSettings, setScreenshotSettings] = React.useState({
    includeWatermark: true,
    includeLikes: true,
    imageSize: 4,
    exportIndividually: false,
  });
  const [searchedCommentCount, setSearchedCommentCount] = React.useState(null);
  const [searched, setSearched] = React.useState(false);
  const [commentsInView, setCommentsInView] = React.useState(400);
  const commentSection = React.useRef(null);
  const [search, setSearch] = React.useState("");
  const commentBox = React.useRef(null);
  const [commentBoxHeight, setCommentBoxHeight] = React.useState(200);

  // React.useEffect(() => {
  //   if (searchBoxRef.current) {
  //     const resizeObserver = new ResizeObserver(() => {
  //       // Dispatch a custom resize event
  //       window.dispatchEvent(new Event("resize"));
  //     });

  //     resizeObserver.observe(searchBoxRef.current);

  //     // Cleanup function
  //     return () => {
  //       resizeObserver.disconnect();
  //     };
  //   }
  // }, [searchBoxRef]);

  // const [expandComments, setExpandComments] = React.useState(() => {
  //   const savedExpandComments = localStorage.getItem("expandComments");
  //   return savedExpandComments ? JSON.parse(savedExpandComments) : false;
  // });

  // const handleExpandComments = React.useCallback((state) => {
  //   setExpandComments(state);
  //   localStorage.setItem("expandComments", JSON.stringify(state));
  //   setListKey((prevKey) => prevKey + 1);
  // }, []);

  const calculateCommentBoxHeight = () => {
    if (commentBox.current) {
      // if(isMobile){
      // const viewportHeight = window.innerHeight;
      // const commentBoxTop = commentBox.current.getBoundingClientRect().top;
      // const bottomPadding = isMobile ? 10 : 10; // Adjust this value as needed
      // // const newHeight = viewportHeight - commentBoxTop - bottomPadding;
      // const newHeight =
      //   viewportHeight - (commentBox.current.offsetTop + bottomPadding);
      // setCommentBoxHeight(newHeight);
      // }
      // else{
        setCommentBoxHeight(window.innerHeight * 0.6);
      // }
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", calculateCommentBoxHeight);

    return () => {
      window.removeEventListener("resize", calculateCommentBoxHeight);
    };
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      calculateCommentBoxHeight();
    }, 50);
  }, [listKey, fullTable, viewportHeight]);

  const handleSetSearch = (search) => {
    const sanitizedValue = sanitizeInput(search);
    if (containsJSInjection(sanitizedValue)) {
      console.warn("Potential JavaScript injection detected.");
    } else if(search.length > 0 && search.length < 99){
      setSearch(sanitizedValue);
    }
    else if (search.trim() === "") {
      setSearch("");
      setSearched(false);
    }

  };

  React.useEffect(() => {
    const handleSearchPopper = () => {
      // console.log("scrolling...", commentSection.current.scrollTop);

      if (commentSection && commentSection.current.scrollTop > 500) {
        setOpenSearchPopper(true);
      } else {
        setOpenSearchPopper(false);
      }
    };

    if (commentSection && commentSection.current) {
      commentSection.current.addEventListener("scroll", handleSearchPopper);

      return () => {
        if (commentSection && commentSection.current) {
          commentSection.current.removeEventListener(
            "scroll",
            handleSearchPopper
          );
        }
      };
    }
  }, [commentSection]);

  React.useEffect(() => {
    async function updateSearchedComments() {
    let filteredComments = await filterComments(searchedComments);
    setSearchedComments(filteredComments);
    setSearchedCommentCount(filteredComments.length);
    setSearched(true);
    setListKey(listKey + 1);
    }

    if(listKey > 1){
    updateSearchedComments();
    }

  }

  , [sortBy]);

  React.useEffect(() => {
    if (selectedComments) {
      setScreenshotComments(selectedComments);
    }
  }, [selectedComments]);

  React.useEffect(() => {
    async function updateSearchedComments() {
      if (searchFilters.length > 0) {
        let filteredComments = await filterComments(searchedComments);
        setSearchedComments(filteredComments);
        setSearchedCommentCount(filteredComments.length);
        setSearched(true);
        setListKey(listKey + 1);
      }
    }

    //reset comments if search filters includes both excludeCreator and onlyCreator

    if (searchFilters.length > 0) {
      updateSearchedComments();
    }
    // else{
    //   resetComments();
    // }
  }, [searchFilters]);

  const goToPostComment = React.useCallback(
    async (comment) => {
      // console.log("goToPostComment", comment);
      //find post in teamData and set selectedAssets
      goToPost(comment.postId);

      let newComment = { postId: comment.postId, comment: comment };

      let newSearchedComments = [newComment];
      //delay 1s

      await new Promise((resolve) => setTimeout(resolve, 750));

      if (
        newComment.comment.replyTo &&
        typeof newComment.comment.replyTo == "string"
      ) {
        let replyToComment = commentData.find(
          (comment) => comment.comment.id == newComment.comment.replyTo
        );
        while (replyToComment) {
          newSearchedComments = [replyToComment, ...newSearchedComments];
          replyToComment = commentData.find(
            (comment) => comment.comment.id == replyToComment.comment.replyTo
          );
        }
      }

      if (newSearchedComments.length > 4) {
        newSearchedComments = [
          ...newSearchedComments.slice(0, 1),
          ...newSearchedComments.slice(-1),
        ];
      }

      setSearchedComments(newSearchedComments);
      setSearchedCommentCount(1);
      setSearched(false);
      setListKey(listKey + 1);
    },
    [commentData, goToPost]
  );

  React.useEffect(() => {
    resetComments();
  }, [selectedPostData]);

  const filterComments = React.useCallback(
    async (comments) => {
      // console.log("filtering comments", comments);
      if (!Array.isArray(comments)) {
        console.warn(
          "Expected comments to be an array, but received:",
          comments
        );
        return [];
      }

      const filterValue = searchFilters.find(
        (filter) =>
          filter.value === "excludeCreator" || filter.value === "onlyCreator"
      )?.value;

      if (filterValue) {
        comments = comments.filter((comment) => {
          const postData = selectedPostData.find(
            (post) => post.id == comment?.postId
          );
          if (!postData?.data?.username) {
            console.warn("Missing username data for post:", postData);
            return true; // Keep the comment if we can't determine the creator
          }

          const commentUsername =
            comment?.comment?.username?.replace("@", "") || "";
          const commentUserlink =
            comment?.comment?.userlink?.replace("/@", "") || "";
          const postUsername = postData.data.username.replace("@", "");

          if (filterValue === "excludeCreator") {
            return (
              commentUsername !== postUsername &&
              commentUserlink !== postUsername
            );
          } else if (filterValue === "onlyCreator") {
            return (
              commentUsername === postUsername ||
              commentUserlink === postUsername
            );
          }
        });
      }

      //check if filterValue contains hideEmojiComments

      if (searchFilters.find((filter) => filter.value === "onlyShowEmojiComments")) {
        // filter comments that ONLY contain emojis
        comments = comments.filter((comment) => {
          const text = comment?.comment?.text || "";
          // Match if the entire text consists only of emojis
          return /[\p{Extended_Pictographic}]/u.test(text);
        });
      }
      else if (searchFilters.find((filter) => filter.value === "hideEmojiComments")) {
        // filter comments that contain emojis
        comments = comments.filter((comment) => {
          const text = comment?.comment?.text || "";
          // Match if text contains anything other than emojis and emoji modifiers
          return !/^(?:\p{Emoji}(?:\p{Emoji_Modifier}|\uFE0F)?\s*)+$/u.test(text.trim());
        });
      }

      if (sortBy && sortBy === "likes") {
        comments = comments.sort((a, b) => {
          // Ensure both comments exist and have engagement data
          const likesA = a?.comment?.engagement?.likes || 0;
          const likesB = b?.comment?.engagement?.likes || 0;
          return likesB - likesA;
        });
      }
      // else if (sortBy && sortBy === "replies") {
      //   //if comment has replies (comments below have)
  
      //   comments = comments.sort((a, b) => {
      //     console.log(a, b);
      //     return (
      //        (a.comment.replyTo ? 0 : a.comment?.replies ? (a.comment?.replies.length > 0 ? -1 : 1 ) : 0)
      //     )
      //   }
      //   );
      
      // }
    
      return comments;
    },
    [searchFilters, selectedPostData, sortBy]
  );
  
  const resetComments = React.useCallback(async () => {
    setLoading(true);
    handleSetSearch("");
    setSearch("");
    setFilters([]);

    console.log("Fetching comment data...");

    const fetchPromises = selectedPostData
      .slice(0, Math.min(selectedPostData.length, 20))
      .map((post) => Functions.fetchCommentDataByPostId(post.id));

    const commentDataArray = await Promise.all(fetchPromises);

    const updatedCommentData = commentDataArray.reduce((acc, data) => {
      if (data) {
        Object.assign(acc, data);
      }
      return acc;
    }, {});

    let listedComments = ListComments(updatedCommentData);
    setCommentData(listedComments);
    let filteredComments = await filterComments(listedComments);
    setSearchedCommentCount(filteredComments.length);
    setSearchedComments(
      filteredComments
      // .sort((a, b) => {
      //   if (!a.comment.replyTo && !b.comment.replyTo) {
      //     return (b.comment.engagement?.likes || 0) - (a.comment.engagement?.likes || 0);
      //   }
      //   return 0; // Keep original order for replies
      // })
    );

    setTimeout(() => {
      setListKey(listKey + 1);
      setSearched(false);
      setLoading(false);
      }
      , 500);
    // alert(selectedPostData.length);
  }, [selectedPostData, filterComments, setSearched, handleSetSearch]);

 



  const filterSearchedComments = React.useCallback(async () => {
    // setLoading(true);
    handleSetSearch("");
    setFilters([]);

    // console.log("Fetching comment data...");

    // const fetchPromises = selectedPostData
    //   .slice(0, Math.min(selectedPostData.length, 20))
    //   .map((post) => Functions.fetchCommentDataByPostId(post.id));

    // const commentDataArray = await Promise.all(fetchPromises);

    // const updatedCommentData = commentDataArray.reduce((acc, data) => {
    //   if (data) {
    //     Object.assign(acc, data);
    //   }
    //   return acc;
    // }, {});

    let filteredComments = await filterComments(commentData);
    // setCommentData(filteredComments);
    setSearchedCommentCount(filteredComments.length);
    setSearchedComments(
      filteredComments
      // .sort((a, b) => {
      //   if (!a.comment.replyTo && !b.comment.replyTo) {
      //     return (b.comment.engagement?.likes || 0) - (a.comment.engagement?.likes || 0);
      //   }
      //   return 0; // Keep original order for replies
      // })
    );
    setTimeout(() => {

    setListKey(listKey + 1);
    setSearched(false);
    setLoading(false);
    }
    , 500);

    // alert(selectedPostData.length);
  }, [selectedPostData, searchFilters, filterComments, setSearched, commentData]);

  const searchComments = React.useCallback(
    async (term) => {
      if (
        selectedPostData.length > 0 &&
        ((term.length > 0 && term.trim() !== "") || filters.length > 0) &&
        term.length < 50 &&
        !searching
      ) {
        setSearching(true);
        handleSetSearch(term);
        let results = new Set(); // Use a Set to automatically remove duplicates
  
        // Reset search results
        setSearchedComments([]);
        setSearchedCommentCount(0);
  
        try {
          // const timeoutPromise = new Promise((resolve, reject) => {
          //   setTimeout(() => {
          //     reject(new Error("Search timeout"));
          //   }, 20000);
          // });
  
          const BATCH_SIZE = 20;
          const DEBOUNCE_DELAY = 300;
          const SEARCH_TIMEOUT = 20000; 

          
  
          let debounceTimer;
          let batchedResults = [];
  
          const postIds = selectedPostData
            .filter((post) => post.data.totalComments > 0)
            .map((post) => post.id);
  
          if (postIds.length > 0 && results.size < 100) {
            try {
              let data = await Functions.searchCommentsByPosts(
                term,
                filters.map((filter) => filter.value),
                postIds // Pass the array of post IDs
              );
  
              // Add new results to the Set
              data.results.forEach((result) =>
                results.add(JSON.stringify(result))
              );
  
              let filteredComments = await filterComments(
                Array.from(results).map(JSON.parse)
              );
              filteredComments.sort((a, b) => {
                if (!a.comment.replyTo && !b.comment.replyTo) {
                  
                  return (
                    (b.comment.engagement?.likes || 0) -
                    (a.comment.engagement?.likes || 0)
                  );
                }
                return 0; // Keep original order for replies
              });
  
              clearTimeout(debounceTimer);
              debounceTimer = setTimeout(() => {
                setSearchedComments((prevComments) => {
                  const newComments = filteredComments.filter(
                    (comment) =>
                      !prevComments.some(
                        (prevComment) =>
                          prevComment.comment.id === comment.comment.id &&
                          prevComment.postId === comment.postId
                      )
                  );
                  const updatedComments = [
                    ...prevComments,
                    ...newComments,
                  ];
                  return updatedComments.sort((a, b) =>
                    a.comment.engagement.likes &&
                    b.comment.engagement.likes
                      ? b.comment.engagement.likes -
                        a.comment.engagement.likes
                      : 0
                  );
                });
                setSearchedCommentCount((prevCount) => results.size);
                setListKey((prevKey) => prevKey + 1);
              }, DEBOUNCE_DELAY);
            } catch (error) {
              console.log("Error searching comments:", error);
            }
          }
        } catch (error) {
          console.log("Error searching comments:", error);
          setTimeout(() => {
            setSearched(false);
            setSearching(false);
          }, 250);
        } finally {
          setTimeout(() => {
            setSearched(true);
            setListKey((prevKey) => prevKey + 1);
            setSearching(false);
          }, 250);
        }
      } else {
        let filteredComments = await filterComments(commentData);
        setSearchedCommentCount(filteredComments.length);
        setSearchedComments(filteredComments);
        setTimeout(() => {
          setSearched(true);
          setListKey((prevKey) => prevKey + 1);
          setSearching(false);
        }, 250);
      }
  
      return true;
    },
    [
      search,
      filters,
      selectedPostData,
      commentData,
      searchFilters,
      setSearched,
      handleSelectedComments,
      handleSetSearch,
      setSearching
    ]
  );
  


  

  const handleSearch = React.useCallback(
    (search) => {
      handleSetSearch(search);
    },
    [search]
  );

  const handleExportText = () => {
    // take selected comment text and copy to clipboard

    setCopied(true);

    let dataToExport = selectedComments.map(
      (comment) =>
        `${commentData.find((c) => c.commentId === comment).username}: ${
          commentData.find((c) => c.commentId === comment).text
        }`
    );

    let text = dataToExport.join("\n");

    // Copy data to clipboard
    navigator.clipboard
      .writeText(text)
      .catch((error) => console.error("Unable to copy:", error));
  };

  const exportIndividualComment = async (selected) => {
    try {
      setExporting(true);
      let comment = { comment: selected, postId: selected.postId };

  

      // Set state and wait for DOM update
      await new Promise(resolve => {
        setScreenshotComments([comment]);
        setTimeout(resolve, 1000); // Give React time to update DOM
      });

      // Take screenshot after DOM has updated
      const style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet?.insertRule("body > div:last-child img { display: inline-block; }");

      const canvas = await html2canvas(screenShotRef.current, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: null,
        scale: 12,
      });

      // Handle download
      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `@${comment.comment.username.replace("@", "")}'s_comment.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      style.remove();
      return true;

    } catch (error) {
      console.error("Error exporting individual comment:", error);
      throw error;
    } finally {
      setExporting(false);
      setScreenshotComments(selectedComments);
    }
  };

  const handleExportImage = async () => {
    setExporting(true);

    if (screenshotSettings.exportIndividually) {
      for (let i = 0; i < selectedComments.length; i++) {
        setScreenshotComments([selectedComments[i]]);
        await new Promise((resolve) => setTimeout(resolve, 1000));

        const style = document.createElement("style");
        document.head.appendChild(style);
        style.sheet?.insertRule(
          "body > div:last-child img { display: inline-block; }"
        );

        let canvas = await html2canvas(screenShotRef.current, {
          useCORS: true,
          allowTaint: true,
          logging: true,
          backgroundColor: null,
        });

        const link = document.createElement("a");
        link.href = canvas.toDataURL();
        link.download = `@${selectedComments[i].comment.username.replace(
          "@",
          ""
        )}'s_comment.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        style.remove();

        setExporting(false);
        setScreenshotComments(selectedComments);
      }
    } else if (screenShotRef.current) {
      // const images = screenShotRef.current.getElementsByTagName('img');
      // console.log("images", images);

      //   console.log("Images loaded");

      const style = document.createElement("style");
      document.head.appendChild(style);
      style.sheet?.insertRule(
        "body > div:last-child img { display: inline-block; }"
      );

      let canvas = await html2canvas(screenShotRef.current, {
        useCORS: true,
        allowTaint: true,
        logging: true,
        backgroundColor: null,
      });

      const link = document.createElement("a");
      link.href = canvas.toDataURL();
      link.download = `siftsy_comments.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      style.remove();
    }

    // let url = await handleDownload(
    //   selectedComments,
    //   "siftsy"
    // );

    setExporting(false);
  };

  const copySummary = () => {
    navigator.clipboard
      .writeText(reportData.summary)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch((error) => console.error("Unable to copy:", error));
  };

  const selectComment = React.useCallback(
    (entry) => {
      //   if (!selectedComments.some(selected => selected.commentId === entry.commentId && selected.postId === entry.postId)) {
      entry.selectedId = uuidv4();
      handleSelectedComments([...selectedComments, entry]);
      // }
    },
    [selectedComments]
  );

  const removeComment = React.useCallback(
    (entry) => {
      let newSelected = [...selectedComments];
      newSelected.splice(selectedComments.indexOf(entry), 1);
      handleSelectedComments(newSelected);
      if (newSelected.length == 0) {
        setSelectMode(false);
        setOpenExport(false);
      }
    },
    [selectedComments]
  );

  const selectAllComments = React.useCallback(() => {
    let newSelected = [...searchedComments];
    handleSelectedComments(newSelected);
  }, [selectedComments]);

  const deselectAllComments = React.useCallback(() => {
    handleSelectedComments([]);
    setSelectMode(false);
  }, []);

  const allSelected = selectedComments.length === searchedComments.length;

  const reportData = selectedPostData.length
    ? selectedPostData[0].reports ? 
    selectedPostData[0].reports.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      )[0] : null
    : null;

  const totalComments = selectedPostData.reduce((acc, post) => {
    // console.log("postId", post.id);
    // console.log("totalComments", post.data.totalComments);
    return acc + parseInt(post.data.totalComments);
  }, 0);

  const handleCommentSelection = React.useCallback(
    (searched) => (event) => {
      event.preventDefault();
      if (event.target.checked) {
        selectComment(searched);
        if (!selectMode) {
          setSelectMode(true);
        }
      } else {
        if (selectedComments.length < 2) {
          setSelectMode(false);
        }
        removeComment(searched);
      }
    },
    [
      selectComment,
      removeComment,
      selectMode,
      setSelectMode,
      selectedComments.length,
    ]
  );

  // const getCommentSize = React.useCallback(
  //   (index) => {
  //     //50 per text line
  //     return (
  //       70 +
  //       (searchedComments[index].comment.text.length / (
  //         document.getElementById("comment-section").getBoundingClientRect().width/18)) *
  //       (small ? 20 : isMobile ? 12 : 12)
  //     );
  //   },
  //   [searchedComments]
  // );
  const getCommentSize = React.useCallback(
    (index) => {
      // Guard against invalid index or empty searchedComments
      if (!searchedComments[index]) {
        return 100; // Default fallback height
      }
  
      const username = searchedComments[index].comment.username || '';
      const text = searchedComments[index].comment.text || '';
      
      // Create canvas once and reuse
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      
      // Set font properties
      const fontSize = 14;
      const lineHeightMultiplier = isMobile ? 1.57 : 2.14; // 22px or 30px line height
      const basePadding = 30;
      
      context.font = `${fontSize}px Gilroy`;
  
      // Get container width with fallbacks
      const getContainerWidth = () => {
        if (commentSection?.current) {
          const width = commentSection.current.getBoundingClientRect().width;
          return Math.max(width - 100, 200); // Minimum width of 200px
        }
        return window.innerWidth - 100; // Fallback to window width
      };
  
      const maxWidth = getContainerWidth();
  
      // Improved text wrapping function
      const calculateLines = (text) => {
        if (!text) return [];
        
        const words = text.split(' ');
        const lines = [];
        let currentLine = '';
  
        words.forEach(word => {
          // Handle newlines in text
          if (word.includes('\n')) {
            const segments = word.split('\n');
            segments.forEach((segment, i) => {
              if (currentLine) {
                lines.push(currentLine.trim());
                currentLine = '';
              }
              if (segment) {
                currentLine = segment + ' ';
              }
              if (i < segments.length - 1) {
                lines.push(currentLine.trim());
                currentLine = '';
              }
            });
            return;
          }
  
          const testLine = currentLine + word + ' ';
          const metrics = context.measureText(testLine);
          
          if (metrics.width > maxWidth && currentLine) {
            lines.push(currentLine.trim());
            currentLine = word + ' ';
          } else {
            currentLine = testLine;
          }
        });
  
        if (currentLine.trim()) {
          lines.push(currentLine.trim());
        }
  
        return lines;
      };
  
      // Calculate total lines needed
      const usernameLines = calculateLines(username);
      const textLines = calculateLines(text);
      const totalLines = usernameLines.length + textLines.length;
  
      // Calculate final height
      const totalHeight = Math.max(
        (totalLines * fontSize * lineHeightMultiplier) + basePadding,
        70 // Minimum height
      );
  
      return totalHeight;
    },
    [searchedComments, isMobile, commentSection]
  );
  const updateContainerWidth = React.useMemo(() => {
    let timeoutId;
    let lastWidth = 0; // Track the last width that triggered an update
    
    return () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (commentSection?.current) {
          const currentWidth = commentSection.current.getBoundingClientRect().width;
          // Only update if width changed by 50px or more
          if (Math.abs(currentWidth - lastWidth) >= 50) {
            getCommentSize.containerWidth = currentWidth;
            lastWidth = currentWidth; // Update the last width
            if (listRef.current) {
              listRef.current.resetAfterIndex(0);
            }
          }
        }
      }, 150); // Keep debounce delay
    };
  }, []);
  
  // Optimized resize observer
  React.useEffect(() => {
    const resizeObserver = new ResizeObserver(updateContainerWidth);
  
    if (commentSection?.current) {
      resizeObserver.observe(commentSection.current);
    }
  
    return () => {
      resizeObserver.disconnect();
    };
  }, [updateContainerWidth]);
  
  // Update container width on mount and when dependencies change
  React.useEffect(() => {
    updateContainerWidth();
  }, [updateContainerWidth, commentSection]);

  const listHeight = window.innerHeight * 0.64 - 100;

  const Row = useMemo(
    () =>
      React.memo(({ index, style }) => {
        const searched = searchedComments[index];
        const postData = selectedPostData.find(
          (post) => post.id == searched.postId
        );
        const showAsReply =
          searched.comment.replyTo &&
          typeof searched.comment.replyTo == "string" &&
          searchedComments.some(
            (comment) => comment.comment.id == searched.comment.replyTo
          );
        const platform = postData
          ? postData.data.platform || "tiktok"
          : "tiktok";
        const isSelected = selectedComments.some(
          (selected) =>
            selected.comment.id == searched.comment.id &&
            selected.postId == searched.postId
        );

        const memoizedStyle = useMemo(() => style, [style]);

        const isMobile = viewportWidth < 600;

        return (
          <Box 
            style={memoizedStyle} 
            key={searched.comment.id}
            draggable
            onDragStart={(e) => {
              e.dataTransfer.setData('comment', JSON.stringify(searched.comment));
              e.dataTransfer.effectAllowed = 'copy';
            }}
            sx={{
              cursor: 'grab',
              '&:active': {
                cursor: 'grabbing'
              }
            }}
          >
            <CommentComponent
              platform={platform}
              setCopied={setCopied}
              exportIndividualComment={exportIndividualComment}
              selectable
              goToPostComment={
                selectedPostData.length > 1 ? goToPostComment : null
              }
              isMobile={isMobile}
              comment={{ ...searched.comment, postId: searched.postId }}
              selected={isSelected}
              selectMode={selectMode}
              selectOnHover
              setSelectMode={setSelectMode}
              handleCommentSelection={handleCommentSelection(searched)}
              showAsReply={showAsReply}
              small={viewportHeight < 1000}
            />
          </Box>
        );
      }),
    [
      searchedComments,
      selectedPostData,
      setCopied,
      exportIndividualComment,
      goToPostComment,
      viewportWidth,
      selectedComments,
      selectMode,
      setSelectMode,
      handleCommentSelection,
    ]
  );

  const isItemLoaded = React.useCallback(
    (index) => {
      return index < searchedComments.length;
    },
    [searchedComments]
  );

  const loadMoreItems = React.useCallback((startIndex, stopIndex) => {
    console.log("loading more items", startIndex, stopIndex);
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });
  }, []);

  const itemCount = 200;

  const small = viewportHeight < 1000;

  return selectedPostData.length == 1 &&
    selectedPostData[0].data &&
    selectedPostData[0].data.totalComments == 0 ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        m: 4,
      }}
    >
      <Typography variant="h6" align="center" sx={{ color: "#888" }}>
        There are no comments on this post.
      </Typography>
      <Typography variant="body1" align="center" sx={{ color: "#888" }}>
        Check back later to see if viewers have added comments!
      </Typography>
    </Box>
  ) : !empty ? (
    <>
      <Box
        id="comment-section"
        sx={{
          // height: "100vh",
          height:"100%",
          overflow: "hidden",
        }}
      >



        <Box
          ref={commentSection}
          sx={{
            // height:
            //   viewportWidth < 600
            //     ? "calc(100vh - 180px)"
            //     : "calc(100vh - 224px)",
            // overflowY: "hidden",
            // overflowX: "hidden",
            overflowY: "auto", // Allow vertical scrolling
            overflowX: "hidden", // Prevent horizontal scrolling
            "&::-webkit-scrollbar": {
              width: "20px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
              borderRadius: 20,
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#ddd",
              border: "5px solid #f7f8fa",
              borderRadius: 20,
            },
            display: "flex",
            flexDirection: "column",
            gap: viewportWidth < 600 ? 1 : 2,
          }}
        >
        
        <Box
            sx={{
              borderRadius: 3,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // width: "100%",
              overflow: "hidden",
              border: "1px solid #DDD",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                p: 2,
                pb: 0,
                display: "flex",
                flexDirection: "row",
                gap: 0,
              }}
            >
              <Typography
                variant="body2"
                align="left"
                sx={{ color: "#5C6078", fontWeight: 700 }}
              >
                <span style={{letterSpacing: -.3, fontWeight: 700, fontSize:14}}>
                Siftsy</span> Search 
              </Typography>
              <SparklesIcon style={{ color: "#5C6078", height:12, ml:-.8 }} />
              {/* {searchedCommentCount > 0 && (
                <Chip
                  sx={{
                    background: "#4728c4",
                    color: "white",
                    fontWeight: 500,
                  }}
                  size="small"
                  label={
                    searchedCommentCount == commentData.length
                      ? formatNumber(totalComments)
                      : formatNumber(searchedComments.length)
                  }
                />
              )} */}
            </Box>

            <Box sx={{p:1, pt: 0 }}>
              <SearchWithFilter
              searchTerm={search}
              searched={searched}
                searchBoxRef={searchBoxRef}
                copySummary={copySummary}
                reportData={reportData}
                commentSection={commentSection}
                viewportWidth={viewportWidth}
                searchComments={searchComments}
                selectedPostData={selectedPostData.slice(0, 10)}
                filters={filters}
                setFilters={setFilters}
                setSearchFilters={setSearchFilters}
                searchFilters={searchFilters}
                resetComments={resetComments}
                viewportHeight={viewportHeight}
                filterSearchedComments={filterSearchedComments}
              />
            </Box>
          </Box>

          <Grid container spacing={searching ? 0 : isMobile ? 1 : 2}>
        
              <Grid
                item
                xs={fullTable && !isMobile ? 4 : 12}
                sx={{ zIndex: 1 }}
              >
                {commentData && commentData.length > 0 ? (
                  <VibeCheckSection
                  listKey={listKey}
                  search={search}
                  teamHasGroups={teamData?.groups && teamData?.groups?.length > 0}
                  searching={searching}
                  searchComments={searchComments}
                  handleSetSearch={handleSetSearch}
                    searched={searched && searchedComments.length > 0 && search.trim() !== ""}
                    selected={selectedComments.length > 2}
                    selectedComments={selectedComments}
                    searchFilters={searchFilters}
                    searchFiltered={(searchFilters && searchFilters.length > 0)}
                    campaignFiltered={selectedGroups && selectedGroups.length > 0}
                    accountFiltered={selectedAccounts && selectedAccounts.length > 0}
                    tagFiltered={selectedTags && selectedTags.length > 0}
                    singlePost={selectedPostData.length == 1}
                    selectedPostData={selectedPostData}
                    selectedGroups={selectedGroups}
                    fullCommentData={commentData}
                    searchedComments={searchedComments}
                    isMobile={viewportWidth < 900}
                    fullTable={fullTable}
                    selectedTags={selectedTags}
                    selectedAccounts={selectedAccounts} 
                    goToPost={goToPost}
                  />
                ) : loading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: isMobile ? 1 : 2,
                      flexDirection: fullTable && !isMobile ? "column" : "row",
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: 180,
                        overflow: "hidden",
                        border: "1px solid #DDD",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: 180,
                          overflow: "hidden",
                        }}
                      >
                        <Skeleton
                          width="110%"
                          height={"110%"}
                          sx={{ margin: "0 auto", opacity: 0.5 }}
                          variant="rectangular"
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        borderRadius: 3,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: 180,
                        overflow: "hidden",
                        border: "1px solid #DDD",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: 180,
                          overflow: "hidden",
                        }}
                      >
                        <Skeleton
                          width="110%"
                          height={"110%"}
                          sx={{ margin: "0 auto", opacity: 0.5 }}
                          variant="rectangular"
                        />
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </Grid>
          
            <Grid
              item
              xs={fullTable && !isMobile ? 8 : 12}
              // sx={{ zIndex: expandComments ? 100 : 1 }}
            >
              <Box
                ref={commentBox}
                sx={{
                  overflow: "hidden",
                  backgroundColor: "#fff",
                  border: "1px solid #DDD",
                  borderRadius: 3,
                  p: 2,
            
                  background: "#fff",
                  height: commentBoxHeight, // Increase height by 100px if scrolled beyond 300px
                  // height:commentBoxHeight,
                  // height: `calc(100vh - ${commentBox.current ? (commentBox.current.offsetTop + 10): 0}px)`,

                  // mt: !loading && expandComments ? -20 : 0,

                  // boxShadow: expandComments
                  //   ? "0px 0px 10px 0px rgba(0, 0, 0, 0.2)"
                  //   : "none",
                  animation: "0.3s ease-in-out",
                }}
              >
                {loading ? (
                  <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:1, mt:10}}>
                                      <Typography 
                                                variant="h6" 
                                                color="text.secondary" 
                                                fontSize={18} 
                                                fontStyle="italic" 
                                                fontWeight={700} 
                                                style={{
                                                    color: "text.secondary", 
                                                    display: "inline",
                                                    animation: "pulse 2s infinite"
                                                }}
                                                sx={{
                                                    "@keyframes pulse": {
                                                        "0%": {
                                                            opacity: 1,
                                                        },
                                                        "50%": {
                                                            opacity: 0.6,
                                                        },
                                                        "100%": {
                                                            opacity: 1,
                                                        }
                                                    }
                                                }}
                                            >
                                              Loading comments...


                                            </Typography>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-stretch",
                        alignItems: "center",
                        flexGrow: 1,
                        width: "100%",
                        gap: 1,
                        mb:2,
                      }}
                    >
                      {/* <CommentOutlined
                        sx={{ fontSize: 20, height: 16, color: "#888" }}
                      /> */}
            <Typography
              variant="body2"
              align="left"
              sx={{ color: "#5C6078", fontWeight: 700 }}
              >
              Comments
              </Typography>

                      {searchedCommentCount > 0 && (
                        <Chip
                          sx={{
                            background: "#4728c4",
                            color: "white",
                            fontWeight: 500,
                            pt:.3
                          }}
                          size="small"
                          label={
                            searchedCommentCount == commentData.length
                              ? formatNumber(totalComments)
                              : formatNumber(searchedComments.length)
                          }
                        />
                      )}

                      <Box
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {/* {!showVibeCheck && !fullTable && (
                          <Box
                            onClick={() => setShowVibeCheck(!showVibeCheck)}
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "100%",
                              gap: 1,
                            }}
                          >
                            <VibeCheckSection
                              quickView
                              searched={searched && searchedComments.length > 0}
                              selected={selectedComments.length > 2}
                              selectedPostData={selectedPostData}
                              selectedGroups={selectedGroups}
                              fullCommentData={commentData}
                              searchedComments={searchedComments}
                              selectedComments={selectedComments}
                              isMobile={viewportWidth < 900}
                              fullTable={fullTable}
                            />
                          </Box>
                        )} */}
                        {/* {viewportHeight < 1200 &&
                          showVibeCheck &&
                          !fullTable && (
                            <Box
                              onClick={() => setShowVibeCheck(!showVibeCheck)}
                              sx={{
                                cursor: "pointer",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                gap: 1,
                              }}
                            >
                              <Button
                                size="small"
                                sx={{
                                  borderRadius: 12,
                                  color: "#666",
                                  fontSize: 12,
                                  textTransform: "none",
                                }}
                                startIcon={<ArrowDropUpOutlined />}
                              >
                                Expand
                              </Button>
                            </Box>
                          )} */}

                        {/* 
                  {(!fullTable || isMobile) && (
                  <IconButton onClick={() => handleExpandComments(!expandComments)}>
                    {expandComments ? <ExpandMore /> : <ExpandLess />}
                  </IconButton>
                  )} */}
                      </Box>

                      <Box sx={{display:"flex", flexDirection:"row", gap:2}}>
                      {(searching || (searchFilters.length > 0 && !searched))  ? 
                      <Box sx={{ mr: 2, ml: 2 }}>
                      {/* <Button
                        variant="outlined"
                        onClick={resetComments}
                        color="primary"
                        size="small"
                        sx={{ borderRadius: 12, padding:"2px 16px", pt:.5, textTransform: "none" }}
                        startIcon={<XMarkIcon style={{ height: 14, color:"#4728c4", marginBottom:2 }} />}
                      >
                       Clear Search
                      </Button> */}
                    </Box> :
                      searched ? (
                        <Box >
                          <Button
                            variant="outlined"
                            onClick={resetComments}
                            color="primary"
                            size="small"
                            sx={{ borderRadius: 12, padding:"2px 16px", pt:.5, textTransform: "none" }}
                            startIcon={<XMarkIcon style={{ height: 14, color:"#4728c4", marginBottom:2 }} />}
                          >
                           Clear Search
                          </Button>
                        </Box>
                      ) : 
                      (selectedPostData.length == 1 && searchedComments.length < 5 && selectedPostData[0]?.data?.totalComments > 5) ? (
                        <Box>
                          <Button
                          variant="outlined"
                            onClick={resetComments}
                            color="primary"
                            size="small"
                            sx={{ borderRadius: 12, padding:"2px 16px", pt:.5, textTransform: "none" }}
                            startIcon={<ArrowPathIcon style={{ height: 14, color:"#4728c4", marginBottom:2 }} />}
                          >
                            View All 
                            {/* ({commentData.length}) */}
                          </Button>
                        </Box>
                      ) : null} 


                          <Chip
                          id="sort-by-button"
                          label="Sort"
                          sx={{fontWeight:500, pt:.3, pl:1, pr:1, borderRadius:12, color:"#5C6078", background:sortBy ? "#eeeaff" :"#f7f8fa"}}
                          onClick={() => setSortByOpen(!sortByOpen)}
                           icon={sortBy == "likes" ? <HandThumbUpIcon style={{height:16}} /> : <ArrowsUpDownIcon style={{height:16}} />}
                          />

<Popper open={sortByOpen} anchorEl={document.getElementById("sort-by-button")} placement="bottom-end">
        <ClickAwayListener onClickAway={() => setSortByOpen(false)}>
        <Paper elevation={3} sx={{ p: 2, borderRadius: 4, boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)", zIndex:100 }}>
            <Typography variant="subtitle2" sx={{ mb: 1, color: "text.secondary" }}>
              Sort Options
            </Typography>
            <MenuItem
            value="likes"
        onClick={(e) => {
          e.target.getAttribute("value") == sortBy ? setSortBy(null) : setSortBy("likes");
        }}
        sx={{
          backgroundColor: sortBy === "likes" ? "rgba(71, 40, 196, 0.1)" : "transparent",
          fontWeight: sortBy === "likes" ? "bold" : "normal",
          borderRadius: 1,
        }}
      >
        Top Liked
        <HandThumbUpIcon style={{height:16, margin:"10px"}} />

      </MenuItem>
      {/* <MenuItem
        value="replies"
        onClick={(e) => {
          e.target.getAttribute("value") == sortBy ? setSortBy(null) : setSortBy("replies");

        }}
        sx={{
          backgroundColor: sortBy === "replies" ? "rgba(71, 40, 196, 0.1)" : "transparent",
          fontWeight: sortBy === "replies" ? "bold" : "normal",
          borderRadius: 1,
        }}
      >
        Top Replied <ChatBubbleLeftRightIcon style={{height:16, margin:"10px"}} />
      </MenuItem> */}
          </Paper>
        </ClickAwayListener>
      </Popper>

                      </Box>
                     

                      {/* <Typography
                        variant="body2"
                        align="left"
                        sx={{ color: "#888", fontWeight: 700 }}
                      >
                        {searchedComments ? (
                          searchedCommentCount === null ? (
                            <Skeleton variant="text" width={100} />
                          ) : (
                            `${
                              searchedCommentCount == commentData.length
                                ? formatNumber(totalComments)
                                : formatNumber(searchedComments.length)
                            } ${
                              viewportWidth > 500
                                ? `comment${
                                    searchedComments.length == 1 ? "" : "s"
                                  }`
                                : ""
                            }`
                          )
                        ) : (
                          "Comment Section"
                        )}
                      </Typography> */}
                    </Box>

                    {/* <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-stretch",
                        alignItems: "center",
                        flexGrow: 1,
                        width: "100%",
                        pt: 2,
                      }}
                    >
                      <SearchWithFilter
                        searchBoxRef={searchBoxRef}
                        copySummary={copySummary}
                        reportData={reportData}
                        commentSection={commentSection}
                        viewportWidth={viewportWidth}
                        searchComments={searchComments}
                        selectedPostData={selectedPostData.slice(0, 10)}
                        filters={filters}
                        setFilters={setFilters}
                        setSearchFilters={setSearchFilters}
                        searchFilters={searchFilters}
                        resetComments={resetComments}
                        viewportHeight={viewportHeight}
                        filterSearchedComments={filterSearchedComments}
                      />
                    </Box> */}

                    <Box
                      id="scrolling-section"
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
              {searchedComments.length > 0 ? (
                      <InfiniteLoader
                        key={listKey}
                        isItemLoaded={isItemLoaded}
                        itemCount={itemCount}
                        loadMoreItems={loadMoreItems}
                      >
                        {({ onItemsRendered, ref }) => (
                     
                     
                              <VariableSizeList
                                className="custom-scrollbar"
                                height={
                                  commentBoxHeight 
                                     -
                                  (isMobile ? 65 : 65)
                                }
                                itemCount={searchedComments.length}
                                itemSize={(index) => getCommentSize(index)}
                                estimatedItemSize={100}
                                width="100%"
                                ref={(list) => {
                                  ref(list);
                                  listRef.current = list;
                                }}
                              >
                                {Row}
                              </VariableSizeList>
                        
                        )}
                      </InfiniteLoader>
                         ) : 
                         searching ? (
                           <Box
                             sx={{
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                               height: commentBoxHeight,
                               flexDirection: "column",
                               position:"relative"
                                                       }}
                           >
                             {[...Array(10)].map((_, index) => (
                               <Box sx={{ width: "100%", display:"flex", flexDirection:"row", gap:2, height:200, alignItems:"center", pl:4, pr:4 }}>
                                 <Skeleton variant="circular" width={50} height={50} sx={{opacity:0.4}} />
                             <Skeleton variant="text" width={"100%"} borderRadius={10} height={120} animation="wave" sx={{opacity:0.4}} />
                               </Box>
                             ))}
                                       <Box sx={{ 
                                     position: "absolute", 
                                     top: 0, 
                                     right: 0, 
                                     left: 0, 
                                     display: "flex", 
                                     flexDirection: "column",
                                     justifyContent: "center", 
                                     alignItems: "center", 
                                     textAlign: "center",
                                     mt: 10,
                                     animation: "fadeIn 0.5s ease-in-out",
                                     "@keyframes fadeIn": {
                                         "0%": {
                                             opacity: 0,
                                             transform: "translateY(10px)"
                                         },
                                         "100%": {
                                             opacity: 1,
                                             transform: "translateY(0)"
                                         }
                                     }
                                 }}>
                                     <Typography variant="h6" color="#999" >
                                         Sifting for comments about <br/>
                                         <Typography 
                                             variant="h6" 
                                             color="text.secondary" 
                                             fontSize={25} 
                                             fontStyle="italic" 
                                             fontWeight={700} 
                                             style={{
                                                 color: "text.secondary", 
                                                 display: "inline",
                                                 animation: "pulse 2s infinite",
                                                                                           }}
                                             sx={{
                                          
                                                 "@keyframes pulse": {
                                                     "0%": {
                                                         opacity: 1,
                                                     },
                                                     "50%": {
                                                         opacity: 0.6,
                                                     },
                                                     "100%": {
                                                         opacity: 1,
                                                     }
                                                 }
                                             }}
                                         >
                                             "{search}"...
                                             {/* {searchFilters.length > 0 ? (
                                               <Typography variant="h6" color="text.secondary" fontSize={20} fontStyle="italic" fontWeight={700} style={{color: "text.secondary", display: "inline", animation: "pulse 2s infinite"}} sx={{ "@keyframes pulse": { "0%": { opacity: 1, }, "50%": { opacity: 0.6, }, "100%": { opacity: 1, } } }}> {searchFilters.map((filter, index) => (index == searchFilters.length - 1 ? filter.value : filter.value + ", "))}</Typography>
                                             ) : null} */}

                                         </Typography> 
                                     </Typography>
                                 </Box>
                           </Box>
                         ) : 
                         
                         loading ?
                         <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center", gap:1, mt:10}}>
                         <Typography 
                                   variant="h6" 
                                   color="text.secondary" 
                                   fontSize={18} 
                                   fontStyle="italic" 
                                   fontWeight={700} 
                                   style={{
                                       color: "text.secondary", 
                                       display: "inline",
                                       animation: "pulse 2s infinite"
                                   }}
                                   sx={{
                                       "@keyframes pulse": {
                                           "0%": {
                                               opacity: 1,
                                           },
                                           "50%": {
                                               opacity: 0.6,
                                           },
                                           "100%": {
                                               opacity: 1,
                                           }
                                       }
                                   }}
                               >
                                 Loading comments...


                               </Typography>
     </Box>
                          :

                         (
                           <Box
                             sx={{
                               display: "flex",
                               justifyContent: "center",
                               alignItems: "center",
                               height:
                                 commentBoxHeight -
                                 (searchBoxRef.current
                                   ? searchBoxRef.current.clientHeight
                                   : 0) -
                                 30,
                             }}
                           >
                             <Typography variant="h6" color="text.secondary">
                               No comments found.
                             </Typography>
                           </Box>
                         )}
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {!loading && searchedCommentCount !== null ? (
        <>
          <SelectedCommentsMenu
            open={selectedComments.length > 0}
            deselectAllComments={deselectAllComments}
            selectAllComments={selectAllComments}
            numSelected={selectedComments.length}
            goToExport={() => setOpenExport(true)}
            allSelected={allSelected}
            viewportWidth={viewportWidth}
            fetchingCommenterProfiles
          />

          <ExportCommentsDialog
            selectedComments={selectedComments}
            open={openExport}
            onClose={() => {
              setOpenExport(false);
              setExporting(false);
            }}
            isMobile={viewportWidth < 600}
            screenshotSettings={screenshotSettings}
          >
            <>
              <Box
                sx={{
                  p: 2,
                  border: "1px solid #999",
                  borderRadius: 8,
                  background: "#fefefe",
                }}
              >
                <Box
                  sx={{
                    p: 1,
                    height: "calc(75vh)",
                    overflowX: "hidden",
                    overflowY: "scroll",
                  }}
                >
                  <ExportCommentsList
                    isMobile={viewportWidth < 600}
                    selectedComments={selectedComments}
                    handleSelectedComments={handleSelectedComments}
                    commentData={commentData}
                    previewMode
                    removeComment={removeComment}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  p: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  gap: "1em",
                }}
              >
                {/* <Button
                          variant="outlined"
                          color="secondary"
                          sx={{ borderRadius: 12 }}
                          endIcon={<CopyAllOutlined />}
                          onClick={handleExportText}
                        >
                          Copy
                        </Button> */}
                {exporting ? (
                  <CircularProgress size={20} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ borderRadius: 12 }}
                    endIcon={<ContentCopyOutlined />}
                    disabled={
                      selectedComments.length == 0 ||
                      exporting ||
                      selectedComments.length > 10
                    }
                    onClick={handleExportImage}
                  >
                    {`Download (${selectedComments.length})`}
                  </Button>
                )}
              </Box>
            </>
          </ExportCommentsDialog>

          <Box
            ref={screenShotRef}
            sx={{
              p: 1,
              position: "absolute",
              top: -9999,
              left: -9999,
              // transform: `scale(${screenshotSettings.imageSize})`,
              background: "transparent",
            }}
          >
            <Box
              sx={{
                height: "fit-content",
                width: "fit-content",
                borderRadius: "12px",
                background: "#fff",
              }}
            >
              {screenshotComments
                ? screenshotComments.map((selected, index) => (
                    <Box sx={{ pr: 4, pl: 4, width: "600px" }}>
                      <CommentComponent
                        screenShot
                        comment={selected.comment}
                        showAsReply={
                          selected.comment.replyTo &&
                          typeof selected.comment.replyTo == "string" &&
                          screenshotComments.some(
                            (comment) =>
                              comment.comment.id == selected.comment.replyTo
                          )
                        }
                      />

                      {/* {screenshotComments.length > 1 &&
                      index < screenshotComments.length - 1 ? (
                        <Divider variant="middle" />
                      ) : null} */}
                    </Box>
                  ))
                : null}
            </Box>
          </Box>
        </>
      ) : null}

      <Snackbar
        open={copied}
        autoHideDuration={3000}
        onClose={() => setCopied(false)}
      >
        <Alert onClose={() => setCopied(false)} severity="success">
          <Typography variant="body1" align="center">
            Copied!
          </Typography>
        </Alert>
      </Snackbar>
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        mt: 10,
      }}
    >
      <Typography variant="h6" align="center">
        {" "}
        This page is not available.
      </Typography>

      <Card
        elevation={0}
        sx={{ borderRadius: "12px", background: "#EEEAFF", p: 3, m: 2 }}
      >
        <Typography variant="body1">
          Please make sure you are signed the correct account and have access to
          this page.
        </Typography>
      </Card>
    </Box>
  );
}

function MainSummary(props) {
  const stringWithLineBreaks = props.text.replace(/([.!?]"?)\s+/g, "$1\n\n");
  const [more, setMore] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [show, setShow] = React.useState(true);
  const [timerDisabled, setTimerDisabled] = React.useState(false);
  const [progress, setProgress] = useState(100);

  React.useEffect(() => {
    setMore(false);
    setAnchorEl(null);
    setShow(true);

    // if (!timerDisabled) {
    //   const timer = setInterval(() => {
    //     setProgress((prevProgress) => {
    //       if (prevProgress <= 0) {
    //         clearInterval(timer);
    //         setShow(false);
    //         return 0;
    //       }
    //       return prevProgress - (100 / 100); // Decrease by 1% every 100ms to complete in 10 seconds
    //     });
    //   }, 100);

    //   return () => {
    //     clearInterval(timer);
    //   };
    // }
  }, [props.text, timerDisabled]);

  const handleMoreClick = (event) => {
    setMore(true);
    setAnchorEl(event.currentTarget);
  };

  const handleBoxClick = () => {
    setTimerDisabled(true);
  };

  const handleClose = () => {
    setMore(false);
  };

  const open = Boolean(anchorEl);

  return (
    show && (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          borderRadius: 4,
          background: "#EEEAFF",
          p: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            width: "100%",
            height: 5,
          }}
        >
          <IconButton onClick={() => setShow(false)}>
            <Close sx={{ fontSize: 12, color: "#888", mr: 1 }} />
          </IconButton>
        </Box>

        <Typography
          variant="body2"
          align="left"
          sx={{ fontSize: 12, width: "100%" }}
        >
          <AutoAwesomeOutlined sx={{ fontSize: 12, color: "#888", mr: 1 }} />
          {stringWithLineBreaks.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              <span style={{ display: index === 0 ? "inline" : "none" }}>
                {line}
                {index === 0 && stringWithLineBreaks.split("\n").length > 1 && (
                  <span onClick={handleMoreClick} style={{ cursor: "pointer" }}>
                    ...{" "}
                    <span style={{ fontWeight: 900, color: "#111" }}>more</span>
                  </span>
                )}
              </span>
            </React.Fragment>
          ))}
        </Typography>

        <Popper
          open={more}
          anchorEl={anchorEl}
          placement="bottom-start"
          transition
          sx={{ zIndex: 2000 }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} timeout={350}>
              <Paper
                elevation={3}
                sx={{ p: 2, maxWidth: 400, overflowY: "auto", borderRadius: 4 }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <DescriptionOutlined
                          sx={{ fontSize: 14, color: "#666", mr: 0.5 }}
                        />
                        <Typography
                          variant="body2"
                          fontWeight={700}
                          align="left"
                          sx={{ fontSize: 14, color: "#999" }}
                        >
                          Summary
                        </Typography>
                      </Box>
                      <IconButton onClick={() => props.copySummary()}>
                        <ContentCopyOutlined
                          sx={{ fontSize: 12, color: "#888", mr: 1 }}
                        />
                      </IconButton>
                    </Box>
                    <Typography
                      variant="body2"
                      align="left"
                      sx={{ fontSize: 14 }}
                    >
                      <AutoAwesomeOutlined
                        sx={{ fontSize: 14, color: "#888", mr: 1 }}
                      />
                      {stringWithLineBreaks.split("\n").map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        mt: 1,
                      }}
                    >
                      <Button
                        onClick={handleClose}
                        size="small"
                        sx={{ color: "#888" }}
                      >
                        Close
                      </Button>
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    )
  );
}

const ExportCommentsList = (props) => {
  const { selectedComments, handleSelectedComments } = props;

  const onDragEnd = (result) => {
    if (!result.destination) return; // Dragged outside the droppable area

    const items = Array.from(selectedComments);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    handleSelectedComments(items);
  };

  return (
    <div>
      <CommentsList
        selectedComments={selectedComments}
        onDragEnd={onDragEnd}
        {...props}
      />
    </div>
  );
};

const CommentsList = ({
  commentData,
  selectedComments,
  onDragEnd,
  isMobile,
  removeComment,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="comments" direction="vertical">
        {(provided) => (
          <Box ref={provided.innerRef} {...provided.droppableProps}>
            {selectedComments
              ? selectedComments.map((selected, index) => (
                  <Draggable
                    key={selected.selectedId}
                    draggableId={selected.selectedId}
                    index={index}
                  >
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...(selectedComments.length < 2
                          ? {}
                          : provided.draggableProps)}
                        {...provided.dragHandleProps}
                        sx={{
                          p: isMobile ? 1 : 2,
                          background: "#fefefe",
                          borderRadius: 4,
                          top: "auto !important",
                          left: "auto !important",
                          right: "auto !important",
                          bottom: "auto !important",
                        }}
                      >
                        <DeselectPopover
                          removeComment={() => removeComment(selected)}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              position: "relative",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              width: "100%",
                              minWidth: "300px",
                              maxWidth: "600px",
                            }}
                          >
                            <Box sx={{ flexGrow: 1 }}>
                              <CommentComponent
                                isMobile={isMobile}
                                dragMode={selectedComments.length > 1}
                                comment={selected.comment}
                                removeComment={removeComment}
                                showAsReply={
                                  selected.comment.replyTo &&
                                  typeof selected.comment.replyTo == "string" &&
                                  selectedComments.some(
                                    (comment) =>
                                      comment.comment.id ==
                                      selected.comment.replyTo
                                  )
                                }
                              />

                              {/* {hover ? 
                          <div style={{position:"absolute", top:0, right:0, zIndex:10}}>
                            <IconButton onClick={() => removeComment(selectedId)}>
                              <Close />
                            </IconButton>
                          </div>
                          : null} */}
                            </Box>
                          </Box>
                        </DeselectPopover>
                        {/* 
                        {selectedComments.length > 1 &&
                        index < selectedComments.length - 1 ? (
                          <Divider
                            variant="middle"
                            sx={{ marginLeft: isMobile ? "40px" : "60px" }}
                          />
                        ) : null} */}
                      </Box>
                    )}
                  </Draggable>
                ))
              : null}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const GlassMorphicPaperComponent = React.forwardRef(
  function GlassMorphicPaperComponent(props, ref) {
    return (
      <Paper
        ref={ref}
        elevation={0}
        sx={{
          borderRadius: 8,
          background: "rgba(255, 255, 255, 0.2)",
          backdropFilter: "blur(12px)",
          // border: "1px solid rgba(255, 255, 255, 0.18)",
          // boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
          padding: "1em",
          margin: ".5em",
        }}
      >
        {props.children}
      </Paper>
    );
  }
);

const ExportCommentsDialog = (props) => {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={GlassMorphicPaperComponent}
      disableEnforceFocus
    >
      {props.children}
    </Dialog>
  );
};

function formatNumber(number) {
  if (isNaN(number)) {
    return "Invalid number";
  }
  if (number >= 1e9) {
    return (number / 1e9).toFixed(1).replace(/\.0$/, "") + "B";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(1).replace(/\.0$/, "") + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return number.toString();
  }
}

async function handleDownload(selectedComments, name) {
  const Functions = HelperFunctions();

  try {
    const url = await Functions.exportCommentImage(selectedComments);

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank"); // Open the link in a new tab
    link.setAttribute("rel", "noopener noreferrer"); // Add security attributes
    link.setAttribute("download", `${name}-comments.png`); // Set the filename for download

    // Simulate a click on the link to initiate download
    link.click();

    return url;
  } catch (error) {
    console.log("error", error);
  }
}

function SelectedCommentsMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    open,
    deselectAllComments,
    numSelected,
    goToExport,
    allSelected,
    selectAllComments,
    viewportWidth,
  } = props;

  React.useEffect(() => {
    setAnchorEl(null);
    //set anchorel to full window
    setAnchorEl(
      viewportWidth < 600
        ? document.body
        : document.getElementById("comment-section")
    );
  }, []);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverPosition = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div>
      {/* <div
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {props.children}
        </div>
   */}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableEnforceFocus
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: 1,
            background: "#111",
            ...popoverPosition,
            marginTop: "-20px",
          },
        }}
      >
        <Box
          sx={{
            pointerEvents: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          }}
        >
          <IconButton onClick={deselectAllComments}>
            <Close sx={{ color: "#fff" }} />
          </IconButton>
          <Typography
            variant="body1"
            sx={{ fontWeight: 700, color: "#fff" }}
          >{`${numSelected} selected`}</Typography>

          <Box sx={{ ml: viewportWidth < 500 ? 1 : 3 }} />

          {!allSelected ? (
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              sx={{ borderRadius: 12, position: "relative" }}
              onClick={selectAllComments}
            >
              Select all
            </Button>
          ) : null}

          {viewportWidth < 500 ? (
            <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
                  backgroundColor: '#fff', // Optional: background color for better contrast
                  color: '#000', // Optional: text color
                },
              }
            }}
              title={
                numSelected < 20
                  ? "Export Comments As Image"
                  : "Select less than 20 comments to export."
              }
            >
              <span>
                <IconButton
                  onClick={goToExport}
                  disabled={numSelected === 0 || numSelected > 20}
                  sx={{ color: "#fff", pl: 2, pr: 2 }}
                >
                  <IosShareOutlined />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip
            componentsProps={{
              tooltip: {
                sx: {
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
                  backgroundColor: '#fff', // Optional: background color for better contrast
                  color: '#000', // Optional: text color
                },
              }
            }}
              title={
                numSelected < 20
                  ? "Export Comments As Image"
                  : "Select less than 20 comments to export."
              }
            >
              <span>
                <Button
                  disabled={numSelected === 0 || numSelected > 20}
                  onClick={goToExport}
                  sx={{ color: "#fff", pl: 2, pr: 2 }}
                  endIcon={<IosShareOutlined />}
                >
                  Export{" "}
                </Button>
              </span>
            </Tooltip>
          )}
        </Box>
      </Popover>
    </div>
  );
}

function DeselectPopover(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleMouseLeave = () => {
    setAnchorEl(null);
  };

  const handleDoubleClick = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  return (
    <div
      onDoubleClick={handleDoubleClick}
      onClick={handlePopoverClose}
      onMouseLeave={handleMouseLeave}
    >
      {props.children}

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        onMouseLeave={handleMouseLeave}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            borderRadius: 10,
            padding: "0px 5px 0px 5px",
            background: "#fefefe",
          },
        }}
      >
        <Button
          color="error"
          size="small"
          onClick={props.removeComment}
          endIcon={<Close />}
        >
          Remove{" "}
        </Button>
      </Popover>
    </div>
  );
}

function ListComments(commentData) {
  let commentList = [];
  let replyMap = new Map();

  // First pass: create a map of comments and collect top-level comments
  Object.entries(commentData).forEach(([postId, comments]) => {
    comments.forEach((comment) => {
      const commentObj = { postId: String(postId), comment: comment };
      if (comment.replyTo && typeof comment.replyTo === "string") {
        if (!replyMap.has(comment.replyTo)) {
          replyMap.set(comment.replyTo, []);
        }
        replyMap.get(comment.replyTo).push(commentObj);
      } else {
        commentList.push(commentObj);
      }
    });
  });

  // Function to recursively add replies
  function addReplies(comment) {
    const result = comment;
    const replies = [];
    if (replyMap.has(comment.comment.id)) {
      replyMap.get(comment.comment.id).forEach((reply) => {
        replies.push(reply);
        if (replyMap.has(reply.comment.id)) {
          replies.push(addReplies(reply));
        }
      });
    }

    comment.comment.replies = replies;
    return comment;
  }

  commentList = commentList.flatMap((comment) => addReplies(comment));
  // Second pass: add replies in the correct order and remove duplicates
  return commentList
    .flatMap((comment) => comment.comment.replies ? [comment, ...comment.comment.replies] : comment)
    .filter(
      (comment, index, self) =>
        self.findIndex(
          (t) =>
            t.comment.id === comment.comment.id && t.postId === comment.postId
        ) === index
    );
}

const Topic = (props) => {
  const { highlight, searched, handleSearch } = props;

  return (
    <Box sx={{ display: "flex", gap: 0.5 }}>
      <ButtonBase
        onClick={async () => {
          searched ? handleSearch("") : handleSearch(highlight.trim());
        }}
        sx={{ borderRadius: 30, height: 40, width: "fit-content" }}
      >
        <Chip
          label={highlight.trim()}
          variant="outlined"
          sx={{
            fontSize: 11,
            height: "auto",
            "& .MuiChip-label": {
              display: "block",
              whiteSpace: "nowrap",
              padding: "8px 12px",
            },
            background: searched ? "#4828C5" : "#ffffff",
            color: searched ? "#fff" : "#000",
            borderRadius: 20,
          }}
        />
      </ButtonBase>
    </Box>
  );
};

const SearchAnalyticsBox = ({ search, handleSearch, selectedPostData }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        overflowX: "auto",
        position: "relative",
        mt: 2,
        "&::-webkit-scrollbar": {
          width: "0px",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
        },
      }}
    ></Box>
  );
};

const TopicBox = ({ search, handleSearch, selectedPostData, rows }) => {
  const sortedHighlights = (post) =>
    post.reports.length > 0
      ? post.reports
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))[0]
          .highlights.sort((a, b) => a.length - b.length)
      : [];

  const renderHighlights = (postDataSlice) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        gap: 1,
        height:
          postDataSlice.flatMap((post) => sortedHighlights(post)).length > 0
            ? 40
            : 0,
        width: "fit-content",
        overflowX: "auto",
        overflowY: "hidden",
        scrollSnapType: "x mandatory", // Enable scroll snapping
        WebkitOverflowScrolling: "touch", // Enable smooth scrolling on iOS
      }}
    >
      {postDataSlice.flatMap((post) =>
        sortedHighlights(post).map((highlight) => (
          <Topic
            key={highlight} // Make sure to add a unique key
            searched={search.trim() == highlight.trim()}
            handleSearch={handleSearch}
            highlight={highlight}
          />
        ))
      )}
    </Box>
  );

  let data = selectedPostData;
  const splitIndex = Math.ceil(data.length / 2);
  const firstHalf = data.slice(0, splitIndex);
  const secondHalf = data.slice(splitIndex);

  return (
    <Box
      sx={{
        width: "100%",
        height: "fit-content",
        overflowX: "auto",
        position: "relative",
        "&::-webkit-scrollbar": {
          width: "0px",
          display: "none",
        },
        "&::-webkit-scrollbar-track": {
          background: "transparent",
          display: "none",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "transparent",
          display: "none",
        },
      }}
    >
      {renderHighlights(firstHalf)}
      {rows > 1 && secondHalf.length > 0 && renderHighlights(secondHalf)}
    </Box>
  );
};

function SearchPopper(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    open,
    handleSetSearch,
    search,
    viewportWidth,
    searching,
    searchComments,
    commentSection,
    filters,
  } = props;

  React.useEffect(() => {
    setAnchorEl(null);
    //set anchorel to full window
    setAnchorEl(document.getElementById("comment-section"));
  }, []);

  const deleteSearch = () => {
    handleSetSearch("");
    if (commentSection && commentSection.current) {
      //scroll comment section to top
      commentSection.current.scrollTo(0, 0);
    }
  };

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const popoverPosition = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div>
      {/* <div
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          {props.children}
        </div>
   */}
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={search && search.trim() != "" ? open : false}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableEnforceFocus
        PaperProps={{
          sx: {
            mt: 3,
            background: "white",
            borderRadius: 10,
            ...popoverPosition,
            zIndex: 10,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-stretch",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexGrow: 1,
              }}
            >
              <Box sx={{ pl: 2, pr: 2 }}>
                <Search sx={{ color: "#888" }} />
              </Box>
              {filters.map((filter) => (
                <Chip
                  icon={filter.icon}
                  key={filter.value}
                  label={filter.label}
                  // onDelete={handleFilterDelete(filter)}
                  size="small"
                  sx={{ mr: 0.8 }}
                />
              ))}
              <Typography
                variant="body1"
                align="left"
                sx={{
                  minWidth: 200,
                  maxWidth: 300,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {search}
              </Typography>
              {search != "" ? (
                <IconButton
                  sx={{ pointerEvents: "auto" }}
                  onClick={deleteSearch}
                >
                  <Close />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}

const SearchWithFilter = React.memo((props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = React.useState("");
  const [openSearchPopper, setOpenSearchPopper] = React.useState(false);
  const [searching, setSearching] = React.useState(false);
  const searchButton = React.useRef(null);
  const {
    viewportWidth,
    viewportHeight,
    resetComments,
    searchBoxRef,
    searchFilters,
    setSearchFilters,
    filters,
    setFilters,
    handleSetSearch,
    copySummary,
    commentSection,
    searchComments,
    selectedPostData,
    reportData,
    filterSearchedComments,
    searchTerm,
    searched
  } = props;

  const isMobile = viewportWidth < 900;


  const [excludeCreator, setExcludeCreator] = useState(false);
  const [hideEmojiComments, setHideEmojiComments] = useState(false);
  const [onlyCreator, setOnlyCreator] = useState(false);

  React.useEffect(() => {
    setExcludeCreator( searchFilters.map((filter) => filter.value).includes("excludeCreator"));
    setHideEmojiComments( searchFilters.map((filter) => filter.value).includes("hideEmojiComments"));
    setOnlyCreator( searchFilters.map((filter) => filter.value).includes("onlyCreator"));
  }, [searchFilters]);


  React.useEffect(() => {
    if(searchTerm){
      setSearch(searchTerm);
    }
  }, [searchTerm]);

  const clickSearch = (event) => {
    if (event.key === "Enter") {
      if (searchButton.current) {
        searchButton.current.click();
      }
    }
  };

  React.useEffect(() => {
    //set enter key to search comments if search is not empty
    window.addEventListener("keydown", clickSearch);

    return () => {
      window.removeEventListener("keydown", clickSearch);
    };
  }, []);

  // React.useEffect(() => {
  //   //set enter key to search comments if search is not empty
  //   resetComments();

  // }, [filters]);

  const updateSearch = (search) => {
    const sanitizedValue = sanitizeInput(search);
    if (containsJSInjection(sanitizedValue)) {
      console.warn("Potential JavaScript injection detected.");
    } else {
      setSearch(sanitizedValue);
    }
  };

  const handleInputChange = (e) => {
    const term = e.target.value;
    if (term.includes("/")) {
      setAnchorEl(e.currentTarget);
    } else {
      if (term.length <= 99) {
        updateSearch(term);
      }
    }

    // If backspace is pressed, search is empty and there are filters
    if (
      e.nativeEvent.inputType === "deleteContentBackward" &&
      term === "" &&
      filters.length > 0
    ) {
      // Remove the last filter
      setFilters(filters.slice(0, -1));
    }
  };
  const handleFilterSelect = (selectedFilter) => {
    if (!filters.includes(selectedFilter)) {
      // setFilters([...filters, selectedFilter]);
      setFilters([selectedFilter]);
      // updateSearch("");
    }
    setAnchorEl(null);
  };

  const handleFilterDelete = (filterToDelete) => () => {
    setFilters(filters.filter((filter) => filter !== filterToDelete));
  };

  const handleClose = () => {
    setAnchorEl(null);
    // resetComments();
  };

  const marketingFilters = [
    {
      category: "marketing",
      value: "awareness",
      label: "Awareness",      
    icon:  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row",gap:1}}>
    <SpeakerWaveIcon style={{ height: 20, marginLeft:5 }} />
    <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
    </Box>,
      description: "Comments related to product or brand awareness.",
    },
    {
      category: "marketing",
      value: "evaluation",
      label: "Evaluation",
      icon:   <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap:1}}>
      <MagnifyingGlassPlusIcon style={{ height: 20, marginLeft:5 }} />
      <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
      </Box>,
      description: "Comments related to product or brand evaluation.",
    },
    {
      category: "marketing",
      value: "conversion",
      label: "Conversion",
      icon:  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap:1}}>
      <CurrencyDollarIcon style={{ height: 20, marginLeft:5 }} />
      <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
      </Box>,
      description: "Comments related to having purchased a product.",
    },
    {
      category: "marketing",
      value: "feedback",
      label: "Feedback",
      icon:   <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap:1}}>
      <InformationCircleIcon style={{ height: 20, marginLeft:5 }} />
      <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
      </Box>,
      description: "Comments giving feedback on a product or brand.",
    },
    {
      category: "marketing",
      value: "categorySupport",
      label: "Category Support",
      icon:  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap:1}}>
      <HandThumbUpIcon style={{ height: 20, marginLeft:5 }} />
      <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
      </Box>,
      description: "Comments showing interest or support for a product or brand category.",
    },
    

  ];

  const searchFilterOptions = [
    {
      category: "setting",
      value: "keyword",
      label: "Keyword/Mention",
      icon:  <MagnifyingGlassIcon style={{ height: 20, marginLeft:5 }} />,
      description: "Comments that contain an exact keyword or mention.",
    },
    {
      category: "type",
      value: "question",
      label: "Question",
      icon:   <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap:1}}>
      <QuestionMarkCircleIcon style={{ height: 20, marginLeft:5 }} />
      <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
      </Box>,
      description: "Any comments that contain questions.",
    },
    {
      category: "creator",
      value: "creatorSupport",
      label: "Support Towards Creator",
          icon:   <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row",gap:1}}>
          <FaceSmileIcon style={{ height: 20, marginLeft:5 }} />
          <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
          </Box>,
      description: "Any comments containing support towards the creator.",
    },
    { 
      category: "creator",
      value: "creatorCritique",
      label: "Criticism Towards Creator",
      icon:  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row", gap:1}}>
      <FaceFrownIcon style={{ height: 20, marginLeft:5 }} />
      <Chip label="Beta" size="small"  sx={{ fontSize:10, color: "#4727c3", background: "#edebff" }} />
      </Box>,
      description: "Any comments containing criticism towards the creator."
    },

    // {value: "request", label: "Requests", icon: <QuestionAnswerOutlined sx={{fontSize: 20, m:1}}/>, description: "Comments that request information or action."}
  ];

  const [filterAnchorEl, setFilterAnchorEl] = useState(null);


  const handleHideEmojiComments = async () => {
    const newHideEmojiComments = !hideEmojiComments;

    setHideEmojiComments(newHideEmojiComments);

    // Update the filters state
    if (newHideEmojiComments) {
      setSearchFilters([
        ...searchFilters,
        {
          value: "hideEmojiComments",
          label: "Hide Emoji Comments",
          icon: <SentimentNeutralOutlined sx={{ fontSize: 20, m: 1 }} />,
        },
      ]);
    } else {
      setSearchFilters(
        searchFilters.filter((filter) => filter.value !== "hideEmojiComments")
      );
    }

    return newHideEmojiComments;



  };


  const handleFilterClick = (event) => {
    setFilterAnchorEl(event.currentTarget);
  };

  const deleteSearch = async () => {
    await resetComments();
    updateSearch("");
  }


  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    // if (!excludeCreator && !onlyCreator) {
    //   resetComments();
    // }
  };

  const handleExcludeCreatorToggle = async() => {

    if(onlyCreator){
      await handleOnlyCreatorToggle();

    }


    const newExcludeCreator = !excludeCreator;

    setExcludeCreator(newExcludeCreator);

    // Update the filters state
    if (newExcludeCreator) {
      setSearchFilters([
        ...searchFilters.filter((filter) => filter.value !== "onlyCreator"),
        {
          value: "excludeCreator",
          label: "Exclude Creator",
          icon: <PersonOffOutlined sx={{ fontSize: 20, m: 1 }} />,
        },
      ]);
    } else {
      setSearchFilters(
        searchFilters.filter((filter) => filter.value !== "excludeCreator")
      );
    }

    setOnlyCreator(false); // Always set onlyCreator to false when toggling excludeCreator

    return newExcludeCreator;
  };

  const handleOnlyCreatorToggle = async() => {

    if(excludeCreator){
      await handleExcludeCreatorToggle();

    }

    

    const newOnlyCreator = !onlyCreator;

    setOnlyCreator(newOnlyCreator);

    // Update the filters state
    if (newOnlyCreator) {
      setSearchFilters([
        ...searchFilters.filter((filter) => filter.value !== "excludeCreator"),
        {
          value: "onlyCreator",
          label: "Only Creator",
          icon: <PersonOutlined sx={{ fontSize: 20, m: 1 }} />,
        },
      ]);
    } else {
      setSearchFilters(
        searchFilters.filter((filter) => filter.value !== "onlyCreator")
      );
    }

    setExcludeCreator(false);

    return newOnlyCreator;

  };

  React.useEffect(() => {
    filterSearchedComments();
  }, [searchFilters]);




  return (
    <>
      <MenuComponent
        anchorEl={anchorEl}
        handleClose={handleClose}
        searchFilterOptions={searchFilterOptions}
        marketingFilters={marketingFilters}
        handleFilterSelect={handleFilterSelect}
        filters={filters}
      />

      <Box
        ref={searchBoxRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            p:.5,
      
        
          }}
        >
          <SearchPopper
            filters={filters}
            open={openSearchPopper}
            search={search}
            searchComments={searchComments}
            handleSetSearch={updateSearch}
            commentSection={commentSection}
            searching={searching}
            searchButton={searchButton}
            viewportWidth={viewportWidth}
          />
    
          <TextField
          id="search-bar"
            variant="outlined"
            placeholder={getPlaceholderText(filters)}
            fullWidth
            size="small"
            value={search}
            inputProps={{
              maxLength: 100,
              style: { fontSize: 16 },
        
          
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {filters && filters.length > 0 ? (
                    <Box sx={{ display: "flex", gap: 0.5, mr:.5 }}>
                             <IconButton
                      // label={<BoltOutlined sx={{ fontSize: 16 }} />}
                      size="small"
                    
                      onClick={() => 
                        setAnchorEl(document.getElementById("search-bar"))
                      }
                    >
                        {/* <BoltOutlined sx={{ fontSize: 16, fill:filters.length > 0 ? "#4728c4" : "#333" }} /> */}

                        <BoltIcon style={{height:20, color:filters.length > 0 ? "#4728c4" : "#333", marginRight:5}} />

                     
                    </IconButton>
                    {filters.map((filter) => (
                      // <Chip
                      //   icon={filter.icon}
                      //   key={filter.value}
                      //   label={filter.label}
                      //   onDelete={handleFilterDelete(filter)}
                      //   size="small"
                      //   sx={{ mr: 0.5 }}
                      // />
                      <Chip
                      size="small"
                      sx={{ background:"#fff",   height:32, mr:.5,
                        color:"#888", border:"1px solid #ccc", borderRadius: 2, fontSize: 12, fontWeight: 700, color: "#333" }}
                        // icon={filter.icon}
                        key={filter.value}
                        label={filter.label}
                        onDelete={handleFilterDelete(filter)}

                    />
                    ))}
             
                    </Box>
                  ) : (
                    <Chip
                      label="Shortcuts"
                      size="small"
                      sx={{ background:"#fff",   height:32,
                        boxShadow: "0px 0px 6px #4728c4",
                                                color:"#888", 
                                                pt:.2,
                        // border:"1px solid #ccc", 
                        borderRadius: 2, fontSize: 12, fontWeight: 700, color: "#333", mr:1}}
                      icon={   <BoltIcon style={{height:16, color:"#333"}} />
                    }
                
                      onClick={() => 
                        setAnchorEl(document.getElementById("search-bar"))
                      }
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {searched || search.trim().length > 0 ?
                  <IconButton
                    onClick={deleteSearch}
                  >
                    <XMarkIcon style={{height:24, color:"#bbb"}} />
                  </IconButton>
                  : null}
                <IconButton
                  onClick={async () => {
                    setSearching(true);
                    let result = await searchComments(search);
                    setSearching(false);
                  }}
                  disabled={searching || search.length == 0 || (search.length == 0 && filters.length > 0)}
                  ref={searchButton}
                >
                  {searching ? (
                    <CircularProgress size={25} color="primary" />
                  ) : (
                    <PaperAirplaneIcon style={{height:24, color:  search.length == 0 || (search.length == 0 && filters.length > 0) ? "#ccc" : "#666"}} />

                  )}
                </IconButton>
                </Box>
              ),
            }}
            sx={{
              borderRadius: 3,
              background:"#F3F3F6",
              fontSize: 16,
              paddingY: .5, // This adds vertical padding
              "& .MuiInputBase-input::placeholder": {
                color: filters.length == 0 ? "#999" : "#999", // Darker color for placeholder
                fontWeight: filters.length == 0 ? 400 : 600, // Bold placeholder for filters
                opacity: 1,    // Ensure the placeholder is fully opaque
                fontSize: 14,
              
              },
              '& .MuiInputBase-input': {
                // textDecoration: searched ? "underline" : "none",
              },
            }}
            color={search.length > 99 ? "error" : "primary"}
            onInput={handleInputChange}
          />

        <Box sx={{ width:isMobile ? 50 : 100, m:1}}>
          <Badge
            badgeContent={searchFilters.length}
            color="primary"
            sx={{
              "& .MuiBadge-badge": {
                fontSize: "12px",
                height: "20px",
                width: "20px",
              },
            }}
          >
            {isMobile ? (

              <IconButton
              onClick={handleFilterClick}
              sx={{borderRadius:2, border:"1px solid #eee", height:45, width:45}}
              >
                <FunnelIcon style={{ height: 20, color: searchFilters.length > 0 ? "#4728c4" : "#333"}} />
              </IconButton>
            ): (
            <Chip
              onClick={handleFilterClick}
              sx={{borderRadius:2, 
                height:45,
                fontSize:16, fontWeight:600, background:"#fff", color:
                
                searchFilters.length > 0 ? "#4728c4" : "#333", border:"1px solid #eee"}}
              label="Filters"
              icon={<FunnelIcon style={{ height: 20, color: searchFilters.length > 0 ? "#4728c4" : "#333"}} />}
            />
            )}
          </Badge>
          </Box>
          <Popper
            open={Boolean(filterAnchorEl)}
            anchorEl={filterAnchorEl}
            placement="bottom-end"
            transition
            style={{ zIndex: 1000 }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: 4, boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.3)" }}>
                  <ClickAwayListener onClickAway={handleFilterClose}>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", gap: 1 }}
                    >

      <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection:"row", p:1, alignItems: "center", gap: 1 }}>
      <FunnelIcon style={{ height: 20 }} />

      <Typography variant="body1" sx={{ fontWeight: 600 }}>
      Quick Filters
      </Typography>
      
      <Tooltip
  title="See or hide comments and data based on these comment filters."
  arrow
  componentsProps={{
    tooltip: {
      sx: {
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
        backgroundColor: '#fff', // Optional: background color for better contrast
        color: '#000', // Optional: text color
      },
    },
    // arrow: {
    //   sx: {
    //     color: '#fff', // Matches the tooltip background color for consistency
    //     boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds shadow to the arrow
    //   },
    // },
  }}
>        <QuestionMarkCircleIcon style={{ height: 16, ml: 1 }} />
        </Tooltip>

        <Box sx={{flexGrow:1}}/>
      
      {searchFilters.length > 0 && (
        <Button
          startIcon={<XMarkIcon style={{ height: 16 }} />}
          size="small"
          onClick={() => {
            setSearchFilters([]);
          }
        }
          sx={{ color: "#666" }}
        >Clear</Button>
      )}
      </Box>

      <Divider variant="middle" />
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "0.8rem",
                            fontWeight: 600,
                            color: "#333",
                          },
                        }}
                        control={
                          <Checkbox
                            checked={excludeCreator}
                            onChange={handleExcludeCreatorToggle}
                          />
                        }
                        label={
                          <Box sx={{ display: "flex", alignItems: "center", gap:1 }}>
                            <Chip
                              label="Exclude"
                              size="small"
                              sx={{ mr: 1,  background:excludeCreator ? "#eeeaff" :"#f7f8fa"}}
                              icon={
                                <HideSourceOutlined sx={{ fontSize: 16 }} />
                              }
                            />
                            <Typography variant="body2">
                              Comments by Creator
                            </Typography>
                            <Tooltip
                              title="Remove creator comments."
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
                                    backgroundColor: '#fff', // Optional: background color for better contrast
                                    color: '#000', // Optional: text color
                                  },
                                }
                              }}
                            >
                                    <QuestionMarkCircleIcon style={{ height: 16 }} />

                            </Tooltip>
                          </Box>
                        }
                      />
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "0.8rem",
                            fontWeight: 600,
                            color: "#333",
                          },
                        }}
                        control={
                          <Checkbox
                            checked={onlyCreator}
                            onChange={handleOnlyCreatorToggle}
                          />
                        }
                        label={
                          <Box sx={{ display: "flex", alignItems: "center", gap:1 }}>
                            <Chip
                              label="Include Only"
                              size="small"
                              sx={{ mr: 1, background:onlyCreator ? "#eeeaff" :"#f7f8fa"}}
                              icon={
                                <CheckCircleOutlineOutlined
                                  sx={{ fontSize: 16 }}
                                />
                              }
                            />
                            <Typography variant="body2">
                              Comments by Creator
                            </Typography>
                            <Tooltip
                              title="Only include creator comments."
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
                                    backgroundColor: '#fff', // Optional: background color for better contrast
                                    color: '#000', // Optional: text color
                                  },
                                }
                              }}
                            >
                                                                <QuestionMarkCircleIcon style={{ height: 16 }} />

                            </Tooltip>
                          </Box>
                        }
                      />
                      <FormControlLabel
                        sx={{
                          "& .MuiFormControlLabel-label": {
                            fontSize: "0.8rem",
                            fontWeight: 600,
                            color: "#333",
                          },
                        }}
                        control={
                          <Checkbox
                            checked={hideEmojiComments}
                            onChange={handleHideEmojiComments}
                          />
                        }
                        label={
                          <Box sx={{ display: "flex", alignItems: "center", gap:1 }}>
                            <Chip
                              label="Exclude"
                              size="small"
                              sx={{ mr: 1, background:hideEmojiComments ? "#eeeaff" :"#f7f8fa"}}
                              icon={
                                <HideSourceOutlined sx={{ fontSize: 16 }} />
                              }
                            />
                            <Typography variant="body2">
                              Emoji-ONLY comments
                            </Typography>
                            <Tooltip
                              title="Remove comments that ONLY contain emojis."
                              arrow
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
                                    backgroundColor: '#fff', // Optional: background color for better contrast
                                    color: '#000', // Optional: text color
                                  },
                                }
                              }}
                            >
                                                                 <QuestionMarkCircleIcon style={{ height: 16 }} />

                            </Tooltip>
                          </Box>
                        }
                      />
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>


{/* 
        {viewportHeight > 1000 &&
        !isMobile &&
        selectedPostData.length == 1 &&
        search.trim() == "" &&
        reportData ? (
          <Box
            sx={{
              display: "flex",
              cursor: "pointer",
            }}
            // onClick={() => {
            //   copySummary(reportData.summary);
            // }}
          >
            <MainSummary
              text={reportData.summary}
              copySummary={() => copySummary(reportData.summary)}
            />
          </Box>
        ) : null} */}
      </Box>
    </>
  );
});

const MenuComponent = ({
  anchorEl,
  handleClose,
  searchFilterOptions,
  marketingFilters,
  handleFilterSelect,
  filters,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Assuming searchFilters and marketingFilters now include a category property
  const groupedOptions = [
    ...searchFilterOptions.filter((filter) => !filters.includes(filter.value)),
    ...marketingFilters.filter((filter) => !filters.includes(filter.value)),
  ].reduce((acc, option) => {
    const category = option.category || "other"; // Default category
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(option);
    return acc;
  }, {});

  const allOptionsWithCategories = Object.entries(groupedOptions).flatMap(
    ([category, options]) => [{ type: "category", label: category }, ...options]
  );

  const handleKeyDown = (event) => {
    // if (event.key === 'ArrowDown') {
    //   setSelectedIndex((prevIndex) => (prevIndex === null ? 0 : Math.min(prevIndex + 1, allOptionsWithCategories.length - 1)));
    //   event.preventDefault();
    // } else if (event.key === 'ArrowUp') {
    //   setSelectedIndex((prevIndex) => (prevIndex === null ? allOptionsWithCategories.length - 1 : Math.max(prevIndex - 1, 0)));
    //   event.preventDefault();
    // }
    // else if (event.key === 'Enter') {
    //   handleSelectFilter();
    //   event.preventDefault();
    // }
    // else if (event.key === 'Enter' && selectedIndex !== null && allOptionsWithCategories[selectedIndex].type !== 'category') {
    //   handleSelectFilter();
    //   event.preventDefault();
    // }
  };
  const handleSelectFilter = React.useCallback(() => {
    const selectedOption = allOptionsWithCategories[selectedIndex];
    if (selectedOption && selectedOption.type !== "category") {
      handleFilterSelect(selectedOption);
      handleClose();
    }
  }, [selectedIndex, allOptionsWithCategories]);

  React.useEffect(() => {
    if (Boolean(anchorEl)) {
      setSelectedIndex(null); // Reset selection when menu opens
      window.addEventListener("keydown", handleKeyDown);
    } else {
      window.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [anchorEl, allOptionsWithCategories.length]);

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}
    PaperProps={{
      sx: {
        // width: 300,
        borderRadius: 4,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.5)",
        },
    }}>
      <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection:"row", p:1, alignItems: "center", gap: 1, m:1, }}>
      {/* <BoltOutlined sx={{ fontSize: 20, mr: 1 }} /> */}
      <BoltIcon style={{height:20, color:"#333", marginRight:5}} />
      <Typography variant="body1" sx={{ fontWeight: 600 }}>
        Search Shortcuts 
       
      </Typography>
      
      <Tooltip
          componentsProps={{
            tooltip: {
              sx: {
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
                backgroundColor: '#fff', // Optional: background color for better contrast
                color: '#000', // Optional: text color
              },
            }
          }}
      title="Use these Siftsy search shortcuts to quickly analyze comments." placement="right" arrow>
          <QuestionMarkCircleIcon style={{ height: 16, marginLeft: 5 }} />
        </Tooltip>
      </Box>

    
      
      <Divider variant="middle" />
      {allOptionsWithCategories.map((option, index) =>
        option.type === "category" ? (
          <ListSubheader key={option.label} style={{ pointerEvents: "none" }}>
            {option.label == "type"
              ? "Comment Type"
              : option.label == "marketing" ? "Marketing Phase" :
              option.label == "creator" ? "Creator Sentiment"
              : option.label == "setting" ? "Search for" 
              : "Filter" 
            }
          </ListSubheader>
        ) : (
          <MenuItem
            key={option.value}
            onClick={() => handleFilterSelect(option)}
            style={{
              backgroundColor: index === selectedIndex ? "#eee" : "transparent",
            }}
          >
            <Tooltip     componentsProps={{
                                tooltip: {
                                  sx: {
                                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)', // Adds a shadow to the tooltip
                                    backgroundColor: '#fff', // Optional: background color for better contrast
                                    color: '#000', // Optional: text color
                                  },
                                }
                              }}
                              title={option.description} placement="right" arrow>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  m:1,
                  alignItems: "center",
                }}
              >
                {option.icon}
                <Typography variant="body1">{option.label}</Typography>
              </Box>
            </Tooltip>
          </MenuItem>
        )
      )}
    </Menu>
  );
};

// Helper function to get placeholder text based on filter
const getPlaceholderText = (filters) => {
  if (filters.length === 0) {
    return "Explore what commenters talked about...";
  }

  // Get the first filter (since we're currently only allowing one filter at a time)
  const filter = filters[0];

  switch (filter.value) {
    case 'awareness':
      return "Type in a brand or product to see if people are talking about it...";
    case 'evaluation':
      return "Find comments evaluating products or features...";
    case 'conversion':
      return "Search for comments about purchases or buying intent...";
    case 'feedback':
      return "Look for product feedback and suggestions...";
    case 'categorySupport':
      return "Find comments showing interest in product categories...";
    case 'keyword':
      return "Enter an exact keyword or phrase to search...";
    case 'question':
      return "Search through questions asked in comments...";
    case 'creatorSupport':
      return "Find supportive comments towards the creator...";
    case 'creatorCritique':
      return "Search for critical feedback about the creator...";
    default:
      return "Specify your search...";
  }
};
