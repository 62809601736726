import {
  Box,
  Grid,
  Typography,
  Slider,
  Card,
  createTheme,
  ThemeProvider,
  ButtonBase,
  AppBar,
  Divider,
  Skeleton,
  Tabs,
  Tab,
  CardContent,
  AvatarGroup,
  Avatar,
  Button,
  Popper,
  ClickAwayListener,
  Paper,
  MenuItem,
  IconButton,
  MenuList,
  FormControl,
  Select,
  Chip,
  LinearProgress,
  CircularProgress,
  Tooltip,
  Badge,
  TextField,
  SpeedDialIcon,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Popover,
  Snackbar,
  Alert,
  Breadcrumbs,
  DialogContentText,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  Toolbar,
  Checkbox,
  Switch,
  ToggleButtonGroup,
  ToggleButton,
  Fab,
  Link,
  Collapse,
  Grow,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { emphasize } from "@mui/material/styles";
import { styled as muiStyled } from "@mui/material/styles";
import {
  AccountCircleOutlined,
  Add,
  AddToPhotosOutlined,
  AirplaneTicket,
  ArrowBack,
  ArrowBackIos,
  ArrowBackIosNewOutlined,
  ArrowForwardIos,
  BarChartOutlined,
  CalendarMonthOutlined,
  CalendarTodayOutlined,
  CampaignOutlined,
  ChatBubbleOutlineOutlined,
  Check,
  CheckCircleOutlineOutlined,
  Clear,
  ClearAll,
  Close,
  CloseOutlined,
  CloseRounded,
  CommentBankOutlined,
  CommentOutlined,
  CompareArrowsOutlined,
  CompareOutlined,
  CopyAllOutlined,
  DeleteForeverOutlined,
  DoneAll,
  Edit,
  EditAttributesOutlined,
  EditNote,
  FeedOutlined,
  FileCopy,
  Filter,
  FilterList,
  FilterListOffOutlined,
  Folder,
  FolderCopy,
  ForumOutlined,
  Group,
  GroupOutlined,
  Info,
  InsertCommentOutlined,
  LabelOutlined,
  LocalOfferOutlined,
  ModeCommentOutlined,
  PhotoAlbumOutlined,
  PhotoLibrary,
  PlaylistAdd,
  PlaylistAddCheck,
  PostAdd,
  PostAddOutlined,
  Public,
  Redo,
  Refresh,
  Replay,
  Reply,
  ReplySharp,
  Restore,
  Save,
  SaveOutlined,
  SelectAll,
  Settings,
  SwitchAccountOutlined,
  TagOutlined,
  TimelineOutlined,
  VideoFileOutlined,
  VideoLibrary,
  VideoLibraryOutlined,
  VideoSettingsOutlined,
  ArchiveOutlined,
} from "@mui/icons-material";
import { siftsyTheme } from "./siftsytheme";
import { useParams, useLocation } from "react-router-dom";
import { useUser, UserButton } from "@clerk/clerk-react";
import { v4 as uuidv4 } from "uuid";
import HelperFunctions from "./HelperFunctions";
import GroupView from "./GroupView";
import {
  formatData,
  formatNumber,
  formatPlatform,
  UTCtoLocal,
  formatFullDate,
  icons,
} from "./Helpers";
import AddNewModal from "./AddNewModal";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import TeamSettingsModal from "./TeamSettingsModal";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { set } from "lodash";
import ProcessingPostsModal from "./ProcessingPostsModal";
import GroupAutoComplete from "./GroupAutocomplete";
import { isMobile } from "react-device-detect";
import { height } from "@mui/system";
import { CalendarDateRangeIcon, CalendarDaysIcon, Cog6ToothIcon, EyeSlashIcon, FunnelIcon, GlobeAltIcon, TagIcon, UserCircleIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import StripePlanPricing from "./StripePlanPricing";
import SuggestionsSection from "./SuggestionsSection";
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const darkMode = false;
const theme = createTheme(siftsyTheme);


export default function TeamView(props) {
  const { isLoaded, isSignedIn, user } = useUser();
  const { tid, pid } = useParams();
  const [teamData, setTeamData] = React.useState(null);
  const [allPostData, setAllPostData] = React.useState(null);
  const [empty, setEmpty] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [groupMatchType, setGroupMatchType] = React.useState("or");
  const [addNewOpen, setOpenAddNew] = React.useState(false);
  const [typeToAdd, setTypeToAdd] = React.useState("group");
  const [copied, setCopied] = React.useState(false);
  const [pageWidth, setPageWidth] = React.useState(window.innerWidth);
  const [viewportWidth, setViewportWidth] = React.useState(window.innerWidth);
  const [viewportHeight, setViewportHeight] = React.useState(
    window.innerHeight
  );
  const [openTeamSettings, setOpenTeamSettings] = React.useState(false);
  const [disableGroupChange, setDisableGroupChange] = React.useState(false);
  const [mobileTab, setMobileTab] = React.useState(0);
  const [filterName, setFilterName] = React.useState("");
  const [compareMode, setCompareMode] = React.useState(false);
  const filterNameRef = React.useRef(null);
  const [teamUsageData, setTeamUsageData] = React.useState(null);
  const [checkoutComplete, setCheckoutComplete] = React.useState(false);
  const [viewPlans, setViewPlans] = React.useState(false);
  const handleOpenTeamSettings = (open, viewPlans) => {
    setOpenTeamSettings(open);
    setViewPlans(viewPlans);
  };

  const isMobile = viewportWidth < 900;
  const Functions = HelperFunctions();

  React.useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    setCheckoutComplete(urlParams.get("checkout-complete"));
    setTimeout(() => {
      setCheckoutComplete(false);
    }
    , 10000);
  }, []);


  const [processingPostsOpen, setProcessingPostsOpen] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);

    };

    window.addEventListener("resize", handleResize);


    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const softRefresh = useCallback(async () => {
    let data = await Functions.getTeamData(tid);
    let pending = data.posts.filter((post) => post.status.includes("fetching") || post.status.includes("processing"));
    data.pendingPosts = pending;
    let archived = data.posts.filter((post) => post.status === "archived");
    data.archivedPosts = archived;

    data.posts = data.posts.filter((post) => post.status == "complete");

    // let posts = data.posts.filter((post) => post.status === selectedPostStatus);
    // data.posts = posts;
    setTeamData(data);
  }, [tid, Functions]);

  React.useEffect(() => {
    async function fetchData() {
      let startDate = new Date(2021, 0, 1);
      let endDate = new Date();
      let data = await Functions.getTeamUsageData(teamData.id, startDate, endDate);
      setTeamUsageData(data);
    }
    if (teamData) {
    fetchData();
    }
  },
  [teamData]);

  const isTrialPlan = teamData?.plan && teamData.plan.name.toLowerCase().includes("trial");
  
  const shouldShowDemoUpgradeMessage = teamData && teamUsageData && (
    !teamData.plan || 
    (isTrialPlan &&
      teamUsageData?.postsCompleted > teamData.plan.postCredits
    )
  );

  const shouldShowPlanUpgradeMessage = teamData && teamUsageData && (
    teamData.plan && teamData.plan.allowedMAAs && (
      teamUsageData?.MAAs && teamUsageData.MAAs > teamData.plan.allowedMAAs
    )
  );

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: "#f7f8fa",
          width: viewportWidth,
          height: "100vh",
          overflowX: "hidden",
        }}
      >

<Grow in={checkoutComplete} unmountOnExit>
  <Box>
  <CountdownProgress seconds={9} />
<Box sx={{ backgroundColor: '#cdbdef', p:2, textAlign: 'center', cursor:"pointer", display:"flex", flexDirection:"row", gap:1, justifyContent:"center"}}>
  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Your plan has been successfully upgraded!&nbsp;&nbsp;🎉
          </Typography>     
  <Link variant="body1" sx={{ fontWeight: 'bold' }}
        onClick={() => handleOpenTeamSettings(true, false)}>Click here to manage.
        </Link>
 
      </Box>
      </Box>
      </Grow>



{!checkoutComplete && shouldShowDemoUpgradeMessage &&
  <Grow in={true}>
    <Box sx={{ backgroundColor: '#cdbdef', p:2, textAlign: 'center', cursor:"pointer" }}>
      <Link variant="body1" sx={{ fontWeight: 'bold' }}
        onClick={() => handleOpenTeamSettings(true, true)}>
        Your demo has expired. Upgrade your plan to upload posts!
      </Link>
    </Box>
  </Grow>
}

        <AppBar
          position="static"
          elevation={0}
          // variant="outlined"
          sx={{
            background: "#fff",
            borderBottom: "1px solid #ddd",
            width: "100%",
          }}
          id="app-bar"
        >
          <Toolbar>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
                alignItems: "center",
                width: pageWidth > 1200 ? 1200 : pageWidth,
                // overflow:
                //   viewportWidth < 900 || viewportHeight < 1000
                //     ? "auto"
                //     : "hidden",
                margin: "auto",
                gap: { xs: 1, sm: 3 },
              }}
            >
              
              <Box sx={{ display: "flex" }}>
                <ButtonBase disabled onClick={() => window.open(`/`, "_blank")}>
                  <img
                    src={"/siftsy_logo.png"}
                    alt="logo"
                    id="logo"
                    style={{
                      width: viewportWidth < 900 ? 60 : 80,
                      height: "auto",
                      marginRight: "5px",
                    }}
                  />
                  
                  
                  {/* <Typography
                    color="primary"
                    align="center"
                    variant={"subtitle2"}
                    sx={{
                      borderRadius: 30,
                      background: "rgba(238,234,255, .8)",
                      color: "#4828C5",
                      padding: "1px 8px 1px 8px",
                      ml: -1,
                      fontWeight: 600,
                      fontSize: viewportWidth < 900 ? 10 : 12,
                    }}
                  >
                    beta{" "}
                  </Typography> */}
                </ButtonBase>
              </Box>

              <Box sx={{ flexGrow: 1 }} />

              {!compareMode &&
                teamData &&
                teamData.pendingPosts &&
                teamData.pendingPosts.length > 0 &&
                (viewportWidth > 900 ? (
                  <Chip
                    label={teamData.pendingPosts.length + " posts processing"}
                    icon={<Refresh sx={{ fontSize: 14 }} />}
                    sx={{
                      backgroundColor: "#edecfe",
                      color: "#4728c4",
                      fontWeight: 500,
                      fontSize: 12,
                      borderRadius: 2,

                    }}
                    onClick={() => setProcessingPostsOpen(true)}
                  />
                ) : (
                  <Chip
                    label={`${teamData.pendingPosts.length}`}
                    icon={<Refresh sx={{ fontSize: 14 }} />}
                    sx={{
                      backgroundColor: "#eec2f3",
                      color: "#4728c4",
                      fontWeight: 600,
                      fontSize: 10,
                      borderRadius: 2,
                    }}
                    onClick={() => setProcessingPostsOpen(true)}
                  />
                ))}

              {teamData && teamData.name ? (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onClick={() => handleOpenTeamSettings(true)}
                      sx={{
                        color: "#666",
                        background: "#fff",
                        textTransform: "none",
                        fontSize: 16,
                        fontWeight: 600,
                        padding: "4px 10px",
                      }}
                      // startIcon={<UserGroupIcon style={{height:20}}/>}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          color: "#666",
                          fontWeight: 600,
                          fontSize: { xs: 14, sm: 16 },
                          textOverflow: "ellipsis",
                          overflow: { xs: "hidden", sm: "visible" },
                          whiteSpace: { xs: "nowrap", sm: "normal" },
                          maxWidth: { xs: 140, sm: 500 },
                        }}
                      >
                        {teamData.name}
                      </Typography>
                    </Button>

                    <IconButton onClick={() => handleOpenTeamSettings(true)}>
                      <Cog6ToothIcon style={{height:20}}/>
                    </IconButton>

                    <TeamSettingsModal
                      open={openTeamSettings}
                      handleClose={() => handleOpenTeamSettings(false)}
                      teamData={teamData}
                      isMobile={isMobile}
                      softRefresh={softRefresh}
                      viewPlans={viewPlans}
                    />
                  </Box>
                </>
              ) : (
                <Skeleton
                  variant="text"
                  width={viewportWidth < 900 ? 140 : 200}
                  height={45}
                />
              )}

              {/* <Box>
                <ProfileSettingsButton
                  preferredFilter={preferredFilter}
                  savePreferredFilter={savePreferfredFilter}
                />
              </Box> */}
              <Box>
                <UserButton />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>


        <div
        style={{
          margin: 
          // compareMode
          //   ? compareIndex === 0
          //     ? "0 auto 0 0"
          //     : "0 0 0 auto"
          //   : 
            "0 auto",
          width: compareMode ? "50%" : pageWidth > 1200 ? 1200 : pageWidth,
          transition: "all 0.3s ease",
          overflow:
           viewportHeight < 1200 ? "auto" : "hidden",
          // float: compareMode ? (compareIndex === 0 ? "left" : "right") : "none",
          // padding: "0 10px", // Add padding to both sides
          // height: "calc(100vh)",
        }}
      >
        <SiftPage
          processingPostsOpen={processingPostsOpen}
          setProcessingPostsOpen={setProcessingPostsOpen}
          compareMode={compareMode}
          compareIndex={0}
          teamData={teamData}
          setTeamData={setTeamData}
          setCompareMode={setCompareMode}
          viewportWidth={viewportWidth}
          viewportHeight={viewportHeight}
          tid={tid}
          pid={pid}
      
        />

        </div>

        {/* {compareMode && (

            <SiftPage
            processingPostsOpen={processingPostsOpen}
            setProcessingPostsOpen={setProcessingPostsOpen}
            compareMode={compareMode}
            compareIndex={1}
              teamData={teamData}
              setTeamData={setTeamData}
              setCompareMode={setCompareMode}
              />

        )}
               */}
      </div>
    </ThemeProvider>
  );
}

function SiftPage(props) {
  const {
    teamData,
    setTeamData,
    compareMode,
    compareIndex,
    processingPostsOpen,
    setProcessingPostsOpen,
    setCompareMode,
    viewportHeight,
    viewportWidth,
    tid,
  } = props;

  const location = useLocation();
  const { pid } = useParams();
  const Functions = HelperFunctions();
  const { isLoaded, isSignedIn, user } = useUser();
  const [allPostData, setAllPostData] = React.useState(null);
  const [empty, setEmpty] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [groupMatchType, setGroupMatchType] = React.useState("or");
  const [addNewOpen, setOpenAddNew] = React.useState(false);
  const [typeToAdd, setTypeToAdd] = React.useState("group");
  const [selectedPostData, setSelectedPostData] = React.useState([]);
  const [copied, setCopied] = React.useState(false);
  const [openTeamSettings, setOpenTeamSettings] = React.useState(false);
  const [disableGroupChange, setDisableGroupChange] = React.useState(false);
  const [mobileTab, setMobileTab] = React.useState(0);
  const [filterName, setFilterName] = React.useState("");
  const filterNameRef = React.useRef(null);

  const navigate = useNavigate();

  const [selectedGroups, setSelectedGroups] = useState([]);
  const filterBar = React.useRef(null);
  const [filterPopper, setFilterPopper] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [groupView, setGroupView] = React.useState(
    viewportWidth < 900 ? "metrics" : "comments"
  );
  const [filterView, setFilterView] = React.useState("campaign");
  const [selectedPlatforms, setSelectedPlatforms] = React.useState([]);
  const [platformMatchType, setPlatformMatchType] = React.useState("or");
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [typeMatchType, setTypeMatchType] = React.useState("or");
  const [selectedAccounts, setSelectedAccounts] = React.useState([]);
  const [accountMatchType, setAccountMatchType] = React.useState("or");
  const [filterNamePopper, setFilterNamePopper] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState(null);
  const [selectedStartDate, setSelectedStartDate] = React.useState(null);
  const [selectedEndDate, setSelectedEndDate] = React.useState(null);
  const [selectedDates, setSelectedDates] = React.useState([]);
  const [dateFilter, setDateFilter] = React.useState([]);
  const addButton = React.useRef(null);
  const [selectedPostStatus, setSelectedPostStatus] = React.useState("complete");
  const [showArchivedModal, setShowArchivedModal] = useState(false); // Add this line


  const [openAddMenu, setOpenAddMenu] = React.useState(false);

  const handleSelectedFilter = (filter) => {
    setSelectedFilter(filter);

    if (filter) {
      setSelectedGroups(
        teamData.groups.filter((group) =>
          filter.settings.groups.includes(group.id)
        )
      );
      setSelectedTags(filter.settings.types);
      setSelectedAccounts(filter.settings.accounts);
      setSelectedPlatforms(filter.settings.platforms);
    }
  };


  const handleFilterNameChange = React.useCallback(
    (name) => {
      setFilterName(name);
    },
    [setFilterName]
  );

  const handleRemoveFilter = (filter) => {
    let data = teamData.data ? teamData.data : {};
    let filters = data.filters ? data.filters : [];
  };

  const handleFilterSave = () => {
    if (filterName.trim() == "") {
      return;
    }

    let data = teamData.data ? teamData.data : {};
    let filters = data.filters ? data.filters : [];

    let newFilter = {
      id: uuidv4(),
      name: filterName,
      settings: {
        groups: selectedGroups.map((group) => group.id),
        types: selectedTags,
        accounts: selectedAccounts,
        platforms: selectedPlatforms,
      },
    };

    filters.push(newFilter);

    data.filters = filters;

    let update = Functions.updateTeamData(tid, data);

    handleFilterNameChange("");

    softRefresh();
  };

  const handleSelectedPostData = useCallback(async(data) => {
    //randomize order of posts
    //get postdata in batch of 1000 for every post in data

    let postIds = data.map((post) => post.id);
    let reports = await Functions.getReportData(postIds);

    data.forEach((post) => {
      post.reports = reports[post.id];
    });

   

    setSelectedPostData( data.filter((post) => post.data && post.data.engagement && post.status === selectedPostStatus));
    // console.log("selected post data", data);
  }, [selectedPostStatus]);
  

  // React.useEffect(() => {

  //   refreshData();

  // }, [selectedGroups, groupMatchType]);

  const filterPosts = useCallback(
    async (teamData) => {
      let posts =
        teamData && teamData.posts
          ? JSON.parse(JSON.stringify(teamData.posts))
          : [];


          posts = posts.filter((post) => post.status === "complete");


          if (selectedGroups.length > 0) {
            posts = posts.filter((post) => {
              return groupMatchType == "and" 
                ? selectedGroups.every((group) => {
                    // If this is a campaign, check if post belongs to any of its subgroups
                    if (!group.type || group.type === "campaign") {
                      return post.groupIds ? post.groupIds.some(id => 
                        // Include posts that match campaign ID directly or any of its subgroup IDs
                        id === group.id || 
                        teamData.groups.some(g => g.campaignId === group.id && g.id === id)
                      ) : false;
                    }
                    // For regular groups, check direct membership
                    return post.groupIds ? post.groupIds.includes(group.id) : false;
                  })
                : selectedGroups.some((group) => {
                    // Same logic for OR condition
                    if (!group.type || group.type === "campaign") {
                      return post.groupIds ? post.groupIds.some(id =>
                        id === group.id ||
                        teamData.groups.some(g => g.campaignId === group.id && g.id === id)
                      ) : false;
                    }
                    return post.groupIds ? post.groupIds.includes(group.id) : false;
                  });
            });
          }

      if (selectedTags.length > 0) {
        //deep copy teamData.posts
        posts =
          typeMatchType == "and"
            ? posts.filter((post) =>
                selectedTags.every((type) =>
                  post.data.tags
                    ? JSON.parse(post.data.tags).includes(type)
                    : false
                )
              )
            : posts.filter((post) =>
                selectedTags.some((type) =>
                  post.data.tags
                    ? JSON.parse(post.data.tags).includes(type)
                    : false
                )
              );
      }

      if (selectedAccounts.length > 0) {
        //deep copy teamData.posts
        posts =
          accountMatchType == "and"
            ? posts.filter((post) =>
                selectedAccounts.every((account) =>
                  post.data.username ? post.data.username.replace("@", "") == account : false
                )
              )
            : posts.filter((post) =>
                selectedAccounts.some((account) =>
                  post.data.username ? post.data.username.replace("@", "") == account : false
                )
              );
      }

      if (selectedPlatforms.length > 0) {
        //deep copy teamData.posts
        posts =
          platformMatchType == "and"
            ? posts.filter((post) =>
                selectedPlatforms.every((platform) =>
                  post.data.platform ? post.data.platform == platform : false
                )
              )
            : posts.filter((post) =>
                selectedPlatforms.some((platform) =>
                  post.data.platform ? post.data.platform == platform : false
                )
              );
      }

      if (dateFilter.length > 0 && selectedDates.length > 0) {
        //find the posts in between and incluidng the selected dates, selectedDates[0] and selectedDates[1], start and end dates
        posts = posts.filter((post) => {
          let postDate = new Date(post.data.postedAt);
          return postDate >= selectedDates[0] && postDate <= selectedDates[1];
        });
      }

      return posts;
    },
    [
      dateFilter,
      selectedDates,
      selectedGroups,
      groupMatchType,
      selectedTags,
      typeMatchType,
      selectedAccounts,
      accountMatchType,
      selectedPlatforms,
      platformMatchType,
    ]
  );

  const resetPosts = useCallback(async () => {
    let posts = await filterPosts(teamData);

    if (pid) {
      let post = posts.find((post) => post.id == pid);
      if(post){
      let reports = await Functions.getReportData([post.id]);
      post.reports = reports[post.id];
      setSelectedPost(post);
      setSelectedPostData([post]);
      }
      else{
        setSelectedPost(null);
        handleSelectedPostData(posts);
      }
    } else {
      handleSelectedPostData(posts);
      setSelectedPost(null);
    }
  }, [teamData, selectedGroups, groupMatchType, pid]);

  const backToDash = useCallback(async () => {
    // setGroupView(viewportWidth < 900 ? "metrics" : "posts");
    navigate(`/team/${tid}`);
    // refreshData();
  }, [tid]);

  const forwardToPost = useCallback(
    (postId) => {
      navigate(`/team/${tid}/post/${postId}`);
      // let post = selectedPostData.find((post) => post.id == postId);
      // if (post) {
      //   setSelectedPost(post);
      //   setSelectedPostData([post]);
      // } else {
      //   setSelectedPost(null);
      //   resetPosts();
      // }
    },
    [
      navigate,
      tid,
      selectedPostData,
      setSelectedPost,
      setSelectedPostData,
      teamData,
    ]
  );

  const refreshData = useCallback(async () => {
    setLoading(true);

    let startTime = new Date().getTime();
    console.log("team data refreshing at time", startTime);

    let data = await Functions.getTeamData(tid);

    console.log("team data refreshed in", new Date().getTime() - startTime);

    console.log("selected post status", selectedPostStatus);

    if (data && data.posts) {
      let pending = data.posts.filter((post) => post.status.includes("fetching") || post.status.includes("processing"));//||  post.status.includes("failed"));
      data.pendingPosts = pending;
      let archived = data.posts.filter((post) => post.status == "archived");
      data.archivedPosts = archived;
      // data.posts = data.posts.filter((post) => post.status === selectedPostStatus);
      setTeamData(data);

      await resetPosts();

      if (initializing) {
        setInitializing(false);
      }
      if (empty) {
        setEmpty(false);
      }
    }

    setLoading(false);
  }, [
    Functions,
    tid,
    selectedPostData,
    groupMatchType,
    selectedGroups,
    selectedPost,
    handleSelectedPostData,
    pid,
    selectedAccounts,
    selectedPlatforms,
    selectedTags,
    typeMatchType,
    dateFilter,
    selectedDates,
    selectedPostStatus
  ]);

  const softRefresh = useCallback(async () => {
    let data = await Functions.getTeamData(tid);

    if (data && data.posts) {
      let pending = data.posts.filter((post) => post.status.includes("fetching") || post.status.includes("processing") ||  post.status.includes("failed"));
      data.pendingPosts = pending;
      let archived = data.posts.filter((post) => post.status == "archived");
      data.archivedPosts = archived;
      // data.posts = data.posts.filter((post) => post.status === selectedPostStatus);
      setTeamData(data);

      let posts = await filterPosts(data);
      resetPosts();
    }
  }, [tid, selectedPostData, groupMatchType, selectedGroups, pid, selectedPostStatus]);

  React.useEffect(() => {
    if (tid) {
      refreshData();
    }
    console.log("team data updated");
  }, [
    tid,
    selectedGroups,
    groupMatchType,
    selectedAccounts,
    selectedPlatforms,
    selectedTags,
    typeMatchType,
    dateFilter,
    selectedDates,
    selectedPostStatus
  ]);


  React.useEffect(() => {
    if(!pid)
    {
      setSelectedPost(null);
      resetPosts();
    }
    else if(teamData){
      resetPosts();
    }
  }, [pid, location.pathname, teamData]);

  const openAddNew = useCallback((type, open) => {
    setOpenAddNew(open);
    setTypeToAdd(type);
  }, [pid]);

  const isMobile = viewportWidth < 900;

  return (
    <>
      {!compareMode && (
        <ProcessingPostsModal
          softRefresh={softRefresh}
          refreshData={refreshData}
          open={processingPostsOpen}
          handleClose={() => setProcessingPostsOpen(false)}
          teamData={teamData}
          forwardToPost={forwardToPost}
        />
      )}

      {!compareMode && viewportWidth < 600 && (
        <Fab
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 16,
            left: 16,
            zIndex: 1000,
            background: "#4728c4",
          }}
          onClick={() => openAddNew("campaign", true)}
        >
          <Add sx={{ height: 18, color: "#fff" }} /> 
        </Fab>
      )}

      {!compareMode ? (
        <AddNewModal
          tid={tid}
          setSelectedTags={setSelectedTags}
          selectedTags={selectedTags}
          open={addNewOpen}
          handleClose={() => openAddNew(typeToAdd, false)}
          type={typeToAdd}
          teamData={teamData}
          setSelectedGroups={setSelectedGroups}
          refreshData={refreshData}
          selectedGroups={selectedGroups}
          isMobile={isMobile}
          softRefresh={softRefresh}
          groupMatchType={groupMatchType}
          setGroupMatchType={setGroupMatchType}
          // handleGroupChange={handleGroupChange}
          setProcessingPostsOpen={setProcessingPostsOpen}
        />
      ) : null}

        <div style={{ padding: compareMode ? "20px" : "0px" }}>
          <Snackbar
            open={copied}
            autoHideDuration={3000}
            onClose={() => setCopied(false)}
          >
            <Alert onClose={() => setCopied(false)} severity="success">
              <Typography variant="body1" align="center">
                Copied!
              </Typography>
            </Alert>
          </Snackbar>

          {teamData ? (
            <Box sx={{ p: isMobile ? .5 : 2,}}>
              {!selectedPost && (
                <FilterBar
                  compareMode={compareMode}
                  setCompareMode={setCompareMode}
                  openAddNew={openAddNew}
                  tid={tid}
                  selectedPost={selectedPost}
                  teamData={teamData}
                  selectedGroups={selectedGroups}
                  setSelectedGroups={setSelectedGroups}
                  groupMatchType={groupMatchType}
                  setGroupMatchType={setGroupMatchType}
                  selectedTags={selectedTags}
                  setSelectedTags={setSelectedTags}
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                  selectedPlatforms={selectedPlatforms}
                  setSelectedPlatforms={setSelectedPlatforms}
                  platformMatchType={platformMatchType}
                  setPlatformMatchType={setPlatformMatchType}
                  typeMatchType={typeMatchType}
                  setTypeMatchType={setTypeMatchType}
                  dateFilter={dateFilter}
                  setDateFilter={setDateFilter}
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                  filterName={filterName}
                  setFilterName={setFilterName}
                  filterNameRef={filterNameRef}
                  filterPopper={filterPopper}
                  setFilterPopper={setFilterPopper}
                  handleFilterSave={handleFilterSave}
                  handleSelectedFilter={handleSelectedFilter}
                  selectedFilter={selectedFilter}
                  handleRemoveFilter={handleRemoveFilter}
                  viewportWidth={viewportWidth}
                  setDisableGroupChange={setDisableGroupChange}
                  disableGroupChange={disableGroupChange}
                  softRefresh={softRefresh}
                  selectedPostData={selectedPostData}
                  setShowArchivedModal={setShowArchivedModal}
                  groupView={groupView}
                />
              )}

              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12}>

                  {selectedPost && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 2,
                        p: 1.5,
                        background: "white",
                        borderRadius: 2,
                        border: "1px solid #ddd",
                        cursor: "pointer",
                      }}
                      // onClick={() => {
                      //   setFilterPopper(true);
                      // }}
                    >
                      {/* <FilterList sx={{ color: "#62676a", fontSize: 20 }} /> */}

                      <Button
                        startIcon={
                          <ArrowBackIos sx={{ color: "#666", width: 14 }} />
                        }
                        onClick={() => backToDash()}
                        sx={{
                          borderRadius: 30,
                          border: "1px solid #ddd",
                          padding: "4px 10px",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            color: "#666",
                            fontWeight: 500,
                            fontSize: 14,
                            textTransform: "none",
                          }}
                        >
                          Back
                        </Typography>
                      </Button>

                      <Chip
                        sx={{
                          backgroundColor: "#fff",
                          border: `.5px solid #b3b3b3`,
                          borderRadius: 1.5,
                          height: 45,
                          borderRadius: 2,
                          overflow: "hidden",
                          textOverflow: "ellipsis",

                        }}
                        label={
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 1,

                            }}
                          >
                            <ChatBubbleOutlineOutlined
                              sx={{ color: "#62676a", fontSize: 20 }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 0.5,
                              }}
                            >
                              <Chip
                              size="small"
                                label={`${
                                  selectedPost.data.creatorName
                                } on ${formatData(
                                  selectedPost.data.platform
                                )} · ${formatFullDate(
                                  selectedPost.data.postedAt
                                )}`}
                                sx={{
                                  backgroundColor: "#5C6078",
                                  color: "white",
                                  fontWeight: 500,
                                  fontSize: 12,
                                  borderRadius: 2,
                                  // border: "1px solid #aaa",
                                }}
                              />
                            </Box>
                          </Box>
                        }
                      />

                      <Box sx={{ flexGrow: 1 }} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          ) : null}


          

          {!empty ? (
            <GroupView
              showArchivedModal={showArchivedModal}
              setShowArchivedModal={setShowArchivedModal}
              selectedPostStatus={selectedPostStatus}
              setSelectedPostStatus={setSelectedPostStatus}
              selectedTags={selectedTags}
              selectedAccounts={selectedAccounts}
              selectedPost={selectedPost}
              handleSelectedPostData={handleSelectedPostData}
              selectedPostData={selectedPostData}
              teamData={teamData}
              viewportHeight={viewportHeight}
              viewportWidth={viewportWidth}
              resetPosts={resetPosts}
              refreshData={refreshData}
              loading={loading}
              backToDash={backToDash}
              groupView={groupView}
              setGroupView={setGroupView}
              selectedGroups={selectedGroups}
              forwardToPost={forwardToPost}
              mobileTab={mobileTab}
              softRefresh={softRefresh}
              openAddNew={openAddNew}
            />
          ) : loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1em",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                m: 4,
              }}
            >
              <Grid container spacing={4}>
                <Grid item xs={12} sx={{ height: 120 }}>
                  <Skeleton
                    variant="rectangular"
                    height={100}
                    sx={{ borderRadius: 4 }}
                    animation="wave"
                  />
                </Grid>

                <Grid item xs={12} md={4} sx={{ height: 420 }}>
                  <Skeleton
                    variant="rectangular"
                    height={400}
                    sx={{ borderRadius: 4 }}
                    animation="wave"
                  />
                </Grid>
                <Grid item xs={12} md={8} sx={{ height: 1340 }}>
                  <Skeleton
                    variant="rectangular"
                    height={300}
                    sx={{ borderRadius: 4 }}
                    animation="wave"
                  />
                  <br />
                  <Skeleton
                    variant="rectangular"
                    height={800}
                    sx={{ borderRadius: 4 }}
                    animation="wave"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                mt: 10,
              }}
            >
              <Typography variant="h6" align="center">
                {" "}
                This page is not available.
              </Typography>

              <Card
                elevation={0}
                sx={{
                  borderRadius: "12px",
                  background: "#EEEAFF",
                  p: 3,
                  m: 2,
                }}
              >
                <Typography variant="body1">
                  Please make sure you are signed in to the correct account and
                  have access to this page.
                </Typography>
              </Card>
            </Box>
          )}
        </div>

    </>
  );
}

const StyledBreadcrumb = muiStyled(Chip)(({ theme }) => {
  const backgroundColor =
    theme.palette.mode === "light"
      ? theme.palette.grey[100]
      : theme.palette.grey[800];
  return {
    backgroundColor: "#fff",
    border: `1px solid ${theme.palette.divider}`,
    //max 16, min 12 for font size
    borderRadius: 30,
    fontSize: theme.typography.pxToRem(14),
    height: theme.spacing(3),
    color: "#999",
    fontWeight: 600,
    padding: "20px 10px",
    "&:hover, &:focus": {
      backgroundColor: emphasize(backgroundColor, 0.06),
      padding: "20px 10px",
      borderRadius: 30,
    },
    // '&:active': {
    //   boxShadow: theme.shadows[1],
    //   backgroundColor: emphasize(backgroundColor, 0.12),
    //   color: theme.palette.text.primary,
    // },
  };
});


const CustomizedBreadcrumb = React.memo(function CustomizedBreadcrumb(props) {
  const { data, type, labelFields, startLabel, handleAction } = props;

  const [showOptions, setShowOptions] = React.useState(true);


  let label = (index) => {
    let labelString = startLabel ? startLabel + ": " : "";

    for (let i = 0; i < labelFields.split(",").length; i++) {
      let labelField = labelFields.split(",")[i];
      let label = null;
      let length = labelField.split(".").length;

      if (length > 1) {
        label = data[index];
        for (let i = 0; i < length; i++) {
          // console.log("label", label);
          label = label[labelField.split(".")[i]];
        }
      } else {
        label = data[index][labelField];
      }
      labelString +=
        formatData(label) + `${i < labelFields.length - 1 ? " · " : ""}`;
    }
    return labelString;
  };


  return data && data.length > 0 ? (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: ".5rem",
          position: "relative",
        }}
      >
        <StyledBreadcrumb
          icon={icons[type]}
          // label={label(selectedAsset)}
          onClick={handleAction}
          // deleteIcon={<ExpandMoreOutlined />}
          // onDelete={() => setShowOptions(!showOptions)}
        />

        {showOptions ? (
          <ClickAwayListener onClickAway={() => setShowOptions(false)}>
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                left: 0,
                width: "fit-content",
                zIndex: 10,
                overflow: "hidden",
              }}
            >
              <Paper elevation={3}>
                {/* {data.map((asset) => (
                  <MenuItem
                    onClick={() => {
                      updateSelectedAssets(type, data.indexOf(asset));
                      setShowOptions(false);
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 14, fontWeight: 700, color: "#888" }}
                      >
                      {label(data.indexOf(asset))}
                    </Typography>
                  </MenuItem>
                ))} */}
                {/* {addNew ? (
                  <Button
                    startIcon={<Add />}
                    onClick={() => openAddNew(type, true)}
                    sx={{
                      width: "100%",
                      borderRadius: 0,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    Add New
                  </Button>
                ) : null} */}
              </Paper>
            </Box>
          </ClickAwayListener>
        ) : null}
      </Box>
    </>
  ) : null;
});

{
  /* Tree view for teamdata team, groups, posts, and reports */
}

{
  /* <SimpleTreeView
    sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '10px', // Adjust the gap between items as needed
    }}
      // defaultExpanded={['team']}
      // defaultSelected={['team']}
      // sx={{ height: 400, flexGrow: 1, maxWidth: 400, overflow: 'auto' }}
    >
      {teamData && teamData.length > 0 ? 

      teamData.map((team) => 
      <TreeItem itemId={team.id} label={team.name}>
        {team.groups.map((group) => (
          <TreeItem itemId={group.id} label={group.name}>
            {group.posts.map((post) => (
              <TreeItem itemId={post.id} label={`${formatPlatform(post.data.platform)}: @${post.data.username} on ${UTCtoLocal(post.data.postedAt)}`}>
                {post.reports.map((report) => (
                  <TreeItem itemId={report.id} label={`Sift - ${UTCtoLocal(report.createdAt)}`} />
                ))}
              </TreeItem>
            ))}
          </TreeItem>
        ))}
      </TreeItem>
      )
      : null}

    </SimpleTreeView> */
}

function FilterBar(props) {
  const {
    tid,
    teamData,
    filterNameRef,
    softRefresh,
    setSoftRefresh,
    openAddNew,
    viewportWidth,
    setDisableGroupChange,
    disableGroupChange,
    selectedPost,
    compareMode,
    setCompareMode,
    selectedPostData,
    setShowArchivedModal,
    groupView
  } = props;

  const [customDateDialogOpen, setCustomDateDialogOpen] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);

  const handleCustomDateSelect = () => {
    setCustomDateDialogOpen(true);
  };

  const handleCustomDateConfirm = () => {
    if (customStartDate && customEndDate) {
      setSelectedDates([customStartDate, customEndDate]);
      setDateFilter([{
        label: `${formatFullDate(customStartDate)} - ${formatFullDate(customEndDate)}`,
        type: "custom",
        id: "custom",
        startDate: customStartDate,
        endDate: customEndDate
      }]);
      props.setSelectedDates([customStartDate, customEndDate]);
      props.setDateFilter([{
        label: `${formatFullDate(customStartDate)} - ${formatFullDate(customEndDate)}`,
        type: "custom",
        id: "custom",
        startDate: customStartDate,
        endDate: customEndDate
      }]);
    }
    setCustomDateDialogOpen(false);
  };


  const [filterPopper, setFilterPopper] = React.useState(false);
  const filterBar = React.useRef(null);

  const Functions = HelperFunctions();

  const [highlightGroupField, setHighlightGroupField] = React.useState(false);
  const [highlightTagField, setHighlightTagField] = React.useState(false);

  const [selectedAccounts, setSelectedAccounts] = React.useState(
    props.selectedAccounts
  );
  const [selectedGroups, setSelectedGroups] = React.useState(
    props.selectedGroups
  );
  const [selectedTags, setSelectedTags] = React.useState(props.selectedTags);
  const [selectedPlatforms, setSelectedPlatforms] = React.useState(
    props.selectedPlatforms
  );
  const [dateFilter, setDateFilter] = React.useState(props.dateFilter);
  const [selectedDates, setSelectedDates] = React.useState(props.selectedDates);
  const [groupMatchType, setGroupMatchType] = React.useState(
    props.groupMatchType
  );
  const [typeMatchType, setTypeMatchType] = React.useState(props.typeMatchType);
  const addButton = React.useRef(null);
  const [openAddMenu, setOpenAddMenu] = React.useState(false);

  const tagAutocompleteRef = React.useRef(null);
  const tagExists = (tag) => {
    return (
      teamData &&
      teamData.posts
        .map((post) => JSON.parse(post.data.tags))
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index)
        .some((t) => t.trim().toUpperCase() == tag.trim().toUpperCase())
    );
  };

  // React.useEffect(() => {
  //   setSelectedGroups(props.selectedGroups);
  //   setSelectedTags(props.selectedTags);
  //   setSelectedAccounts(props.selectedAccounts);
  //   setSelectedPlatforms(props.selectedPlatforms);
  //   setDateFilter(props.dateFilter);
  //   setSelectedDates(props.selectedDates);
  //   setGroupMatchType(props.groupMatchType);
  //   setTypeMatchType(props.typeMatchType);
  // }, [
  //   props.selectedGroups,
  //   props.selectedTags,
  //   props.selectedAccounts,
  //   props.selectedPlatforms,
  //   props.dateFilter,
  //   props.selectedDates,
  //   props.groupMatchType,
  //   props.typeMatchType,
  // ]);

  const handleFilterPopperClose = (e) => {
    if (filterBar.current && !filterBar.current.contains(e.target)) {
      setFilterPopper(false);
    }

    setFilters();
  };

  const setFilters = () => {
    props.setSelectedGroups(selectedGroups);
    props.setSelectedTags(selectedTags);
    props.setSelectedAccounts(selectedAccounts);
    props.setSelectedPlatforms(selectedPlatforms);
    props.setDateFilter(dateFilter);
    props.setSelectedDates(selectedDates);
    props.setGroupMatchType(groupMatchType);
    props.setTypeMatchType(typeMatchType);
  }

  const handleFilterReset = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setFilterPopper(false);

    if (
      dateFilter.length > 0 ||
      selectedDates.length > 0 ||
      selectedGroups.length > 0 ||
      selectedTags.length > 0 ||
      selectedAccounts.length > 0 ||
      selectedPlatforms.length > 0
    ) {
      setSelectedGroups([]);
      setSelectedTags([]);
      setSelectedAccounts([]);
      setSelectedPlatforms([]);
      // props.setSelectedFilter(null);
      setDateFilter([]);
      setSelectedDates([]);

      props.setSelectedGroups([]);
      props.setSelectedTags([]);
      props.setSelectedAccounts([]);
      props.setSelectedPlatforms([]);
      // props.setSelectedFilter(null);
      props.setDateFilter([]);
      props.setSelectedDates([]);
    }
  };

  const filterActive = dateFilter.length > 0 || selectedGroups.length > 0 || selectedTags.length > 0 || selectedAccounts.length > 0 || selectedPlatforms.length > 0;

  const handleGroupChange = async (event, value) => {
    // console.log(event, value);

    if (event.key == "Enter") {
      event.preventDefault();
      event.stopPropagation();
    }

    let newGroups = JSON.parse(JSON.stringify(value)).filter(
      (option) => typeof option == "string"
    );

    if (newGroups.length > 0) {
      for (let i = 0; i < newGroups.length; i++) {
        //create a new campaign with name newGroups[i]
        let name = newGroups[i];
        let newGroup = await Functions.createCampaign(name, tid);
        softRefresh();
      }
      return;
    }

    let newValue = JSON.parse(JSON.stringify(value)).filter(
      (option) =>
        (!option.type && !option.campaignId) ||
        (option.type && (option.type == "campaign" || option.type == "group"))
    );

    const addedValue = newValue
      .filter(
        (option) => !selectedGroups.some((group) => group.id == option.id)
      )
      .flat();

    const removedValue = selectedGroups
      .filter((option) => !newValue.some((group) => group.id == option.id))
      .flat();


    let groups = newValue;

    if (
      removedValue.length > 0 &&
      (!removedValue[0].type || removedValue[0].type == "campaign")
    ) {
      groups = groups.filter(
        (group) => !group.campaignId || group.campaignId != removedValue[0].id
      );
    }

    //if added group is a group, remove all campaigns associated with that group
    else if (
      addedValue.length > 0 &&
      addedValue[0].type &&
      addedValue[0].type == "group"
    ) {
      groups = groups.filter((group) => group.id != addedValue[0].campaignId);
    }

    //if add group is a campaign, remove all groups associated with that campaign
    else if (
      addedValue.length > 0 &&
      addedValue[0].type &&
      addedValue[0].type == "campaign"
    ) {
      groups = groups.filter(
        (group) => !group.campaignId || group.campaignId != addedValue[0].id
      );
    }

    setSelectedGroups(groups);
    // setDisableGroupChange(true);
    // setTimeout(() => {
    //   setDisableGroupChange(false);
    // }, 1500);
  };

  const filterBarPopperRef = React.useRef(null);

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && filterBarPopperRef.current && filterPopper) {
        event.preventDefault(); // Prevent default behavior of Enter key
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [filterPopper]);

  // React.useEffect(() => {
  //   props.setSelectedDates(selectedDates);
  //   props.setDateFilter(dateFilter);
  // }, [selectedDates, dateFilter]);



  return (
    <Box sx={{display:"flex", flexDirection:"column", gap:1}}>



<Popper
  open={customDateDialogOpen}
  anchorEl={filterBar.current}
  placement="top"
  style={{ zIndex: 2000 }}
>
  <ClickAwayListener onClickAway={() => setCustomDateDialogOpen(false)}>
    <Paper 
      elevation={3}
      sx={{
        p: 3,
        borderRadius: 2,
        width: 320,
        border: "1px solid #ddd",
        background: "#fff",
        mt: 1
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#333' }}>
            Custom Date Range
          </Typography>
          <IconButton 
            size="small" 
            onClick={() => setCustomDateDialogOpen(false)}
          >
            <CloseOutlined sx={{ fontSize: 18, color: '#666' }} />
          </IconButton>
        </Box>

        <TextField
          label="Start Date"
          type="date"
          value={customStartDate ? customStartDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setCustomStartDate(new Date(e.target.value))}
          InputLabelProps={{ 
            shrink: true,
            sx: {
              color: '#666',
              fontWeight: 500
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              '& fieldset': {
                borderColor: '#ddd',
              },
              '&:hover fieldset': {
                borderColor: '#4728c4',
              },
            }
          }}
          fullWidth
        />

        <TextField
          label="End Date"
          type="date"
          value={customEndDate ? customEndDate.toISOString().split('T')[0] : ''}
          onChange={(e) => setCustomEndDate(new Date(e.target.value))}
          InputLabelProps={{ 
            shrink: true,
            sx: {
              color: '#666',
              fontWeight: 500
            }
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 2,
              '& fieldset': {
                borderColor: '#ddd',
              },
              '&:hover fieldset': {
                borderColor: '#4728c4',
              },
            }
          }}
          fullWidth
        />

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, mt: 1 }}>
          <Button 
            onClick={() => setCustomDateDialogOpen(false)}
            sx={{ 
              color: '#666',
              textTransform: 'none',
              fontWeight: 600,
              '&:hover': {
                background: '#f5f5f5'
              }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCustomDateConfirm}
            disabled={!customStartDate || !customEndDate}
            variant="contained"
            sx={{ 
              backgroundColor: "#4728c4",
              textTransform: 'none',
              fontWeight: 600,
              '&:hover': {
                backgroundColor: "#3a1f9e"
              },
              '&.Mui-disabled': {
                backgroundColor: '#e0e0e0'
              }
            }}
          >
            Apply
          </Button>
        </Box>
      </Box>
    </Paper>
  </ClickAwayListener>
</Popper>


    <Grid
      container
      spacing={isMobile ? 0 : 1}
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <Grid item xs={viewportWidth > 600 && !compareMode ? 10.5 : 12}>
        <Box
          ref={filterBar}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
            p: 1.5,
            background: "white",
            borderRadius: 2,
            border: "1px solid #ddd",
            cursor: "pointer",
          }}
          onClick={() => {
            setFilterPopper(true);
          }}
        >
          <FunnelIcon style={{ color: filterActive ? "#4728c4" : "#62676a", height: 20,
           }} />

          {dateFilter.length == 0 &&
            selectedGroups.length == 0 &&
            selectedTags.length == 0 &&
            selectedAccounts.length == 0 &&
            selectedPlatforms.length == 0 && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  height: 45,

                }}
              >
                <Chip
                  sx={{
                    backgroundColor: "#fff",
                    border: `1px solid #ddd`,
                    borderRadius:2,
                    height:40
                  }}
                  label={
                    <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
                    <Typography
                      variant="body2"
                      fontWeight={600}
                      fontSize={14}
                      sx={{  pt:.3}}
                      color="#62676a"
                    >
                      All Posts
                                            {/* All Posts           <Chip label={`${teamData.posts.length}`} sx={{background:"#4728c4", color:"white", fontWeight:500}} size="small"/> */}

                    </Typography>
                    <Chip label={selectedPostData.length} sx={{background:"#5C6078", color:"white", fontWeight:500,   
                    pt:.3,
                   
}} size="small"/> 
                    </Box>
                  }
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: "#666",
                    fontWeight: 500,
                    textTransform: "none",
                    fontSize: 14,
                  }}
                >
                  Select filters to narrow down posts
                </Typography>
              </Box>
            )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              maxWidth: "100%",
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "transparent",
              },
            }}
            ref={filterNameRef}
          >
            {selectedGroups.length > 0 && (
              <Chip
                sx={{
                  backgroundColor: "#fff",
                  border: `.5px solid #b3b3b3`,
                  height: 45,
                  borderRadius: 2,

                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <Typography variant="caption" color="#4728c4">
                      <CampaignOutlined
                        sx={{ color: "#62676a", fontSize: 20 }}
                      />
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 0.5,
                      }}
                    >
                      {selectedGroups.slice(0, 2).map((group) => (
                        <Chip
                          key={group.id}
                          label={group.name}
                          size="small"
                          sx={{
                            backgroundColor: "#5C6078",
                            color: "white",
                            fontWeight: 500,
                            fontSize: 12,
                            borderRadius: 2,

                          }}
                        />
                      ))}
                    </Box>

                    {selectedGroups.length > 2 ? (
                      <Typography variant="body2" color="#555" fontWeight={600}>
                        +{selectedGroups.length - 2}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                }
              />
            )}
            {selectedTags.length > 0 && (
              <Chip
                sx={{
                  backgroundColor: "#fff",
                  border: `.5px solid #b3b3b3`,
                  borderRadius: 2,
                  height: 45,
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <LabelOutlined sx={{ color: "#62676a", fontSize: 20 }} />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 0.5,
                      }}
                    >
                      {selectedTags.slice(0, 2).map((type) => (
                        <Chip
                          key={type}
                          label={type}
                          size="small"
                          sx={{
                            backgroundColor: "#5C6078",
                            color: "white",
                            fontWeight: 500,
                            fontSize: 12,
                            borderRadius: 2,

                            // border: "1px solid #aaa",
                          }}
                        />
                      ))}
                    </Box>

                    {selectedTags.length > 2 ? (
                      <Typography variant="body2" color="#555" fontWeight={600}>
                        +{selectedTags.length - 2}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                }
              />
            )}
            {selectedAccounts.length > 0 && (
              <Chip
                sx={{
                  backgroundColor: "#fff",
                  border: `.5px solid #b3b3b3`,
                  height: 45,
                  borderRadius: 2,
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <AccountCircleOutlined
                      sx={{ color: "#62676a", fontSize: 20 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 0.5,
                      }}
                    >
                      {selectedAccounts.slice(0, 2).map((acc) => (
                        <Chip
                          key={acc}
                          label={acc}
                          size="small"
                          sx={{
                            backgroundColor: "#5C6078",
                            color: "white",
                            fontWeight: 500,
                            fontSize: 12,
                            borderRadius: 2,
                          }}
                        />
                      ))}
                    </Box>
                    {selectedAccounts.length > 2 ? (
                      <Typography variant="body2" color="#555" fontWeight={600}>
                        +{selectedAccounts.length - 2}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                }
              />
            )}
            {selectedPlatforms.length > 0 && (
              <Chip
                sx={{
                  backgroundColor: "#fff",
                  border: `.5px solid #b3b3b3`,
                  borderRadius: 1.5,
                  height: 45,
                  borderRadius: 2,

                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <SwitchAccountOutlined
                      sx={{ color: "#62676a", fontSize: 20 }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 0.5,
                      }}
                    >
                      {selectedPlatforms.slice(0, 2).map((platform) => (
                        <Chip
                          key={platform}
                          label={formatPlatform(platform)}
                          size="small"
                          sx={{
                            backgroundColor: "#5C6078",
                            color: "white",
                            fontWeight: 500,
                            fontSize: 12,
                            borderRadius: 2,

                            // border: "1px solid #aaa",
                          }}
                        />
                      ))}
                    </Box>

                    {selectedPlatforms.length > 2 ? (
                      <Typography variant="body2" color="#555" fontWeight={600}>
                        +{selectedPlatforms.length - 2}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </Box>
                }
              />
            )}
            {/* {selectedPlatforms.length > 0 && (
                            <Chip
                              sx={{
                                backgroundColor: "#f0edfe",
                                border: `1px solid #4728c4`,
                              }}
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 0.5,
                                  }}
                                >
                                  <SwitchAccountOutlined
                                    sx={{ color: "#4728c4", fontSize: 14 }}
                                  />
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selectedPlatforms
                                      .slice(0, 2)
                                      .map((platform) => (
                                        <Chip
                                          key={platform}
                                          label={formatPlatform(platform)}
                                          size="small"
                                          sx={{
                                            backgroundColor: "transparent",
                                            color: "#555",
                                            fontWeight: 600,
                                            fontSize: 12,
                                            border: "1px solid #aaa",
                                          }}
                                        />
                                      ))}
                                  </Box>
                                  {selectedPlatforms.length > 2 ? (
                                    <Typography
                                      variant="body2"
                                      color="#555"
                                      fontWeight={600}
                                    >
                                      +{selectedPlatforms.length - 2}
                                    </Typography>
                                  ) : (
                                    ""
                                  )}
                                </Box>
                              }
                            />
                          )} */}

            {selectedDates && selectedDates.length > 1 && (
              <Chip
                sx={{
                  backgroundColor: "#fff",
                  border: `.5px solid #b3b3b3`,
                  borderRadius: 1.5,
                  height: 45,
                  borderRadius: 2,
                }}
                label={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <CalendarDaysIcon
                      style={{ color: "#62676a", height: 20 }}
                    />
                    <Typography
                      variant="body2"
                      color="#4728c4"
                      fontWeight={500}
                      fontSize={12}
                    >
                      <Chip
                        // key={formatFullDate(selectedDates[0])}
                        label={formatFullDate(selectedDates[0])}
                        size="small"
                        sx={{
                          backgroundColor: "#5C6078",
                            color: "white",
                            fontWeight: 500,
                            fontSize: 12,
                            borderRadius: 2,

                        }}
                      />{" "}
                      -{" "}
                      <Chip
                        // key={formatFullDate(selectedDates[1])}
                        label={formatFullDate(selectedDates[1])}
                        size="small"
                        sx={{
                          backgroundColor: "#5C6078",
                          color: "white",
                          fontWeight: 500,
                          fontSize: 12,
                          borderRadius: 2,

                        }}
                      />
                    </Typography>
                  </Box>
                }
              />
            )}
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: 1,
              maxWidth: "20%",
            }}
            ref={filterNameRef}
          >
            {selectedDates.length != 0 ||
            dateFilter.length != 0 ||
            selectedGroups.length != 0 ||
            selectedTags.length != 0 ||
            selectedAccounts.length != 0 ||
            selectedPlatforms.length != 0 ? (
              <>
                <Button
                  startIcon={
                    <CloseOutlined sx={{ color: "#666", width: 14 }} />
                  }
                  onClick={handleFilterReset}
                  sx={{
                    borderRadius: 30,
                    border: "1px solid #ddd",
                    padding: "4px 10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "#666",
                      fontWeight: 500,
                      fontSize: 14,
                      textTransform: "none",
                    }}
                  >
                    Clear
                  </Typography>
                </Button>

                {/* <Button startIcon={<CheckCircleOutlineOutlined sx={{color:"#666", width:14}}/>
                      disabled ={
                        selectedFilter && selectedFilter.settings && selectedFilter.settings.groups == selectedGroups.map((group) => group.id) && selectedFilter.settings.types == selectedTags && selectedFilter.settings.accounts == selectedAccounts && selectedFilter.settings.platforms == selectedPlatforms
                      }
                      onClick={() => {
                        filterName.trim() == "" ? setFilterNamePopper(true) : handleFilterSave();
                      }}
                      sx={{borderRadius:30, border:"1px solid #ddd",padding:"4px 10px"}}
  
                      >
  
                      <Typography variant="body1" sx={{ color: "#666", fontWeight: 500, fontSize: 14, textTransform:"none" }}>Save</Typography> */}
              </>
            ) : (
              <>
                <Button sx={{ borderRadius: 30, padding: "4px 10px" }} disabled>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "transparent",
                      fontWeight: 500,
                      fontSize: 14,
                      textTransform: "none",
                    }}
                  >
                    Clear
                  </Typography>
                </Button>
                {/* <Button 
                    sx={{borderRadius:30 ,padding:"4px 10px"}}
                    disabled
                    >
                      <Typography variant="body1" sx={{ color: 
                        "transparent", fontWeight: 500, fontSize: 14, textTransform:"none" }}>Save</Typography>
                    </Button> */}
              </>
            )}
{/* 
            {!compareMode && groupView === "posts" && (
              <Button
                startIcon={<ArchiveOutlined sx={{ color: "#666", width: 14 }} />}
                onClick={() => setShowArchivedModal(true)}
                sx={{
                  borderRadius: 30,
                  border: "1px solid #ddd",
                  padding: "4px 10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "#666",
                    fontWeight: 500,
                    fontSize: 14,
                    textTransform: "none",
                  }}
                >
                  Archived
                </Typography>
              </Button>
            )} */}
          </Box>
        </Box>

        {filterPopper && (
          <ClickAwayListener onClickAway={handleFilterPopperClose}>
            <Popper
              ref={filterBarPopperRef}
              open={filterPopper}
              anchorEl={
                filterBar && filterBar.current
                  ? filterBar.current
                  : document.body
              }
              placement="bottom"
              style={{ zIndex: 1000 }}
            >
              <Paper
                sx={{
                  p: 2,
                  borderRadius: 2,
                  width:
                    filterBar && filterBar.current
                      ? filterBar.current.offsetWidth - 50
                      : 300,
                  background: "#fff",
                  border: "1px solid #ddd",
                  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                  mt: -1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CampaignOutlined sx={{ color:  selectedGroups.length > 0 ? "#4728c4" : "#999", fontSize: 20 }} />
                    {viewportWidth > 900 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: selectedGroups.length > 0 ? "#4728c4" : "#666",
                          fontWeight: 600,
                          fontSize: 14,
                          width: 100,
                        }}
                      >
                        Campaign
                      </Typography>
                    )}
                    <Box sx={{ flexGrow: 1 }} />

                    <GroupAutoComplete
                      highlightGroupField={highlightGroupField} 
                      selectedGroups={selectedGroups}
                      setSelectedGroups={setSelectedGroups}
                      disableGroupChange={disableGroupChange}
                      setDisableGroupChange={setDisableGroupChange}
                      teamData={teamData}
                      openAddNew={openAddNew}
                      handleGroupChange={handleGroupChange}
                      softRefresh={softRefresh}
                      groupMatchType={groupMatchType}
                      setGroupMatchType={setGroupMatchType}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <TagIcon style={{ color: selectedTags.length > 0 ? "#4728c4" :"#999", height: 25 }} />
                      
                    {viewportWidth > 900 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color:  selectedTags.length > 0 ? "#4728c4" :"#666",
                          fontWeight: 600,
                          fontSize: 14,
                          width: 100,
                        }}
                      >
                        Post Tag
                      </Typography>
                    )}

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Autocomplete
                        ref={tagAutocompleteRef}
                        // freeSolo
                        fullWidth
                        sx={{
                          width: "100%",
                          cursor: "pointer",
                          borderRadius: 2,
                          color: "#999",
                          fontWeight: 600,
                          border: highlightTagField
                            ? "2px solid #4727c3"
                            : "1px solid #ddd",
                          backgroundColor: "#fff",
                          mt: 1.5,
                          "& .MuiAutocomplete-inputRoot": {
                            fontSize: "16px",
                          },
                          "& .MuiAutocomplete-option": {
                            fontSize: "16px",
                          },
                        }}
                        forcePopupIcon={true}
                        disableCloseOnSelect
                        clearIcon={selectedTags.length == 0 ? null : <Clear />}
                        multiple
                        options={
                          //get all platforms in teamData.posts and remove duplicates
                          teamData.posts.filter((post) => post.data.tags)
                            .map((post) => JSON.parse(post.data.tags))
                            .flat()
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )
                        }
                        // getOptionLabel={(option) => formatPlatform(option)}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li
                              {...props}
                              style={{
                                pointerEvents: disableGroupChange
                                  ? "none"
                                  : "auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                  alignItems: "center",
                                  flexGrow: 1,
                                }}
                              >
                                <Checkbox checked={selected} size="small" />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#666",
                                    fontWeight: 600,
                                    fontSize: 14,
                                  }}
                                >
                                  {option}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />

                                {/* {selected ?
                                  <IconButton size="small" onClick={() => openAddNew("group", true)}><FolderCopy sx={{color:"#999"}}/></IconButton>
                                  : null} */}
                              </Box>
                            </li>
                          );
                        }}
                        onChange={(event, value) => {
                          setSelectedTags(
                            value.filter((option) => option != "Any")
                          );
                          // softRefresh();
                        }}
                        value={selectedTags.length > 0 ? selectedTags : ["Any"]}
                        // isOptionEqualToValue={(option, value) =>
                        //   option.id == value.id
                        // }

                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <>
                              <Chip
                                key={index}
                                sx={{
                                  backgroundColor: "#fff",
                                  border: "1px solid #ddd",
                                  borderRadius: 2,
                                }}
                                label={
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#666",
                                      fontWeight: 600,
                                      fontSize: 14,
                                    }}
                                  >
                                    {option}
                                  </Typography>
                                }
                                {...getTagProps({ index })}
                                onDelete={
                                  option == "Any"
                                    ? null
                                    : () => {
                                        let newValue = selectedTags.filter(
                                          (platform) => platform != option
                                        );
                                        setSelectedTags(newValue);
                                      }
                                }

                                // deleteIcon={<CloseOutlined />}
                              />
                              {value.length > 1 && index < value.length - 1 ? (
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#666",
                                    fontWeight: 600,
                                    fontSize: 12,
                                  }}
                                >
                                  {typeMatchType.toUpperCase()}
                                </Typography>
                              ) : null}
                            </>
                          ))
                        }
                        renderInput={(params) => (
                          <>
                            <TextField
                              InputProps={{
                                sx: { overflow: "hidden" },
                              }} // Adjust input text font size
                              {...params}
                              sx={{
                                "& .MuiInputBase-input": {
                                  fontSize: "16px",
                                },
                                "& .MuiInputLabel-root": {
                                  fontSize: "16px",
                                },
                                "& .MuiAutocomplete-option": {
                                  fontSize: "16px",
                                },
                              }}
                              variant="outlined"
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: 1,
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#999",
                                      fontWeight: 600,
                                      fontSize: 16,
                                    }}
                                  >
                                    Tag
                                  </Typography>
                                  <TagIcon style={{ color: "#999", height: 20 }} />

                                  {/* <LabelOutlined
                                    sx={{
                                      color: "#666",
                                      height: 20,
                                      width: 20,
                                    }}
                                  /> */}
                                </Box>
                              }
                              placeholder="Type to search"
                            />

                            {tagAutocompleteRef.current &&
                              tagAutocompleteRef.current.querySelector("input")
                                .value &&
                              !tagExists(
                                tagAutocompleteRef.current.querySelector(
                                  "input"
                                ).value
                              ) && (
                                <Popper
                                  open={true}
                                  anchorEl={tagAutocompleteRef.current.querySelector(
                                    "input"
                                  )}
                                  placement="top-start"
                                  sx={{ zIndex: 2000, mb: 2 }}
                                >
                                  <Chip
                                    icon={
                                      <Info
                                        sx={{ color: "#999", height: 18 }}
                                      />
                                    }
                                    label="Go to Individual Posts Below To Create New Tags "
                                    sx={{
                                      background: "#fff",
                                      color: "#666",
                                      border: "1px solid #ddd",
                                      fontSize: 12,
                                      fontWeight: 500,
                                      marginLeft: 2,
                                      borderRadius: 2,
                                    }}

                                    // onClick={handleClose}
                                  />
                                </Popper>
                              )}
                          </>
                        )}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                    <GlobeAltIcon style={{ color: selectedPlatforms.length > 0 ? "#4728c4" :"#999", height: 25 }} />

                    {viewportWidth > 900 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color:  selectedPlatforms.length > 0 ? "#4728c4" :"#666",
                          fontWeight: 600,
                          fontSize: 14,
                          width: 100,
                        }}
                      >
                        Platform
                      </Typography>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Autocomplete
                        sx={{
                          width: "100%",
                          cursor: "pointer",
                          borderRadius: 2,
                          color: "#999",
                          fontWeight: 600,
                          border: "1px solid #ddd",
                          backgroundColor: "#fff",
                          mt: 1.5,
                          "& .MuiAutocomplete-inputRoot": {
                            fontSize: "16px",
                          },
                          "& .MuiAutocomplete-option": {
                            fontSize: "16px",
                          },
                        }}
                        forcePopupIcon={true}
                        disableCloseOnSelect
                        clearIcon={
                          selectedPlatforms.length == 0 ? null : <Clear />
                        }
                        multiple
                        options={
                          //get all platforms in teamData.posts and remove duplicates

                          teamData.posts.filter((post) => post.data.platform)
                            .map((post) => post.data.platform.toLowerCase())
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )
                        }
                        // getOptionLabel={(option) => formatPlatform(option)}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li
                              {...props}
                              style={{
                                pointerEvents: disableGroupChange
                                  ? "none"
                                  : "auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                  alignItems: "center",
                                  flexGrow: 1,
                                }}
                              >
                                <Checkbox checked={selected} size="small" />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#666",
                                    fontWeight: 600,
                                    fontSize: 14,
                                  }}
                                >
                                  {formatPlatform(option)}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />

                                {/* {selected ?
                                          <IconButton size="small" onClick={() => openAddNew("group", true)}><FolderCopy sx={{color:"#999"}}/></IconButton>
                                          : null} */}
                              </Box>
                            </li>
                          );
                        }}
                        onChange={(event, value) =>
                          setSelectedPlatforms(
                            value.filter((option) => option != "Any")
                          )
                        }
                        value={
                          selectedPlatforms.length > 0
                            ? selectedPlatforms
                            : ["Any"]
                        }
                        // isOptionEqualToValue={(option, value) =>
                        //   option.id == value.id
                        // }

                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <>
                              <Chip
                                key={index}
                                sx={{
                                  backgroundColor: "#fff",
                                  border: "1px solid #ddd",
                                  borderRadius: 2,

                                }}
                                label={
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#666",
                                      fontWeight: 600,
                                      fontSize: 14,
                                      borderRadius: 2,

                                    }}
                                  >
                                    {formatPlatform(option)}
                                  </Typography>
                                }
                                {...getTagProps({ index })}
                                onDelete={
                                  option == "Any"
                                    ? null
                                    : () => {
                                        let newValue = selectedPlatforms.filter(
                                          (platform) => platform != option
                                        );
                                        setSelectedPlatforms(newValue);
                                      }
                                }

                                // deleteIcon={<CloseOutlined />}
                              />
                            </>
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            InputProps={{
                              sx: { overflow: "hidden" },
                            }} // Adjust input text font size
                            {...params}
                            sx={{
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                              },
                              "& .MuiInputLabel-root": {
                                fontSize: "16px",
                              },
                              "& .MuiAutocomplete-option": {
                                fontSize: "16px",
                              },
                            }}
                            variant="outlined"
                            label={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#999",
                                    fontWeight: 600,
                                    fontSize: 16,
                                  }}
                                >
                                  Platform{" "}
                                </Typography>
                   
                                                 <GlobeAltIcon style={{ color: "#999", height: 20 }} />

                              </Box>
                            }
                            placeholder="Type to search"
                          />
                        )}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
            <UserCircleIcon style={{ color: selectedAccounts.length > 0 ? "#4728c4" :"#999", height: 25 }} />

                    {viewportWidth > 900 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color:  selectedAccounts.length > 0 ? "#4728c4" : "#666",
                          fontWeight: 600,
                          fontSize: 14,
                          width: 100,
                        }}
                      >
                        Account
                      </Typography>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Autocomplete
                        sx={{
                          width: "100%",
                          cursor: "pointer",
                          borderRadius: 2,
                          color: "#999",
                          fontWeight: 600,
                          border: "1px solid #ddd",
                          backgroundColor: "#fff",
                          mt: 1.5,
                          "& .MuiAutocomplete-inputRoot": {
                            fontSize: "16px",
                          },
                          "& .MuiAutocomplete-option": {
                            fontSize: "16px",
                          },
                        }}
                        forcePopupIcon={true}
                        disableCloseOnSelect
                        clearIcon={
                          selectedAccounts.length == 0 ? null : <Clear />
                        }
                        multiple
                        options={
                          //get all platforms in teamData.posts and remove duplicates

                          teamData.posts.filter((post) => post.data.username)
                            .map((post) => post.data.username.replace("@", ""))
                            .filter(
                              (value, index, self) =>
                                self.indexOf(value) === index
                            )
                        }
                        // getOptionLabel={(option) => formatPlatform(option)}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li
                              {...props}
                              style={{
                                pointerEvents: disableGroupChange
                                  ? "none"
                                  : "auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                  alignItems: "center",
                                  flexGrow: 1,
                                }}
                              >
                                <Checkbox checked={selected} size="small" />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#666",
                                    fontWeight: 600,
                                    fontSize: 14,
                                  }}
                                >
                                  {option}
                                </Typography>
                                <Box sx={{ flexGrow: 1 }} />

                                {/* {selected ?
                                  <IconButton size="small" onClick={() => openAddNew("group", true)}><FolderCopy sx={{color:"#999"}}/></IconButton>
                                  : null} */}
                              </Box>
                            </li>
                          );
                        }}
                        onChange={(event, value) =>
                          setSelectedAccounts(
                            value.filter((option) => option != "Any")
                          )
                        }
                        value={
                          selectedAccounts.length > 0
                            ? selectedAccounts
                            : ["Any"]
                        }
                        // isOptionEqualToValue={(option, value) =>
                        //   option.id == value.id
                        // }

                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <>
                              <Chip
                                key={index}
                                sx={{
                                  backgroundColor: "#fff",
                                  border: "1px solid #ddd",
                                  borderRadius: 2,

                                }}
                                label={
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#666",
                                      fontWeight: 600,
                                      fontSize: 14,

                                    }}
                                  >
                                    {option}
                                  </Typography>
                                }
                                {...getTagProps({ index })}
                                onDelete={
                                  option == "Any"
                                    ? null
                                    : () => {
                                        let newValue = selectedAccounts.filter(
                                          (platform) => platform != option
                                        );
                                        setSelectedAccounts(newValue);
                                      }
                                }

                                // deleteIcon={<CloseOutlined />}
                              />
                              {/* {value.length > 1 &&
                        index < value.length - 1 ? (
                          <Typography
                            variant="body1"
                            sx={{
                              color: "#666",
                              fontWeight: 600,
                              fontSize: 12,
                            }}
                          >
                            {accountMatchType.toUpperCase()}
                          </Typography>
                        ) : null} */}
                            </>
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            InputProps={{
                              sx: { overflow: "hidden" },
                            }} // Adjust input text font size
                            {...params}
                            sx={{
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                              },
                              "& .MuiInputLabel-root": {
                                fontSize: "16px",
                              },
                              "& .MuiAutocomplete-option": {
                                fontSize: "16px",
                              },
                            }}
                            variant="outlined"
                            label={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#999",
                                    fontWeight: 600,
                                    fontSize: 16,
                                  }}
                                >
                                  Account{" "}
                                </Typography>
                                <UserCircleIcon style={{ color: "#999", height: 20 }} />

                              </Box>
                            }
                            placeholder="Type to search"
                          />
                        )}
                      />
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                    }}
                  >
                               <CalendarDateRangeIcon style={{ color: selectedDates.length > 0 ? "#4728c4" :"#999", height: 25 }} />

                    {viewportWidth > 900 && (
                      <Typography
                        variant="body1"
                        sx={{
                          color: selectedDates.length > 0 ? "#4728c4" :"#666",
                          fontWeight: 600,
                          fontSize: 14,
                          width: 100,
                        }}
                      >
                        Post Date
                      </Typography>
                    )}

                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Autocomplete
                        sx={{
                          width: "100%",
                          cursor: "pointer",
                          borderRadius: 2,
                          color: "#999",
                          fontWeight: 600,
                          border: "1px solid #ddd",
                          backgroundColor: "#fff",
                          mt: 1.5,
                          "& .MuiAutocomplete-inputRoot": {
                            fontSize: "16px",
                          },
                          "& .MuiAutocomplete-option": {
                            fontSize: "16px",
                          },
                        }}
                        forcePopupIcon={true}
                        disableCloseOnSelect
                        clearIcon={dateFilter.length == 0 ? null : <Clear />}
                        multiple
                        options={[
                          {
                            label: "Last 7 Days",
                            type: "preset",
                            id: "7days",
                            startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
                            endDate: new Date(),
                          },
                          {
                            label: "Last 30 Days",
                            type: "preset",
                            id: "30days",
                            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
                            endDate: new Date(),
                          },
                          {
                            label: "Last 90 Days",
                            type: "preset",
                            id: "90days",
                            startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
                            endDate: new Date(),
                          },
                          {
                            label: "Custom Range",
                            type: "custom",
                            id: "custom",
                          },
                        ]}
                        isOptionEqualToValue={(option, value) => {
                          if (option.type === 'preset' && value.type === 'preset') {
                            return option.id === value.id;
                          }
                          if (option.type === 'custom' && value.type === 'custom') {
                            return true;
                          }
                          return false;
                        }}
                        renderOption={(props, option, { selected }) => {
                          return (
                            <li
                              {...props}
                              onClick={option.type === "custom" ? handleCustomDateSelect : props.onClick}
                              style={{
                                pointerEvents: disableGroupChange ? "none" : "auto",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                  alignItems: "center",
                                  flexGrow: 1,
                                }}
                              >
                                <Checkbox checked={selected} size="small" />
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#666",
                                    fontWeight: 600,
                                    fontSize: 14,
                                  }}
                                >
                                  {option.label}
                                </Typography>
                              </Box>
                            </li>
                          );
                        }}
                        onChange={(event, value) => {
                          if (value.length > 0) {
                            const lastSelected = value[value.length - 1];
                            if (lastSelected.type === "preset") {
                              setSelectedDates([lastSelected.startDate, lastSelected.endDate]);
                              setDateFilter([lastSelected]);
                            }
                          
                          else if (lastSelected.type === "custom") {
                            setSelectedDates([lastSelected.startDate, lastSelected.endDate]);
                            setDateFilter([lastSelected]);
                          }
                        }
                          else {
                            setSelectedDates([]);
                            setDateFilter([]);
                          }
                        }}
                        value={dateFilter.length > 0 ? dateFilter : [{label:'Any', type:'preset', id:'any'}]}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={index}
                              sx={{
                                backgroundColor: "#fff",
                                border: "1px solid #ddd",
                                borderRadius: 2,
                              }}
                              label={
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#666",
                                    fontWeight: 600,
                                    fontSize: 14,
                                  }}
                                >
                                  {option.label}
                                </Typography>
                              }
                              {...getTagProps({ index })}
                              onDelete={  option.label == "Any" ? null : () => {
                             
                               setSelectedDates([]);
                                setDateFilter([]);
                               
                              }}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            InputProps={{
                              sx: { overflow: "hidden" },
                            }}
                            {...params}
                            sx={{
                              "& .MuiInputBase-input": {
                                fontSize: "16px",
                              },
                              "& .MuiInputLabel-root": {
                                fontSize: "16px",
                              },
                              "& .MuiAutocomplete-option": {
                                fontSize: "16px",
                              },
                            }}
                            variant="outlined"
                            label={
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 1,
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#999",
                                    fontWeight: 600,
                                    fontSize: 16,
                                  }}
                                >
                                  Date Range
                                </Typography>
                                <CalendarDaysIcon style={{ color: "#999", height: 20 }} />
                              </Box>
                            }
                            placeholder="Select date range"
                          />
                        )}
                      />
                    </Box>
                  
                  </Box>
                  {teamData?.archivedPosts?.length > 0 && groupView === "posts" && (

                  <Box sx={{display:"flex", flexDirection:"row", gap:1, alignItems:"center", justifyContent:"flex-end"}}>

                                <Button
                                  variant="text"
                                  size="small"
                                  sx={{
                                    color: "#666",
                                    borderRadius: 30,
                                    textTransform: "none",
                                    fontWeight: 600,
                                    fontSize: 12,
                                  }}
                                  startIcon={<EyeSlashIcon style={{color:"#aaa", height:16, width:16}} />}
                                  onClick={() => setShowArchivedModal(true)}
                                >
                                  Archived ({teamData?.archivedPosts?.length || 0})
                                </Button>
                  </Box>
                  )}
                </Box>
              </Paper>
            </Popper>
          </ClickAwayListener>
        )}
      </Grid>
      <Grid item xs={viewportWidth > 600 ? 1.5 : 0}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {!selectedPost && viewportWidth > 600 && !compareMode ? (
            <>
              <Button
                ref={addButton}
                fullWidth
                sx={{
                  // minWidth: 100,
                  pt:1,
                  borderRadius: 2,
                  fontWeight: 700,
                  fontSize: 16,
                  height: 56,
                  textTransform: "none",
                  color: "#fff",
                  background: "#4828C5",
                  "&:hover": { background: "#4828C5" },
                  "&:disabled": {
                    background: "#ddd",
                    color: "#999",
                  },
                }}
                startIcon={<Add />}
                onClick={() => setOpenAddMenu(true)}
              >
                Add
              </Button>
              <Popper
                open={openAddMenu}
                anchorEl={addButton.current}
                placement="bottom"
                style={{ zIndex: 2000 }} // Add this line

              >
                <ClickAwayListener onClickAway={() => setOpenAddMenu(false)}>
                  <Paper
                    sx={{
                      borderRadius: 2,
                      width: 200,
                      background: "#fff",
                      border: "1px solid #ddd",
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                      mt: -1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        p: 2,
                      }}
                    >
                      <Button
                        fullWidth
                        variant="text"
                        sx={{
                          borderRadius: 30,
                          textTransform: "none",
                          fontWeight: 600,
                          fontSize: 18,
                        }}
                        startIcon={<Add />}
                        onClick={() => openAddNew("post", true)}
                      >
                        Add Posts
                      </Button>
                      <Divider />
                      <Button
                        fullWidth
                        variant="text"
                        sx={{
                          color: "#666",
                          borderRadius: 30,
                          textTransform: "none",
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                        startIcon={<CampaignOutlined />}
                        onClick={() => {
                          setFilterPopper(true);
                          setHighlightGroupField(true);
                          setTimeout(() => {
                            setHighlightGroupField(false);
                          }, 2000);
                        }}
                      >
                        Create Campaign
                      </Button>



                    </Box>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </>
          ) : (
            // </Badge>
            <>
              {/* <IconButton
                              size="small"
                              sx={{
                                background: "#4828C5",
                                color: "#fff",
                                borderRadius: 30,
                              }}
                              onClick={() => openAddNew("post", true)}
                            >
                              <Add />
                            </IconButton>
                            {isMobile && mobileTab == 0 && (
                              <Tooltip title="Analytics" placement="bottom">
                                <Button
                                  startIcon={
                                    <CommentOutlined sx={{ color: "#666" }} />
                                  }
                                  size="small"
                                  endIcon={
                                    <ArrowForwardIos sx={{ color: "#666" }} />
                                  }
                                  onClick={() =>
                                    setMobileTab(mobileTab == 0 ? 1 : 0)
                                  }
                                >
                                  <Typography
                                    variant="body1"
                                    sx={{
                                      color: "#666",
                                      textTransform: "none",
                                      fontWeight: 600,
                                    }}
                                  ></Typography>
                                </Button>
                              </Tooltip>
                            )} */}
            </>
          )}
        </Box>
      </Grid>
    </Grid>




    {/* {!selectedPost && teamData && (
      <SuggestionsSection 
        filterActive={filterActive}
        teamData={teamData}     
        campaignFilterActive={selectedGroups.length > 0}
        accountFilterActive={selectedAccounts.length > 0}
        platformFilterActive={selectedPlatforms.length > 0}
        dateFilterActive={dateFilter.length > 0}
        setSelectedDates={props.setSelectedDates}
        setSelectedAccounts={props.setSelectedAccounts}
        setSelectedGroups={props.setSelectedGroups}
        setSelectedPlatforms={props.setSelectedPlatforms}
        setSelectedTags={props.setSelectedTags}
        setDateFilter={props.setDateFilter}
        selectedPostData={selectedPostData}
      />
)} */}




          </Box>
  );
}


const CountdownProgress = (props) => {
  const [progress, setProgress] = useState(100);
  const { seconds } = props;

    const duration = seconds * 1000; // Total duration in milliseconds (10 seconds)
    const refreshRate = 100; // Refresh every 100ms (0.1 seconds)
  
    React.useEffect(() => {
      const interval = setInterval(() => {
        setProgress((prev) => Math.max(prev - (100 / (duration / refreshRate)), 0));
      }, refreshRate);
  
      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }, []);
  
    return (
        <LinearProgress
          sx={{
            transform: "scaleX(-1)", // Flips the bar direction to right-to-left
            backgroundColor: "#cdbdef",
            height: 4,
            borderRadius:0,
            border:"transparent"
          }}
          variant="determinate"
          value={progress}
        />
    );
  };
