import { useAuth } from "@clerk/clerk-react";

console.log('Environment:', process.env.REACT_APP_ENV);
console.log('Endpoint from env:', process.env.REACT_APP_ENDPOINT);

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
const SCREENSHOT_ENDPOINT = process.env.REACT_APP_SCREENSHOT_ENDPOINT;


const HelperFunctions = () => {
    const { getToken } = useAuth()

    const fetchFunctions = {
        fetchUser: (email) => fetchUser(email, getToken),
        listSubscriptionsByEmail: (email) => listSubscriptionsByEmail(email, getToken),
        listOwnedByEmail: (email) => listOwnedByEmail(email, getToken),
        listSiftsByEmail: (email) => listSiftsByEmail(email, getToken),
        fetchSift: (videoId, platform) => fetchSift(videoId, platform, getToken),
        fetchCommentData: (groupId, postId) => fetchCommentData(groupId, postId, getToken),
        fetchCommentDataByPostId: (postId) => fetchCommentDataByPostId(postId, getToken),
        fetchAccountAvatar: (platform, username) => fetchAccountAvatar(platform, username, getToken),
        screenShotComments: (html) => screenShotComments(html, getToken),
        fetchCampaigns: () => fetchCampaigns(getToken),
    };

    const userManagementFunctions = {
        updateUserSettings: (email, settings) => updateUserSettings(email, settings, getToken),
        createUser: (email, name) => createUser(email, name, getToken),
        unlinkUserTeam: (userId, teamId) => unlinkUserTeam(userId, teamId, getToken),
        linkUserTeam: (userId, teamId) => linkUserTeam(userId, teamId, getToken),
        addMemberToTeam: (teamId, email) => addMemberToTeam(teamId, email, getToken),
        removeMemberFromTeam: (teamId, userId) => removeMemberFromTeam(teamId, userId, getToken),
        getUserTeamData: (userId) => getUserTeamData(userId, getToken),
    };

    const teamFunctions = {
        getTeamData: (id) => getTeamData(id, getToken),
        updateTeamData: (teamId, data) => updateTeamData(teamId, data, getToken),
        updateTeamName: (teamId, name) => updateTeamName(teamId, name, getToken),
        createTeam: (name) => createTeam(name, getToken),
        getTeamUsageData: (teamId, start, end) => getTeamUsageData(teamId, start, end, getToken),
        getTeammateData: (teamId) => getTeammateData(teamId, getToken),
        linkDrop: (links, teamId, groupIds) => linkDrop(links, teamId, groupIds, getToken),
        linkDropQueue: (links, teamId, groupIds) => linkDropQueue(links, teamId, groupIds, getToken),
        getReportData: (postIds) => getReportData(postIds, getToken),
        createCampaign: (name, teamId) => createCampaign(name, teamId, getToken),
        createGroup: (name, teamId, campaignId) => createGroup(name, teamId, campaignId, getToken),
        updateGroupName: (name, groupId) => updateGroupName(name, groupId, getToken),
        updatePostGroups: (postId, groupIds) => updatePostGroups(postId, groupIds, getToken),
    };

    const postFunctions = {
        updatePostGroups: (postId, groupIds) => updatePostGroups(postId, groupIds, getToken),
        updatePostTags: (postId, teamId, tags) => updatePostTags(postId, teamId, tags, getToken),
        updatePostsGroups: (postIds, groupIds) => updatePostsGroups(postIds, groupIds, getToken),
        summarizeComments: (commentsByPost, analytics, filterType, signal) => 
            summarizeComments(commentsByPost, analytics, filterType, signal, getToken),
        searchCommentsByPosts: (topic, filters, postIds, groupIds) => searchCommentsByPosts(topic, filters, postIds, groupIds, getToken),
        searchCommentsByPost: (topic, filters, postId, groupIds) => searchCommentsByPost(topic, filters, postId, groupIds, getToken),
        checkAccount: (username, platform) => checkAccount(username, platform, getToken),
        getPostVibeCheck: (postId) => getPostVibeCheck(postId, getToken),
        getAllPosts: (options = {}) => getAllPosts(getToken, options),
        fetchAllPostsWithPagination: (options = {}) => fetchAllPostsWithPagination(getToken, options),
        updatePostsStatus: (postIds, status) => updatePostsStatus(postIds, status, getToken),

    };

    return {
        ...fetchFunctions,
        ...userManagementFunctions,
        ...teamFunctions,
        ...postFunctions,
        getAllTeams: (options = {}) => getAllTeams(getToken, options),
        updateTeamPlan: (teamId, planData) => updateTeamPlan(teamId, planData, getToken),
        isPostTooOld: (LOOKBACK, postedAt) => isPostTooOld(LOOKBACK, postedAt),

    };
};

export default HelperFunctions;

const isPostTooOld = (LOOKBACK, postedAt) => {
    const postedDate = new Date(postedAt);
    const currentDate = new Date();
    const lookbackDate = new Date(currentDate);
    lookbackDate.setMonth(lookbackDate.getMonth() - LOOKBACK);
  
    // Return true if post is older than lookback date
    return postedDate < lookbackDate;
  };
  

async function fetchAllPostsWithPagination(token, options = {}) {
    let allPosts = [];
    let currentPage = 1;
    let hasMore = true;
    
    while (hasMore) {
      console.log(`Fetching page ${currentPage}...`);
      const result = await getAllPosts(token, {
        page: currentPage,
        limit: 1000,
        platform: options.platform || undefined,
        startDate: options.dateRange ? options.dateRange[0]?.toISOString() : undefined,
        endDate: options.dateRange ? options.dateRange[1]?.toISOString() : undefined
      });

      if (!result?.data || result.data.length === 0) {
        hasMore = false;
        break;
      }

      allPosts = [...allPosts, ...result.data];
      console.log(`Fetched ${result.data.length} posts from page ${currentPage}. Total so far: ${allPosts.length}`);

      if (result.data.length < 1000) {
        hasMore = false;
        break;
      }

      currentPage++;
      
      // Add a small delay to prevent overwhelming the API
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    console.log(`Total posts fetched: ${allPosts.length}`);
    return allPosts;
  };


async function getPostVibeCheck(postId, getToken){

    let req = {
        postId: postId
    };

    return await fetch(`${ENDPOINT}/getpostvibecheck`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data.data;
        }).catch((err) => console.log(err));
}



async function summarizeComments(commentsByPost, analytics, filterType, signal, getToken) {

    console.log("summarizing comments...");
    console.log(`${ENDPOINT}/summarize`);
    const response = await fetch(`${ENDPOINT}/summarize`, {
        method: 'POST',
        signal, // Pass the signal to fetch
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${await getToken()}` // Add authentication header
        },
        body: JSON.stringify({
            commentsByPost,
            analytics,
            filterType
        }),
    });
    
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return await response.json();
}

async function removeMemberFromTeam(teamId, userId, getToken){

    let req = {
        teamId: teamId,
        userId: userId
    };

    return await fetch(`${ENDPOINT}/removememberfromteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data;
        }).catch((err) => console.log(err));
}

async function addMemberToTeam(teamId, email, getToken){

    let req = {
        email: email,
        teamId: teamId
    };

    return await fetch(`${ENDPOINT}/addmembertoteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            return data;
        }).catch((err) => console.log(err));
}

async function createUser(email, name, getToken){
        
            let req = {
                email: email,
                name: name
            };
        
            return await fetch(`${ENDPOINT}/createuser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
                .then((data) => {
        
                    // console.log("data", data);
        
                    if (data && data.data) {
                        return data.data;
                    }
                }).catch((err) => console.log(err));
        
        }   

async function unlinkUserTeam(userId, teamId, getToken){
        
            let req = {
                userId: userId,
                teamId: teamId,
            };
        
            return await fetch(`${ENDPOINT}/unlinkuserteam`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
                .then((data) => {
        
                    // console.log("data", data);
        
                    if (data && data.data) {
                        return data.data;
                    }
                }).catch((err) => console.log(err));
        
        }




async function linkUserTeam(userId, teamId, getToken){
    


        let req = {
            userId: userId,
            teamId: teamId,
            status: "active",
            role: "admin"
        };
    
        return await fetch(`${ENDPOINT}/linkuserteam`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

    async function getReportData(postIds, getToken){

        let req = {
            postIds: postIds
        };
    
        return await fetch(`${ENDPOINT}/getreportdata`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

async function createTeam(name, getToken){

    let req = {
        name: name,
    };

    return await fetch(`${ENDPOINT}/createteam`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function updateGroupName(name, groupId, getToken){
    
        let req = {
            groupId: groupId,
            name: name
        };
    
        return await fetch(`${ENDPOINT}/updategroupname`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }

    async function linkDrop(links, teamId, groupIds, getToken){
            
        let req = {
            links: links,
            teamId: teamId,
            groupIds: groupIds
        };

        console.log("req", req);
    
        return await fetch(
            // `${ENDPOINT}/ldqueue/`
            `${ENDPOINT}/linkdrop`
            
            , {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }



    async function linkDropQueue(links, teamId, groupIds) {
        // Organize links by platform
        const platformLinks = {};
    
        links.forEach(link => {
            const platform = getPlatformFromLink(link); // Assume this function extracts the platform from the link
            if (!platformLinks[platform]) {
                platformLinks[platform] = [];
            }
            platformLinks[platform].push(link);
        });
    
        // Send each platform's links to their corresponding endpoint
        const promises = Object.keys(platformLinks).map(platform => {
            let req = {
                links: platformLinks[platform],
                teamId: teamId,
                groupIds: groupIds
            };
    
            console.log("req for platform", platform, req);
    
            return fetch(`https://citv7wn2f9.execute-api.us-east-1.amazonaws.com/dev/ldqueue/${platform}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",

                },
                body: JSON.stringify(req),
            }).then((res) => res.json())
              .then((data) => {
                  console.log("data for platform", platform, data);
                  return data && data.data ? data.data : null;
              }).catch((err) => console.log(err));
        });
    
        return Promise.all(promises); // Wait for all requests to complete
    }
    
    // Helper function to extract platform from link
    function getPlatformFromLink(link) {
        if (link.includes("instagram.com")) {
            return "Instagram";
        } else if (link.includes("tiktok.com")) {
            return "TikTok";
        }
    else if (link.includes("youtube.com")) {
        return "YouTube";

    }
    else if (link.includes("linkedin.com")) {
        return "LinkedIn";

    }
    else if (link.includes
    ("twitter.com")) {
        return "Twitter";
    }
    else{
        return "TikTok";
    }

}
export async function getTeammateData(teamId, getToken){
    
    let req = {
        teamId: teamId
    };

    return await fetch(`${ENDPOINT}/getteammatedata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

export async function getTeamUsageData(teamId, start, end, getToken){
    let req = {
        teamId: teamId,
        start: start,
        end: end
    };

    return await fetch(`${ENDPOINT}/getteamusagedata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function exportCommentImage(selectedCommentData, getToken){

    let req = {
        comments: selectedCommentData
    };

    return await fetch(`${SCREENSHOT_ENDPOINT}/screenshot`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function createGroup(name, teamId, campaignId, getToken){

    let req = {
        name: name,
        teamId: teamId,
        type:"group",
        campaignId: campaignId
    };

    return await fetch(`${ENDPOINT}/creategroup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function createCampaign(name, teamId, getToken){

    let req = {
        name: name,
        teamId: teamId,
        type:"campaign"
    };

    return await fetch(`${ENDPOINT}/creategroup`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updatePostGroups(postId, groupIds, getToken){

    let req = {
        postId: postId,
        groupIds: groupIds
    };

    return await fetch(`${ENDPOINT}/updatepostgroups`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updateTeamData(teamId, data, getToken){

    let req = {
        teamId: teamId,
        data: data
    };

    return await fetch(`${ENDPOINT}/updateteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function updatePostTags(postId, teamId, tags, getToken){

    let req = {
        postId: postId,
        teamId: teamId,
        tags: tags
    };

    return await fetch(`${ENDPOINT}/updateposttags`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function updatePostsGroups(postIds, groupIds, getToken){

    let req = {
        postIds: postIds,
        groupIds: groupIds
    };

    return await fetch(`${ENDPOINT}/updatepostsgroups`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function getUserTeamData(userId, getToken){

    let req = {
        userId: userId
    };

    return await fetch(`${ENDPOINT}/getuserteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}



async function getTeamData(id, getToken) { // Accept token as a parameter

    let req = {
        id: id
    };

    return await fetch(`${ENDPOINT}/getteamdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}` // Use the passed token
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            //console.log("data", data);
            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function screenShotComments(html, getToken){
    
        let req = {
            html: html
        };
    
        return await fetch(`${ENDPOINT}/screenshot`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

            },
            body: JSON.stringify(req),
        }).then((res) => res.json())
            .then((data) => {
    
                //console.log("data", data);
    
                if (data && data.data) {
                    return data.data;
                }
            }).catch((err) => console.log(err));
    
    }


async function fetchAccountAvatar(platform, username, getToken){

    let req = {
        platform: platform,
        username: username,
        imgType: "avatar"
    };

    return await fetch(`${ENDPOINT}/fetchimg`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function fetchCommentData(groupId, getToken){

    let req = {
        groupId: groupId,
    };

    return await fetch(`${ENDPOINT}/fetchcommentdata`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function fetchCommentDataByPostId(postId, getToken){

    let req = {
        postId: postId
    };

    return await fetch(`${ENDPOINT}/fetchcommentdatabypostid`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function searchCommentsByPosts(topic, filters, postIds, groupIds, getToken){
    

    let req = {
        groupIds: groupIds,
        topic: topic,
        postIds: postIds,
        filters: filters
    };

    return await fetch(`${ENDPOINT}/search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}


async function searchCommentsByPost(topic, filters, postId, groupIds, getToken){
    

    let req = {
        groupIds: groupIds,
        topic: topic,
        postId: postId,
        filters: filters
    };

    return await fetch(`${ENDPOINT}/search`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));

}

async function checkAccount(username, platform, getToken){

    let req = {
        username: username,
        platform: platform
    };

    return await fetch(`${ENDPOINT}/checkaccount`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}


async function listSubscriptionsByEmail(email, getToken){
    
    console.log("fetching subscriptions for " + email);

    let req = {
        email: email
    };

    return await fetch(`${ENDPOINT}/listsubscriptionsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {
                return data.data;
            }

            else {
                return [];
            }
        }).catch((err) => console.log(err));


}


async function listOwnedByEmail(email, getToken){

console.log("fetching subscriptions for " + email);

let req = {
    email: email
};

return await fetch(`${ENDPOINT}/listownedbyemail`, {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

    },
    body: JSON.stringify(req),
}).then((res) => res.json())
    .then((data) => {

        //console.log("data", data);

        if (data && data.data) {
            return data.data;
        }
        else{
            return [];
        }
    }).catch((err) => console.log(err));


}


    
async function fetchUser(email, getToken){

    let req = {
        email: email
    };
  
    return await fetch(`${ENDPOINT}/getuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
    .then((data) => {
          //console.log("data", data);
          if (data && data.data) {
            let userData = data.data;
            return userData;
  }}).catch((err) => console.log(err));
  
  }


  async function listSiftsByEmail(email, getToken){

    console.log("fetching sifts...");

    let req = {
        email: email
    };


    return await fetch(`${ENDPOINT}/listsiftsbyemail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {

            //console.log("data", data);

            if (data && data.data) {

                const response = {
                    sifts: data.data
                };

                return response;
            }
        }).catch((err) => console.log(err));

}

async function fetchSift(videoId, platform, getToken){

    console.log("fetching sift...");
  
    let req = {
      postId: videoId,
      platform: platform
    };
  
    return await fetch(`${ENDPOINT}/getsift`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

      },
      body: JSON.stringify(req),
    }).then((res) => res.json())
      .then((data) => {
  
        //console.log("data", data);
  
        if (data && data.data && data.data.sift) {
  
          const response = {
            fetchedSift: JSON.parse(data.data.sift),
            fetchedData: data.data,
            fetchedCommentData: JSON.parse(data.data.commentData),
            totalComments: data.data.totalComments,
          };
  
          return response;
        }
      })
  
      .catch((err) => {
        console.log("err", err);
      });
  
  }
  
  async function updateUserSettings(email, settings, getToken){
  
    let req = {
        email: email,
        settings: settings
    };
  
    return await fetch(`${ENDPOINT}/updateuser`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
 "Authorization": `Bearer ${await getToken()}` // Use the passed token

        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
  
    .catch((err) => console.log(err));
  
  }
  
// Cache storage for teams and posts
const cache = {
    teams: {
        data: null,
        timestamp: null,
        ttl: 5 * 60 * 1000 // 5 minutes TTL
    },
    posts: {
        data: null,
        timestamp: null,
        ttl: 2 * 60 * 1000 // 2 minutes TTL
    }
};

// AbortController instances for cancellable requests
let teamsController = null;
let postsController = null;

async function getAllTeams(getToken, options = {}) {
    const {
        page = 1,
        limit = 100,
        search = ''
    } = options;

    // Cancel any existing request
    if (teamsController) {
        teamsController.abort();
    }
    teamsController = new AbortController();

    try {
        const token = await getToken();
        console.log('Auth token available:', !!token);
        
        if (!token) {
            throw new Error('No authentication token available');
        }

        const req = {
            page,
            limit,
            search
        };

        console.log('Making request to:', `${ENDPOINT}/getallteams`);
        console.log('Request payload:', req);
        
        try {
            const response = await fetch(`${ENDPOINT}/getallteams`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(req),
                signal: teamsController.signal
            });

            console.log('Response status:', response.status);
            
            if (!response.ok) {
                const errorText = await response.text();
                console.error('Error response:', errorText);
                throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
            }

            const data = await response.json();
            console.log('Response data available:', !!data);
            
            if (data && data.data) {
                return data.data;
            }
            console.log('No data in response');
            return null;
        } catch (fetchError) {
            console.error('Fetch error details:', {
                name: fetchError.name,
                message: fetchError.message,
                stack: fetchError.stack
            });
            throw fetchError;
        }
    } catch (err) {
        if (err.name === 'AbortError') {
            console.log('Teams fetch aborted');
            return null;
        }
        console.error('Error fetching teams:', {
            message: err.message,
            endpoint: ENDPOINT,
            status: err.status,
            name: err.name,
            stack: err.stack
        });
        throw err;
    }
}

async function getAllPosts(getToken, options = {}) {
    const {
        page = 1,
        limit = 1000,
        search = '',
        platform = null,
        startDate = null,
        endDate = null,
        forceRefresh = false
    } = options;

    try {
        const token = await getToken();
        console.log('Auth token available:', !!token);
        
        if (!token) {
            throw new Error('No authentication token available');
        }

        const req = {
            page,
            limit,
            search,
            platform,
            startDate,
            endDate
        };

        console.log('Making request to:', `${ENDPOINT}/getallposts`);
        console.log('Request payload:', req);
        
        const response = await fetch(`${ENDPOINT}/getallposts`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify(req)
        });

        console.log('Response status:', response.status);
        
        if (!response.ok) {
            const errorText = await response.text();
            console.error('Error response:', errorText);
            throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        const data = await response.json();
        console.log('Response data:', data);
        
        if (data && data.data) {
            return {
                data: data.data,
                total: data.total || data.data.length,
                page,
                limit
            };
        }
        console.log('No data in response');
        return null;
    } catch (err) {
        console.error('Error fetching posts:', {
            message: err.message,
            endpoint: ENDPOINT,
            status: err.status,
            name: err.name,
            stack: err.stack
        });
        throw err;
    }
}

// Helper function to filter teams from cache
function filterTeamsFromCache(teams, search, page, limit) {
    if (!teams) return null;
    
    let filtered = teams;
    if (search) {
        const searchLower = search.toLowerCase();
        filtered = teams.filter(team => 
            team?.name?.toLowerCase().includes(searchLower)
        );
    }
    
    const start = (page - 1) * limit;
    const end = start + limit;
    return {
        data: filtered.slice(start, end),
        total: filtered.length,
        page,
        limit
    };
}

// Helper function to filter posts from cache
function filterPostsFromCache(posts, { search, platform, startDate, endDate, page, limit }) {
    if (!posts) return null;
    
    let filtered = posts;
    
    if (search) {
        const searchLower = search.toLowerCase();
        filtered = filtered.filter(post => 
            post?.title?.toLowerCase().includes(searchLower) ||
            post?.content?.toLowerCase().includes(searchLower)
        );
    }
    
    if (platform) {
        filtered = filtered.filter(post => post.platform === platform);
    }
    
    if (startDate) {
        const start = new Date(startDate);
        filtered = filtered.filter(post => new Date(post.created_at) >= start);
    }
    
    if (endDate) {
        const end = new Date(endDate);
        filtered = filtered.filter(post => new Date(post.created_at) <= end);
    }
    
    const start = (page - 1) * limit;
    const end = start + limit;
    return {
        data: filtered.slice(start, end),
        total: filtered.length,
        page,
        limit
    };
}

async function updateTeamPlan(teamId, planData, getToken) {
    let req = {
        teamId: teamId,
        plan: planData
    };

    return await fetch(`${ENDPOINT}/updateteamplan`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}`
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function fetchCampaigns(getToken) {
  try {
    console.log('Fetching campaigns from:', `${ENDPOINT}/admincampaigns`);
    const token = await getToken();
    console.log('Token received:', token ? 'Yes' : 'No');
    
    const response = await fetch(`${ENDPOINT}/admincampaigns`, {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      credentials: 'include'
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Campaign API error:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText,
        endpoint: `${ENDPOINT}/admincampaigns`,
      });
      throw new Error(`HTTP ${response.status}: ${errorText || response.statusText}`);
    }

    console.log('Campaign response received');
    const data = await response.json();
    console.log('Campaign data parsed:', data);

    if (!data?.data || !Array.isArray(data.data)) {
      console.warn('Invalid campaigns data format received:', data);
      return [];
    }

    const defaultCampaign = {
      name: 'Untitled Campaign',
      createdAt: new Date().toISOString(),
      status: 'active',
      groupCount: 0
    };

    const processedCampaigns = data.data.map(campaign => ({
      ...defaultCampaign,
      ...campaign,
      // Ensure required fields are present
      id: campaign.id,
      teamId: campaign.teamId,
      type: campaign.type
    }));

    console.log('Processed campaigns:', processedCampaigns.length);
    return processedCampaigns;
  } catch (error) {
    console.error('Failed to fetch campaigns:', {
      error: error.message,
      stack: error.stack,
      endpoint: `${ENDPOINT}/admincampaigns`
    });
    throw error;
  }
}

async function updateTeamName(teamId, name, getToken) {
    let req = {
        teamId: teamId,
        name: name
    };

    return await fetch(`${ENDPOINT}/updateteamname`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${await getToken()}`
        },
        body: JSON.stringify(req),
    }).then((res) => res.json())
        .then((data) => {
            if (data && data.data) {
                return data.data;
            }
        }).catch((err) => console.log(err));
}

async function updatePostsStatus(postIds, status, getToken) {
  let req = {
    postIds: postIds,
    status: status
  };

  console.log("updating posts status...", req);

  return await fetch(`${ENDPOINT}/updatepostsstatus`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${await getToken()}`
    },
    body: JSON.stringify(req),
  }).then((res) => res.json())
    .then((data) => {
      if (data && data.data) {
        return data.data;
      }
    }).catch((err) => console.log(err));
}
  