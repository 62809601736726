import React , { useMemo } from "react";
import { Box, Card, CardMedia, CircularProgress, Typography } from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const ThumbnailGallery = React.memo(function ({ small, selectedPostData, multiple, numAccounts, numPosts, screenShot }) {
    const [loadedImages, setLoadedImages] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [clickedIndex, setClickedIndex] = React.useState(null);
  
    React.useEffect(() => {
      const loadImages = async () => {
        setLoading(true);
    
        const imagesLoaded = await Promise.all(
          selectedPostData.map((post) =>
            new Promise((resolve) => {
              const img = new Image();
              img.src = post.data.thumbnail;
    
              img.onload = () => resolve(img);
              img.onerror = () => resolve(null);  // Handle load errors by resolving null
            })
          )
        );
    
        const validImages = imagesLoaded.filter((img) => img !== null);
        
        if (validImages.length !== loadedImages.length) {
          setLoadedImages(validImages);
        }
    
        setLoading(false);
      };
    
      loadImages();
    }, [selectedPostData]);

    const fullWidth = 300;
    const isMobile = window.innerWidth < 600;

    const sortedImages = useMemo(() => {
      return [...loadedImages].splice(0,20).sort(() => Math.random() - 0.5);
    }, [loadedImages]);
  
    const getStyles = (aspectRatio, isMobile, small) => {
      const maxHeight = (aspectRatio >= 1 ? "220px" : "180px"); // Adjust as needed
      const width =
        aspectRatio >= 1
          ? 
          small ? 120 : 
          isMobile
            ? 180
            : 220
          : aspectRatio < 0.56
          ? 
          small ? 80 : 
          isMobile
            ? 90
            : 100
          : 
          small ? 90 : 
          isMobile
          ? 100
          : 120; // Adjust as needed
  
      return { maxHeight, width };
    };
  
    // Add drag end handler
    const handleDragEnd = (result) => {
      if (!result.destination) return;
      
      const items = Array.from(sortedImages);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      
      setLoadedImages(items);
    };

    const handleClick = (index) => {
      if (clickedIndex !== null) return; // Prevent multiple clicks
      setClickedIndex(index);
      
      // Move clicked image to front
      const items = Array.from(sortedImages);
      const [clickedItem] = items.splice(index, 1);
      items.unshift(clickedItem); // Add to beginning instead of end
      
      // Update the order immediately
      setLoadedImages(items);
      
      // Only reset the clicked state after animation
      setTimeout(() => {
        setClickedIndex(null);
      }, 300);
    };

    return loading ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress size={20} />
      </Box>
    ) : (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="thumbnails" direction="horizontal">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                display: loadedImages.length > 0 ? "flex" : "none",
                position: "relative",
                height: (small || isMobile) ? 160 : 220,
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                my: loadedImages.length > 1 ? 5 : 1,
                            }}
            >
              {sortedImages.map((img, index) => {
                const aspectRatio = img.width / img.height;
                const { width } = getStyles(aspectRatio, isMobile, small);
                const isClicked = clickedIndex === index;
                
                return (
                  <Draggable key={img.src} draggableId={img.src} index={index}>
                    {(provided, snapshot) => (
                      <Card
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        onClick={() => handleClick(index)}
                        elevation={snapshot.isDragging ? 6 : 3}
                        sx={{
                          position: "absolute",
                          transform: multiple &&
                            `rotate(${Math.floor(Math.random() * 30) - 15}deg)`,
                          borderRadius: 2,
                          width: width,
                          transition: 'all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)',
                          cursor: 'pointer',
                          animation: isClicked ? 'pop 0.3s cubic-bezier(0.34, 1.56, 0.64, 1)' : 'none',
                          '@keyframes pop': {
                            '0%': {
                              transform: 'scale(1) rotate(0deg)',
                            },
                            '50%': {
                              transform: 'scale(1.1) rotate(5deg)',
                              zIndex: 2,
                            },
                            '100%': {
                              transform: 'scale(1) rotate(0deg)',
                            },
                          },
                          ...(isClicked && {
                            zIndex: 2,
                          }),
                          ...(snapshot.isDragging && {
                            zIndex: 2,
                          }),
                        }}
                      >
                        <CardMediaComponent
                          component="img"
                          height="auto"
                          width={width}
                          src={img.src}
                          sx={{ borderRadius: 2 }}
                        />
                      </Card>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    );
  });

  export default ThumbnailGallery;



  function CardMediaComponent({src, width, height, alt, sx}) {

    const [bitmapData, setBitmapData] = React.useState(null);

    return (
 
      <CardMedia
        component="img"
        src={src}
        width={width}
        height={height}
        alt={alt}
        sx={sx}
      />
      
    );
  }